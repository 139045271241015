import React, { useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCustomReducer } from '../../../../../base/customHooks';
import './VisualDocumentation.scss';
import { GetProductByIdApi } from '../services/getProductByIdApi';
import Swal from 'sweetalert2';
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC';
import { IProductGroupImageList, IProductList } from '../models/responseModel';
import { GetProductGroupImagesApi } from '../services/getProductGroupImagesApi';
import Table from '../../../../../components/Table';
import { AddProductGroupImageApi } from '../services/addProductGroupImageApi';
import { getUploadFileRequest } from '../../../../../base/utils/dataHelper';
import { IProductGroupImagesModel } from '../models/requestModels';
import classes from '../../../../../components/IPFile/IPFile.module.scss'
import IPImage from '../../../../../components/IPImage';
import IPInput from '../../../../../components/IPInput';
import { DeleteProductGroupImageApi } from '../services/deleteProductGroupImageApi';


interface IProductState {
  allImages: IProductGroupImageList[];
  filterImages: IProductGroupImageList[];
  document: boolean;
  documentData?: any[];
  searchValue: string
}

function ProductGroupImageList(props: BaseComponentWithContextProps) {
  const navigate = useNavigate();
  const inputEl: any = useRef(null)

  const getInitialState = (): IProductState => {
    return {
      document: false,
      allImages: [],
      filterImages: [],
      searchValue: ""
    };
  };

  const initialState = useMemo(() => getInitialState(), []);
  const [state, setState] = useCustomReducer<IProductState>(initialState);

  useEffect(() => {
    visualDocumentationList()
  }, [])

  const visualDocumentationList = async () => {
    props.showLoading();
    const getImageByApi = new GetProductGroupImagesApi({})
    try {
      const result = await getImageByApi.getImages()
      if (result)
        setState({ allImages: result, filterImages: result })
      props.hideLoading()
    } catch (err: any) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const deleteImage = async (code: string, name: string) => {
    Swal.fire({
      icon: 'question',
      title: `${name} (${code}) ürün grubuna ait resmi silmek istediğinize emin misiniz?`,
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const deleteImageApi = new DeleteProductGroupImageApi({})
        try {
          const result = await deleteImageApi.deleteImage(JSON.stringify(code))
          if (result) {
            setState({ allImages: result, filterImages: result })
            Swal.fire({
              icon: 'success',
              title: "Resim başarılı bir şekilde kaldırıldı.",
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          }
        } catch (err: any) {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        }
      }
    })
  }

  const addImage = async (e: any, group: string) => {
    const fileList: any[] = await getUploadFileRequest(e)
    for (const fileData of fileList) {
      if (fileData.size > 50000000) {
        return Swal.fire('Hata', 'Resim boyutu 50 MB\'den küçük olmalıdır!', 'error')
      }

      const allowedTypes = ['.jpeg', '.png', '.gif', '.jpg',]
      if (!allowedTypes.includes(fileData.Extension)) {
        return Swal.fire('Hata', 'Hatalı dosya formatı! Sayfayı yeniledikten sonra tekrar deneyiniz.', 'error')
      }
    }

    var file = fileList.map((fileData, index) => ({
      Id: index,
      Label: '',
      Description: '',
      File: fileData,
    }))

    const data: IProductGroupImagesModel = {
      GroupCode: group,
      File: file[0].File
    }

    const addImageApi = new AddProductGroupImageApi({})
    try {
      const result = await addImageApi.addImage(data)
      if (result) {
        setState({ allImages: result, filterImages: result })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: 'Grup Kodu',
        accessor: 'GroupCode',
      },
      {
        Header: 'Grup Adı',
        accessor: 'GroupName',
      },
      {
        Header: 'Dosya',
        accessor: 'Image',
      },
      {
        Header: 'Resim',
        accessor: 'Pic',
      },
      {
        Header: 'Ekle',
        accessor: 'Actions',
      },
      {
        Header: 'Sil',
        accessor: 'Delete',
      },
    ],
    []
  );

  const tableData = useMemo(() => {
    return state.filterImages.map((x, index) => {

      return {
        GroupCode: x.GroupCode,
        GroupName: x.GroupName,
        Image: x.Image,
        Pic: <IPImage
          src={x.Image}
          alt=''
          style={{ maxHeight: '125%' }}
          className='popular_product'
        />,
        Actions: (
          <>
            <div className={classes.file}>
              <input
                type="file"
                onChange={(event) => addImage(event, x.GroupCode)}
                multiple={false}
                id={`ImageFile${index}`}
              />
              <label htmlFor={`ImageFile${index}`}>
                Resim Seç
              </label>

            </div>
          </>
        ),
        Delete: (
          <>
            {x.Image?.length > 0 && (
              <i
                className="icon-trash-2"
                style={{ cursor: 'pointer', color: 'red' }}
                onClick={() => deleteImage(x.GroupCode, x.GroupName)}
              ></i>
            )}
          </>
        ),
      }
    })
  }, [state.filterImages])

  useEffect(() => {
    if (state.searchValue && state.searchValue.length > 1)
      setState({ filterImages: state.allImages.filter(a => a.GroupCode.includes(state.searchValue) || a.GroupName.includes(state.searchValue)) })
    else
      setState({ filterImages: state.allImages })
  }, [state.searchValue])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              Ürün Grubu Görsel Ekleme
            </span>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-lg-12'>
                <div id='datatable_wrapper' className='dataTables_wrapper dt-bootstrap4'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <IPInput
                        type={'text'}
                        placeholder='Arama'
                        value={state.searchValue}
                        onChange={e => setState({ searchValue: e.target.value })}
                      />
                    </div>

                    <p style={{ color: 'red', textAlign: 'right' }}>Aynı ürün grubuna tekrar resim eklemek için sayfayı yenilemeniz gerekmektedir.</p>
                    <div className='table-responsive'>
                      <Table columns={columns} data={tableData} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}
export default componentWithContext(ProductGroupImageList);