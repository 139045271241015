import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IPO, PODocUpload } from '../models'

export class AddPODocumentApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'MyReports/AddPODocument',
      requesterInfo,
    })
  }
  public async addDocument(req: PODocUpload): Promise<IPO[]> {
    return await this.postAsync<IPO[]>(req)
  }
}
