import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'

export class DeleteBankAccountApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/deleteBankAccounts',
      requesterInfo,
    })
  }

  public async deleteBankAccount(id: number): Promise<any> {
    return await this.postAsync<any>(JSON.stringify(id))
  }
}
