import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { IBankAccountNumbers } from '../../../../FinancialInformation/models/models'

export class UpdateBankAccountApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/updateBankAccounts',
      requesterInfo,
    })
  }

  public async updateBankAccount(request: IBankAccountNumbers): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
