import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import IPButton from '../../../../../components/IPButton'
import IPInput from '../../../../../components/IPInput'
import IPSelectBox from '../../../../../components/IPSelectBox'
import Translations from '../../../../../translations'
import { translate } from '../../../../../base/localization'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { CommonPostResponse, IBanks, ICompany, IDealer } from '../../../components/models/models'
import { getBanksApi, getCompanyApi, getDealerApi } from '../../../components/api'
import { uuidv4 } from '../../../../../base/utils'
import _ from 'lodash'
import { IBank, ICampaign, IPostInstallment, ProcedureInstalment } from '../models/models'
import { AddInstallemntsApi } from '../services/addInstallemntsApi'
import Swal from 'sweetalert2'
import { exportToXLSX, getDateByTimezoneOffset } from '../../../../../base/utils/dataHelper'
import { useCustomReducer } from '../../../../../base/customHooks'
import { UpdateInstallemntsApi } from '../services/updateInstallemntsApi'
import { GetAllFcvService } from '../../../ProductOperation/CampaignBundleCrud/services/GetAllFcvService'
import { DeleteInstallemntsApi } from '../services/deleteInstallemntsApi'
import InputCampaign from '../../../../../components/InputCampaign'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { IListAllFCV } from '../../../ProductOperation/CampaignBundleCrud/services/InsertFcvService'
interface IListInstallmentState {
  dealerCodes: ISelectOption[]
  type?: ISelectOption | null
  bankName?: ISelectOption
  installment?: ISelectOption
  company?: ISelectOption
  campaing?: ISelectOption | null
  buttonName: string
}
function BankAdd(props: BaseComponentWithContextProps) {
  let navigate = useNavigate()
  const t = translate(Translations)
  const getInitialState = (): IListInstallmentState => {
    return {
      dealerCodes: [],
      buttonName: 'Ekle',
    }
  }
  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListInstallmentState>(intialState)
  const [companies, setCompanies] = useState<ISelectOption[]>([])
  const [dealers, setDealers] = useState<IDealer[]>([])
  const [installment, setInstallment] = useState<ProcedureInstalment[]>([])
  const [bank, setBank] = useState<ISelectOption[]>([])
  const [campaing, setCampaing] = useState<ICampaign[]>([])
  const [company, setCompany] = useState<boolean>(false)
  const [types, setTypes] = useState<boolean>(false)
  const [campaings, setCampaings] = useState<boolean>(false)
  const location = useLocation()

  const inititalStartDate = new Date()
  inititalStartDate.setDate(1)
  inititalStartDate.setHours(0)
  inititalStartDate.setMinutes(0)
  inititalStartDate.setSeconds(0)
  const [startDate, setStartDate] = useState(inititalStartDate)
  const [endDate, setEndDate] = useState(new Date())

  const toDateTimeLocal = date => {
    return date
      .toLocaleString('sv-SE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(' ', 'T')
  }

  const getDate = async (data: any, title: string) => {
    if (title === 'Start') setStartDate(data)
    else setEndDate(data)
  }

  useEffect(() => {
    getDealer()
    getCompanies()
    addInstallmentField(0)
  }, [])

  const getDealer = async () => {
    const dealer: IDealer[] = await getDealerApi()
    setDealers(dealer)
  }

  const getCompanies = async () => {
    const company: ICompany[] = await getCompanyApi()
    const data: ISelectOption[] = []
    company
      .filter(c => {
        return c.Name !== 'İndex Grup'
      })
      .map(com => {
        if (com.Name === 'Index') data.push({ value: '1000', label: com.Name })
        if (com.Name === 'Datagate') data.push({ value: '1100', label: com.Name })
        if (com.Name === 'Despec') data.push({ value: '1200', label: com.Name })
        if (com.Name === 'Netex') data.push({ value: '2300', label: com.Name })
      })
    setCompanies(data)
  }

  const getBanks = async (option: ISelectOption) => {
    if (
      state.company &&
      state.company.value !== option.value &&
      (state.type || (installment && installment[0].BankName) || state.campaing)
    ) {
      Swal.fire({
        icon: 'question',
        title: `Daha önce girdiniz bilgiler silinecektir. Emin misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          setState({ company: option, type: null, campaing: null })
          setInstallment([
            {
              StateId: uuidv4(),
              Id: 0,
              BankName: [],
              InstalmentRate: null,
              TermDay: '',
              Add: true,
              BankGroupId: uuidv4(),
              Update: false,
              Order: 0,
            },
          ])
          const banks: IBanks[] = await getBanksApi(String(option.value))
          const data: ISelectOption[] = []
          banks.map(b => data.push({ value: b.BankID, label: b.BankName }))
          setBank(data)
        } else {
          return
        }
      })
    } else {
      setState({ company: option })
      const banks: IBanks[] = await getBanksApi(String(option.value))
      const data: ISelectOption[] = []
      banks.map(b => data.push({ value: b.BankID, label: b.BankName }))
      setBank(data)
    }
  }

  const getTypes = async (option: ISelectOption) => {
    if (
      state.type &&
      state.type.value !== option.value &&
      ((installment && installment[0].BankName) || state.campaing)
    ) {
      Swal.fire({
        icon: 'question',
        title: `Daha önce girdiniz bilgiler silinecektir. Emin misiniz?`,
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async result => {
        if (result.isConfirmed) {
          setState({ type: option, campaing: null })
        } else {
          return
        }
      })
    } else {
      setState({ type: option })
    }
  }
  const type = [
    { value: '3', label: 'Cari Ödeme' },
    { value: '0', label: 'Avantaj POS' },
    { value: '1', label: 'Fırsat POS' },
    { value: '2', label: 'FÇV2' },
  ]
  const installmentAddNum = [
    {
      value: '1',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['1']),
    },
    {
      value: '2',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['2']),
    },
    {
      value: '3',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['3']),
    },
    {
      value: '4',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['4']),
    },
    {
      value: '5',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['5']),
    },
    {
      value: '6',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['6']),
    },
    {
      value: '9',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['9']),
    },
    {
      value: '12',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['12']),
    },
    {
      value: '15',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['15']),
    },
    {
      value: '18',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['18']),
    },
    {
      value: '24',
      label: t('pages.admin.bankAndInstallementOperation.bankCrud.bankAdd.installment', ['24']),
    },
  ]

  const addInstallmentField = (id: number) => {
    // Yeni elemanı oluşturun
    const newInstallment = {
      StateId: uuidv4(),
      Id: 0,
      BankName: [],
      InstalmentRate: null,
      TermDay: '',
      Add: true,
      BankGroupId: uuidv4(),
      Update: false,
      Order: id,
    }

    // Yeni elemanı mevcut installment dizisine ekleyin
    const updatedInstallment = [...installment]

    // Eğer dizide eleman varsa ve eklenecek yer belirlenmişse
    if (id >= 0 && id < updatedInstallment.length) {
      updatedInstallment.splice(id + 1, 0, newInstallment) // Ekleme işlemini gerçekleştir
    } else {
      updatedInstallment.push(newInstallment) // Belirlenen konum yoksa dizinin sonuna ekle
    }

    // Güncellenmiş diziyi state'e set edin
    setInstallment(updatedInstallment)
  }

  const copyInstallmentField = (item: ProcedureInstalment, id: number) => {
    const dt: ISelectOption[] = []
    item.BankName.forEach(ba => {
      dt.push({ value: Number(ba.value), label: ba.label })
    })

    const newInstallment = {
      StateId: uuidv4(),
      Id: 0,
      BankName: dt,
      InstalmentRate: null,
      TermDay: '',
      Add: true,
      BankGroupId: uuidv4(),
      Update: false,
      Order: id,
    }

    const updatedInstallment = [...installment]

    if (id >= 0 && id < updatedInstallment.length) {
      updatedInstallment.splice(id + 1, 0, newInstallment)
    } else {
      updatedInstallment.push(newInstallment)
    }

    setInstallment(updatedInstallment)
  }

  const { validateField, setFieldValue, setFieldTouched, values } = useFormik({
    initialValues: {
      Type: '',
      DealerCodes: [],
      Installment: 0,
      BankName: '',
      InterestRate: 0,
      StartDate: startDate,
      EndDate: endDate,
      Id: 0,
      BankId: '',
      Company: '',
      PaymentTermsId: 0,
    },

    onSubmit: async values => {
      values['Location'] = values['Location']['value']
    },
  })

  const addInstallment = async () => {
    //#region Validation
    if (!state.company) {
      Swal.fire({
        icon: 'error',
        title: 'Şirket seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!state.type) {
      Swal.fire({
        icon: 'error',
        title: 'Tip seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (state.type.value !== '3' && (!state.campaing || !state.campaing.value)) {
      Swal.fire({
        icon: 'error',
        title: 'Kampanya seçmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (installment.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Banka Taksit girmelisiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    //#endregion

    const clnValues = _.clone(values)
    clnValues.StartDate = new Date(startDate)
    clnValues.EndDate = new Date(endDate)
    clnValues.DealerCodes = state.dealerCodes ? (state.dealerCodes.map((item: ISelectOption) => item.label) as any) : []
    clnValues.Type = state.type ? String(state.type.value) : ''
    clnValues.BankName = state.bankName ? state.bankName.label : ''
    clnValues.BankId = state.bankName ? String(state.bankName.value) : ''
    clnValues.Company = state.company ? String(state.company.value) : ''
    clnValues.PaymentTermsId = state.type?.value !== '3' ? (state.campaing ? Number(state.campaing.value) : 0) : 0

    clnValues.InterestRate = Number(values.InterestRate)
    const data: IPostInstallment[] = []
    let BankBool: boolean = false

    installment.map((ins, index) => {
      if (!ins.TermDay) {
        Swal.fire({
          icon: 'error',
          title: 'Vade oranı boş bırakılamaz!',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        BankBool = true
        return
      }
      if (ins.BankName && ins.BankName.length > 0 && ins.InstalmentRate && Number(ins.InstalmentRate.value) > 0) {
        ins.BankName.map(bname => {
          data.push({
            Id: ins.Id,
            Type: state.type ? String(state.type.value) : '',
            DealerCodes: [],
            Company: clnValues.Company,
            Installment: ins.InstalmentRate ? Number(ins.InstalmentRate.value) : 0,
            BankName: bname.label,
            InterestRate: Number(ins.TermDay),
            StartDate: getDateByTimezoneOffset(startDate) as any,
            EndDate: getDateByTimezoneOffset(endDate) as any,
            BankId: String(bname.value),
            PaymentTermsId: clnValues.PaymentTermsId,
            BankGroupId: ins.BankGroupId ? ins.BankGroupId : '',
          })
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Banka taksit bilgileriniz eksiktir.',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(async result => { })
        BankBool = true
      }
    })
    if (!BankBool) {
      if (state.buttonName === 'Ekle') {
        const addInstallemnt: AddInstallemntsApi = new AddInstallemntsApi({})
        addInstallemnt
          .addInstallemnts(data)
          .then((result: CommonPostResponse) => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Banka Taksit Eklendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async result => {
                navigate(
                  '/admin/BankAndInstallementOperation/BankCrud' +
                  (location.state?.url ? '?' + location.state?.url : '')
                )
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      } else {
        const updateInstallemnts: UpdateInstallemntsApi = new UpdateInstallemntsApi({})
        updateInstallemnts
          .updateInstallemnts(data)
          .then((result: CommonPostResponse) => {
            if (result) {
              Swal.fire({
                icon: 'success',
                title: 'Banka Taksit Güncellendi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async result => {
                navigate(
                  '/admin/BankAndInstallementOperation/BankCrud' +
                  (location.state?.url ? '?' + location.state?.url : '')
                )
              })
            }
          })
          .catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
      }
    }
  }

  const handleBankName = (e, item) => {
    const tempInstallment = _.clone(installment)
    if (tempInstallment.length > 1) {
      ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).BankName = e
        ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).Add = false
    } else {
      tempInstallment[0].BankName = e
      if (
        tempInstallment[0].TermDay !== '' &&
        (tempInstallment[0].InstalmentRate ||
          tempInstallment[0].InstalmentRate ||
          tempInstallment[0].TermDay ||
          tempInstallment[0].TermDay)
      )
        tempInstallment[0].Add = false
    }
    setInstallment(tempInstallment)
  }

  const handleInstalmentRate = (e, item) => {
    const tempInstallment = _.clone(installment)

    // If selected installment rate is not '1', remove BankID 9001 and 1402 Diğer bankalar and Kuveyt Türk
    if (e.value !== '1') {
      tempInstallment.forEach(installmentItem => {
        if (installmentItem.StateId === item.StateId) {
          installmentItem.BankName = installmentItem.BankName.filter(bank => ![9001, 1402].includes(Number(bank.value)))
        }
      })
    }

    if (tempInstallment.length > 1) {
      const foundItem = tempInstallment.find(desc => desc.StateId === item.StateId)
      if (foundItem) {
        foundItem.InstalmentRate = e
        foundItem.Add = false
      }
    } else {
      tempInstallment[0].InstalmentRate = e
      if (tempInstallment[0].TermDay !== '' && (tempInstallment[0].BankName || tempInstallment[0].BankName))
        tempInstallment[0].Add = false
    }
    setInstallment(tempInstallment)
  }

  const handleAllBank = item => {
    const dt: ISelectOption[] = []
    bank.filter(a => !a.label.includes("PARAM POS")).map(b => {
      dt.push(b)
    })
    setInstallment(prevState => {
      return prevState.map((prev: any) => {
        if (prev.StateId === item.StateId) {
          prev = {
            StateId: prev.StateId,
            Id: prev.Id,
            BankName: dt,
            InstalmentRate: prev.InstalmentRate,
            TermDay: prev.TermDay,
            Add: prev.Add,
            BankGroupId: prev.BankGroupId,
          }
        }
        return prev
      })
    })
  }

  const handleAllBankParam = item => {
    const dt: ISelectOption[] = []
    bank.filter(a => a.label.includes("PARAM POS")).map(b => {
      dt.push(b)
    })
    setInstallment(prevState => {
      return prevState.map((prev: any) => {
        if (prev.StateId === item.StateId) {
          prev = {
            StateId: prev.StateId,
            Id: prev.Id,
            BankName: dt,
            InstalmentRate: prev.InstalmentRate,
            TermDay: prev.TermDay,
            Add: prev.Add,
            BankGroupId: prev.BankGroupId,
          }
        }
        return prev
      })
    })
  }

  const handleTermDay = (e, item) => {
    const tempInstallment = _.clone(installment)
    if (tempInstallment.length > 1) {
      ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).TermDay = e
        ; (tempInstallment.find(desc => desc.StateId === item.StateId) as any).Add = false
    } else {
      tempInstallment[0].TermDay = e
      if (tempInstallment[0].InstalmentRate && (tempInstallment[0].BankName || tempInstallment[0].BankName))
        tempInstallment[0].Add = false
    }
    setInstallment(tempInstallment)
  }

  const routeLocation = useLocation()

  const removeInstallmentItem = item => {
    Swal.fire({
      icon: 'question',
      title: 'Silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        if (routeLocation.state && (routeLocation.state as any)?.installementData) {
          const deleteInstallemnts: DeleteInstallemntsApi = new DeleteInstallemntsApi({})
          deleteInstallemnts.deleteInstallemnts([item.Id])
        }
        setInstallment(
          installment
            .filter(ins => {
              return ins.StateId !== item.StateId
            })
            .map(i => {
              return i
            })
        )
      }
    })
    return
  }

  useEffect(() => {
    if (routeLocation.state && companies.length > 0 && routeLocation.state.flag != 'copy') {
      setCompany(true)
      setTypes(true)
      setCampaings(true)
      const data = _.clone((routeLocation.state as any)?.installementData)
      const dt = data.Bank
      const res = dt.sort((a, b) => a.BankId - b.BankId)
      companies.map(com => {
        if (String(data.Company) === String(com.value)) {
          getBanks(com)
          setState({ company: com })
        }
      })
      installmentAddNum.map(ins => {
        if (String(data.Installment) === String(ins.value)) {
          setState({ installment: ins })
        }
      })
      if (data.PaymentTermsId > 0 && data.PaymentTermsName.length > 0)
        setState({ campaing: { value: data.PaymentTermsId, label: data.PaymentTermsName } })
      type.map(com => {
        if (String(data.Type) === String(com.value)) {
          setState({ type: com })
          if (String(data.Type) !== '3') {
            // setState({ campaing: com })
            getAllFcv('update', data.PaymentTermsId)
          }
        }
      })
      // setState({ bankName: { value: data.BankId, label: data.BankName } })
      // setFieldValue('Limit', data.Limit)
      // setFieldValue('Id', data.Id)
      // setFieldValue('Installment', data.Installment)
      // setFieldValue('InterestRate', data.InterestRate)
      setStartDate(data.StartDate)
      setEndDate(data.EndDate)
      let dataBank: ProcedureInstalment[] = []
      // let dt: ProcedureInstalment[] = []
      // let bankgrup = "";
      res &&
        res.map((bank: IBank, index) => {
          const InstalmentRate = installmentAddNum
            .filter(ins => {
              return ins.value === String(bank.Installment)
            })
            .pop()
          dataBank.push({
            BankName: [
              {
                value: bank.BankId,
                label: bank.BankName,
              },
            ],
            Id: bank.Id,
            StateId: uuidv4(),
            InstalmentRate: { value: String(bank.Installment), label: InstalmentRate?.label },
            TermDay: String(bank.InterestRate),
            Add: data.Bank.length - 1 === index ? false : true,
            BankGroupId: bank.BankGroupId,
            Update: true,
            Order: index,
          })
          setInstallment(dataBank)
          setCompany(false)
          setState({ buttonName: 'Güncelle' })
        })
    }
  }, [companies, dealers])

  useEffect(() => {
    if (routeLocation.state && companies.length > 0 && routeLocation.state.flag === 'copy') {
      const data = _.clone((routeLocation.state as any)?.installementData)
      const dt = data.Bank
      const res = dt.sort((a, b) => a.BankId - b.BankId)
      companies.map(com => {
        if (String(data.Company) === String(com.value)) {
          getBanks(com)
          setState({ company: com })
        }
      })
      installmentAddNum.map(ins => {
        if (String(data.Installment) === String(ins.value)) {
          setState({ installment: ins })
        }
      })
      if (data.PaymentTermsId > 0 && data.PaymentTermsName.length > 0)
        setState({ campaing: { value: data.PaymentTermsId, label: data.PaymentTermsName } })
      type.map(com => {
        if (String(data.Type) === String(com.value)) {
          setState({ type: com })
          if (String(data.Type) !== '3') {
            getAllFcv('add')
          }
        }
      })
      setStartDate(data.StartDate)
      setEndDate(data.EndDate)
      let dataBank: ProcedureInstalment[] = []
      res &&
        res.map((bank: IBank, index) => {
          const InstalmentRate = installmentAddNum
            .filter(ins => {
              return ins.value === String(bank.Installment)
            })
            .pop()
          dataBank.push({
            BankName: [
              {
                value: bank.BankId,
                label: bank.BankName,
              },
            ],
            Id: bank.Id,
            StateId: uuidv4(),
            InstalmentRate: { value: String(bank.Installment), label: InstalmentRate?.label },
            TermDay: String(bank.InterestRate),
            Add: data.Bank.length - 1 === index ? false : true,
            BankGroupId: bank.BankGroupId,
            Update: false,
            Order: index,
          })
          setInstallment(dataBank)
          setCompany(false)
          setState({ buttonName: 'Ekle' })
        })
    }
  }, [companies, dealers])

  useEffect(() => {
    if (state.type && state.type.value && state.type.value !== '3' && state.company && startDate && endDate) {
      getAllFcv('add')
    } else {
      setCampaing([])
      setState({ campaing: null })
    }
  }, [state.type, state.company, startDate, endDate])

  const getAllFcv = async (type?: string | boolean, id?: number | undefined) => {
    const req: IListAllFCV = {
      Company: state.company?.value === undefined ? '' : String(state.company?.value),
      Type: state.type?.value === undefined ? undefined : +state.type?.value,
      PaymentTermsName: '',
      StartDate: startDate,
      EndDate: endDate,
      DealerCode: '',
      PageNumber: 0,
      PageSize: 1000,
      PageType: 0,
    }
    try {
      props.showLoading()
      const fcvListAPI: GetAllFcvService = new GetAllFcvService({})
      const result: any = await fcvListAPI.listAllFCV(req)
      // const data: ICampaign[] = [];

      // for (const res of result) {
      //   if (res.Type === Number(state.type?.value) && res.Company === state.company?.value) {
      //     data.push(res);
      //   }
      // }
      // if (
      //   (type === 'add' && (!state.campaing || state.campaing) && (!data || data.length === 0)) ||
      //   (type === 'update' && id === 0)
      // ) {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Uygun kampanya bulunamamıştır şirket veya tipi değiştiriniz',
      //     showConfirmButton: true,
      //     allowOutsideClick: false,
      //   });
      //   return;
      // }
      if (result.TotalPaymentCount === 0) {
        Swal.fire({
          icon: 'error',
          title: 'Uygun kampanya bulunamamıştır şirket veya tipi değiştiriniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
      setCampaing(result.PaymentTermsList)
      props.hideLoading()
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Kampanya bulunamadı.',
        allowOutsideClick: true,
      })
      props.hideLoading()
    }
  }

  const handleQuantity = (e: any, item: any) => {
    const val = e.target.value
    handleTermDay(val.replace(',', '.'), item)
  }

  const handleQuantityOnBlur = (e: any, item: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value)) || Number(e.target.value) < 0) {
      handleTermDay(0, item)
    } else {
      if (Number(e.target.value) < 0) {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: '0 dan küçük olmalıdır',
          showConfirmButton: true,
          allowOutsideClick: true,
        })
        handleTermDay(0, item)
        return
      }
      handleTermDay(e.target.value, item)
    }
  }

  const excelButton = async () => {
    try {
      if (installment) {
        const data: any[] = []
        if (installment.length > 0) {
          installment.map((p, index) => {
            return data.push({
              Sıra: index + 1,
              'Banka Adı': p.BankName && (p.BankName[0].label as any),
              'Taksit Adedi': p.InstalmentRate && (p.InstalmentRate.label as any),
              Vade: p.TermDay,
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Banka Taksitleri')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <div className='col-12 col-md-9 mb-2'>
              <span className='h4'>Banka Taksit {state.buttonName === 'Ekle' ? 'Ekleme' : 'Güncelleme'}</span>
            </div>
            <div className='col-12 col-md-2 mb-2'>
              <div className='buttonsuz'>
                <Link
                  to={`/admin/BankAndInstallementOperation/BankCrud${location.state?.url ? '?' + location.state?.url : ''
                    }`}
                >
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
              </div>
            </div>
            <div className='col-12 col-md-1'>
              <div className='d-flex align-items-center'>
                <span>Excel: </span>
                <button
                  type='button'
                  style={{
                    border: '1px solid #2e7d32',
                    borderRadius: 0,
                    width: '29px',
                    height: '29px',
                    backgroundColor: 'white',
                    margin: '0 10px',
                  }}
                  onClick={excelButton}
                  className='btn text-capitalize background-white-important d-flex align-items-center'
                >
                  <i
                    className='fas fa-file-excel'
                    style={{
                      marginLeft: '-6px',
                      fontSize: '18px',
                      color: '#2e7d32',
                    }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form id='id1' className='formform' noValidate style={{ marginBottom: '350px' }}>
              <div className='form-row'>
                <div className={'form-group col-md-6'}>
                  <label className='labeltext'>Şirket</label>
                  <IPSelectBox
                    isDisabled={company}
                    isClearable
                    options={companies}
                    value={state.company}
                    onChangeSingle={option => {
                      getBanks(option)
                    }}
                  />
                </div>
                <div className={'form-group col-md-6'}>
                  <label className='labeltext'>Tip</label>
                  <IPSelectBox
                    isDisabled={types}
                    isClearable
                    options={type}
                    value={state.type}
                    onChangeSingle={option => {
                      getTypes(option)
                    }}
                  />
                </div>
              </div>
              <div className='form-row'>
                <div className='col-lg-4'>
                  <label className='form-label'>Başlangıç Tarihi</label>
                  <InputCampaign
                    type='datetime-local'
                    id='StartDate'
                    name='StartDate'
                    label={''}
                    value={toDateTimeLocal(startDate)}
                    onChange={e => getDate(e.target.value, 'Start')}
                    isActive
                  />
                </div>
                <div className='col-lg-4'>
                  <label className='form-label'>Bitiş Tarihi</label>
                  <InputCampaign
                    type='datetime-local'
                    id='EndDate'
                    name='EndDate'
                    label={''}
                    value={toDateTimeLocal(endDate)}
                    onChange={e => getDate(e.target.value, 'End')}
                    isActive
                  />
                </div>
                {
                  <div className='form-group col-md-4'>
                    <label className='labeltext'>Kampanya</label>
                    <IPSelectBox
                      isDisabled={campaings ? true : state.type && state.type.value !== '3' ? false : true}
                      isClearable
                      options={
                        campaing &&
                        campaing.map((com: ICampaign) => {
                          return {
                            value: `${com.Id}`,
                            label: com.PaymentTermsName,
                          }
                        })
                      }
                      value={state.campaing}
                      onChangeSingle={option => {
                        setState({ campaing: option })
                      }}
                    />
                  </div>
                }
              </div>
              {installment.length > 0 &&
                installment.map((item, index) => {
                  // Determine if the selected installment rate allows restricted banks
                  const isRestrictedRate = item.InstalmentRate && item.InstalmentRate.value !== '1'

                  // Filter bank options based on the selected installment rate
                  const filteredBanks = isRestrictedRate
                    ? bank.filter(bankOption => ![9001, 1402].includes(Number(bankOption.value)))
                    : bank

                  return (
                    <div className='row m-t-5' key={item.StateId}>
                      <div className='col-4'>
                        <label className='labeltext'>Banka</label>
                        <IPSelectBox
                          isDisabled={item.Update}
                          isMulti
                          isClearable
                          id='BankName'
                          name='BankName'
                          onChangeMulti={options => {
                            handleBankName(options, item)
                          }}
                          options={filteredBanks}
                          value={item.BankName}
                        />
                        {!item.Update && !company && bank.length > 0 && (
                          <>
                            <Link to='' onClick={() => handleAllBank(item)}>
                              Tüm Bankaları Getir
                            </Link>
                            &nbsp;&nbsp;&nbsp;&nbsp; {/* 1 tab boşluk */}
                            <Link to='' onClick={() => handleAllBankParam(item)}>
                              Tüm Bankaları Getir (PARAM POS)
                            </Link>
                          </>
                        )}
                      </div>
                      <div className='col-2'>
                        <label className='labeltext'>Taksit Adedi</label>
                        <IPSelectBox
                          isClearable
                          name='InstalmentRate'
                          options={installmentAddNum}
                          value={item.InstalmentRate}
                          onChangeSingle={option => {
                            handleInstalmentRate(option, item)
                          }}
                        />
                      </div>
                      <div className='col-2'>
                        <label className='labeltext'>Vade(max)</label>
                        <IPInput
                          name='TermDay'
                          type='text'
                          value={item.TermDay}
                          onChange={e => {
                            handleQuantity(e, item)
                          }}
                          onBlur={e => handleQuantityOnBlur(e, item)}
                        />
                      </div>
                      <div className='col-1'>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            removeInstallmentItem(item)
                          }}
                          type='button'
                          className='btn btn-danger m-t-5'
                          text='Sil'
                          disabled={installment.length < 2 ? true : false}
                        />
                      </div>
                      <div className='col-1' style={{ marginLeft: '20px' }}>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            addInstallmentField(index)
                          }}
                          type='button'
                          className='btn btn-success m-t-5'
                          text='Ekle'
                        />
                      </div>
                      <div className='col-1' style={{ marginLeft: '20px' }}>
                        <label className='labeltext'></label>
                        <IPButton
                          onClick={e => {
                            copyInstallmentField(item, index)
                          }}
                          type='button'
                          className='btn btn-success m-t-5'
                          text='Kopyala'
                        />
                      </div>
                    </div>
                  )
                })}
              <div className='form-row mt-4' style={{ float: 'right' }}>
                <IPButton
                  text={state.buttonName}
                  type='button'
                  className='btn btn-success w-25 float-right'
                  onClick={() => addInstallment()}
                />
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(BankAdd)
