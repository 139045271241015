import React, { useMemo, useState, useEffect } from 'react'
import IPButton from '../../../../../components/IPButton'
import '../../BestSellerList/components/BestSellerList.scss'
import IPInput from '../../../../../components/IPInput'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import Swal from 'sweetalert2'
import { IProductIsActive } from '../models/models'
import { IsProductActiveService } from '../services/isProductServices'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { GetAllProductActiveService } from '../services/getAllProducts'
import { GetProductBySearch } from '../services/getProductBySearch'
import IPSelectBox, { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { exportToXLSX } from '../../../../../base/utils/dataHelper'
import { UpdateProductBySearch } from '../services/updateProductBySearch'
import { UpdateRedis } from '../services/updateRedis'
import Table from '../../../../../components/Table'

function IsProductActive(props: BaseComponentWithContextProps) {
  const [productCode, setProductCode] = useState("")
  const [productSearch, setProductSearch] = useState("")
  const [catSearch, setCatSearch] = useState("")
  const [searchData, setSearchData] = useState<any>()
  const [details, setDetails] = useState<IProductIsActive[]>([])
  const [allProducts, setAllProducts] = useState<any[]>([])
  const [allProductsFilter, setAllProductsFilter] = useState<any[]>([])
  const [selectedSearch, setSelectedSearch] = useState<ISelectOption>()
  const [selectedStatus, setSelectedStatus] = useState<ISelectOption>()
  const [selectedCompany, setSelectedCompany] = useState<ISelectOption>()



  const RecursiveTree = ({ item }) => {
    if (!item) return null

    return (
      <div>
        <p>{<Link
          target="_blank"
          to={`/products?categoryId=${(item.Id)}&global-search=true`}
        >
          {item.Title}
          {/* {item.Title} {item.SapCodes ? `(${item.SapCodes})` : ""} */}
        </Link>}</p>
        {item.Item && <RecursiveTree item={item.Item} />}
      </div>
    )
  }

  const RecursiveCount = ({ item, count = 0 }) => {
    if (!item) return count
    return RecursiveCount({ item: item.Item, count: count + 1 });
  }

  // const RecursiveTree = ({ item }) => {
  //   if (!item) return null;

  //   return (
  //     <div>
  //       <>Id:{<Link
  //         target="_blank"
  //         to={`/products?categoryId=${(item.Id)}&global-search=true`}
  //       >
  //         {item.Id}
  //       </Link>},Başlık:{item.Title},Sap:{item.SapCodes}</>
  //       {item.Item && <RecursiveTree item={item.Item} />}
  //     </div>
  //   );
  // };


  const tableColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'col1',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'col2',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'col3',
      },
      {
        Header: 'Şirket',
        accessor: 'col4',
      },
      {
        Header: 'Aktif',
        accessor: 'col5',
      },
      {
        Header: 'Silinmiş',
        accessor: 'col6',
      },
      {
        Header: 'Özel Fiyat',
        accessor: 'col7',
      },
      {
        Header: 'TESK Fiyat',
        accessor: 'col8',
      },
      {
        Header: 'Liste Fiyatı',
        accessor: 'col9',
      },
      {
        Header: 'Menü',
        accessor: 'col10',
      },
      {
        Header: 'Görünürlük',
        accessor: 'vis',
      },
      {
        Header: 'Marka',
        accessor: 'col11',
      },
      {
        Header: 'Kategori',
        accessor: 'col12',
      },
      {
        Header: 'MatNumber',
        accessor: 'col13',
      },
      {
        Header: 'Model',
        accessor: 'col14',
      },
      {
        Header: 'Kota',
        accessor: 'col15',
      },
      {
        Header: 'Ürün Müdürü',
        accessor: 'col16',
      },
      {
        Header: 'KDV',
        accessor: 'col17',
      },
      {
        Header: 'Kategori Ağacı',
        accessor: 'col18',
      },
    ],
    []
  )

  const tableData = useMemo(
    () =>
      details.map(item => {
        const data = {
          col1: <Link
            target="_blank"
            to={`/product/detail/${item.Id}?from=productList`}
          >
            {item.Id}
          </Link>,
          col2: item.ProductCode,
          col3: item.ProductName,
          col4: item.Company,
          col5: item.Enabled ? <b style={{ color: 'green' }}>Evet</b> : <b style={{ color: 'red' }}>Hayır</b>,
          col6: item.Deleted ? <b style={{ color: 'red' }}>Evet</b> : <b style={{ color: 'green' }}>Hayır</b>,
          col7: item.PriceListSpecial ? <b style={{ color: 'green' }}>Var</b> : <b style={{ color: 'red' }}>Yok</b>,
          col8: item.PriceListEnd ? <b style={{ color: 'green' }}>Var</b> : <b style={{ color: 'red' }}>Yok</b>,
          col9: item.PriceListNormal ? <b style={{ color: 'green' }}>Var</b> : <b style={{ color: 'red' }}>Yok</b>,
          col10: item.CatList && RecursiveCount({ item: item.CatTree }) > 2 ?
            <>
              <b style={{ color: 'green' }}>Var</b>
            </>
            : item.CatList && RecursiveCount({ item: item.CatTree }) < 3 ?
              <>
                <b style={{ color: 'orange' }}>Düşük Seviye</b>
              </>
              :
              <>
                <b style={{ color: 'red' }}>Yok</b>
              </>
          ,
          col11: item.Brand,
          col12: item.CategoryCode,
          col13: item.MatNumber,
          col14: item.Model,
          col15: item.Quota,
          col16: item.MudName,
          col17: item.Tax,
          col18: <RecursiveTree item={item.CatTree} />,
          vis: item.Visibility ? <b style={{ color: 'orange' }}>Kayıt Mevcut</b> : <b style={{ color: 'green' }}>Kayıt Yok</b>
        }
        return data
      }),

    [details]
  )

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate: any = useNavigate()

  useEffect(() => {
    const productParam = searchParams.get('Product')
    if (productParam != null) {
      setProductCode(productParam)
      isProductCode(productParam)
    }
  }, [])

  const isProduct = async () => {
    navigate(`/admin/ProductOperation/IsProductActive?Product=${productCode}`)
    props.showLoading()
    try {
      const res = await new IsProductActiveService({}).isProductActive(productCode)
      if (res)
        setDetails(res)
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const getAllProducts = async () => {
    props.showLoading()
    try {
      const res = await new GetAllProductActiveService({}).getAllProducts()
      if (res) {
        setAllProducts(res)
        setAllProductsFilter(res)
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const updateProductById = async (data: any) => {
    const req = {
      Type: selectedSearch?.value,
      Data: data
    }
    props.showLoading()
    try {
      const res = await new UpdateProductBySearch({}).updateProductBySearch(req)
      if (res) {
        setAllProducts(res)
        setAllProductsFilter(res)
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const updateRedis = async () => {
    props.showLoading()
    try {
      const res = await new UpdateRedis({}).updateRedis()
      if (res) {
        Swal.fire('Başarılı', res.toString(), 'success')
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const getProductById = async (data: any) => {
    const req = {
      Type: selectedSearch?.value,
      Data: data
    }
    props.showLoading()
    try {
      const res = await new GetProductBySearch({}).getProductBySearch(req)
      if (res) {
        setAllProducts(res)
        setAllProductsFilter(res)
      }
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const isProductCode = async (product: string) => {
    props.showLoading()
    try {
      const res = await new IsProductActiveService({}).isProductActive(product)
      if (res)
        setDetails(res)
      props.hideLoading()
    } catch (err: any) {
      Swal.fire('Hata', err.description, 'error')
      props.hideLoading()
    }
  }

  const companies = [
    { label: 'Index', value: 1 },
    { label: 'Despec', value: 3 },
    { label: 'Netex', value: 2 },
    { label: 'Datagate', value: 4 },
  ]

  const statusOption = [
    { label: 'Aktif', value: 1 },
    { label: 'Pasif', value: 0 },
  ]

  const searchType = [
    { label: 'Ürün ID', value: 1 },
    { label: 'Ürün Kodu', value: 2 },
    { label: 'SAP Kodu', value: 3 },
  ]

  const excelButton = async () => {
    try {
      if (allProductsFilter) {
        const data: any[] = []
        if (allProductsFilter.length > 0) {
          allProductsFilter.map(item => {
            return data.push({
              "Ürün Kodu": item.ProductCode,
              "Ürün Adı": item.Name,
              "Malzeme Numarası": item.MatNumber,
              "Kota": item.QuotaNo,
              "Marka": item.Brand,
              "Model": item.Model,
              "Kategori Kodu": item.CategoryCode,
              "Ürün Müdürü": item.MudName,
              "Taksit Sayısı": item.InstallmentCount,
              "Şirket":
                item.CompanyId === 1
                  ? 'Index'
                  : item.CompanyId === 2
                    ? 'Netex'
                    : item.CompanyId === 3
                      ? 'Despec'
                      : item.CompanyId === 4
                        ? 'Datagate'
                        : '',
              "Durum": item.Enabled === true ? "Aktif" : item.Enabled === false ? "Pasif" : "",
              "Silindi": item.Deleted === true ? "Evet" : item.Deleted === false ? "Hayır" : "",
            })
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Excele atılacak veri bulunmamaktadır.',
            allowOutsideClick: true,
            backdrop: true,
          })
          return
        }
        exportToXLSX(data, 'Tüm Ürünler Listesi')
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        allowOutsideClick: true,
        backdrop: true,
      })
    }
  }

  const tableColumnsAllProducts = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'order',
      },
      {
        Header: 'ID',
        accessor: 'id',
      },
      {
        Header: 'Ürün Kodu',
        accessor: 'productCode',
      },
      {
        Header: 'Ürün Adı',
        accessor: 'productName',
      },
      {
        Header: 'Şirket',
        accessor: 'company',
      },
      {
        Header: 'Aktif',
        accessor: 'enabled',
      },
      {
        Header: 'Silinmiş',
        accessor: 'deleted',
      },
      {
        Header: 'Marka',
        accessor: 'brand',
      },
      {
        Header: 'Model',
        accessor: 'model',
      },
      {
        Header: 'MatNumber',
        accessor: 'matNumber',
      },
      {
        Header: 'Kota',
        accessor: 'quota',
      },
      {
        Header: 'Ürün Müdürü',
        accessor: 'mudName',
      },
      {
        Header: 'Taksit',
        accessor: 'installment',
      },
      {
        Header: 'SAP Kategori',
        accessor: 'CategoryCode',
      },
      {
        Header: 'Kategori Ağacı',
        accessor: 'catTree',
      },
      // {
      //   Header: 'test',
      //   accessor: 'test',
      // },
    ],
    []
  )

  const tableDataAllProducts = useMemo(
    () =>
      allProductsFilter.map((item, index) => {
        const data = {
          order: index + 1,
          id: <Link
            target="_blank"
            to={`/product/detail/${item.Id}?from=productList`}
          >
            {item.Id}
          </Link>,
          productCode: item.ProductCode,
          productName: item.Name,
          company: companies.find(a => a.value == item.CompanyId)?.label,
          enabled: item.Enabled ? 'Evet' : 'Hayır',
          deleted: item.Deleted ? 'Evet' : 'Hayır',
          brand: item.Brand,
          CategoryCode: item.CategoryCode,
          matNumber: item.MatNumber,
          model: item.Model,
          quota: item.QuotaNo,
          mudName: item.MudName,
          installment: item.InstallmentCount,
          catTree: <RecursiveTree item={item.catTree} />,
          //test: JSON.stringify(item.catTree),
        }
        return data
      }),

    [allProductsFilter]
  )

  useEffect(() => {
    const filtered = allProducts.filter(item => {
      const matchesProductSearch =
        item.ProductCode.toLowerCase().includes(productSearch.toLowerCase()) ||
        item.Model.toLowerCase().includes(productSearch.toLowerCase()) ||
        item.Brand.toLowerCase().includes(productSearch.toLowerCase()) ||
        item.Name.toLowerCase().includes(productSearch.toLowerCase());


      const matchesCatSearch =
        item.CategoryCode.toLowerCase().includes(catSearch.toLowerCase()) ||
        JSON.stringify(item.catTree).toLowerCase().includes(catSearch.toLowerCase());

      const matchesCompany = selectedCompany ? item.CompanyId === selectedCompany.value : true;
      const matchesStatus = selectedStatus ? item.Enabled === (selectedStatus.value === 1 ? true : false) : true;

      return matchesProductSearch && matchesCatSearch && matchesCompany && matchesStatus;
    });

    setAllProductsFilter(filtered);
  }, [productSearch, catSearch, selectedStatus, selectedCompany]);



  return (
    <div className='container' style={{ maxWidth: '100%' }}>
      <div className='card-header'>
        <span className='h4'>Ürün Bilgisi Sorgulama / Güncelleme</span>
      </div>
      <form id='form1' className='form-validate' noValidate>
        <div className='row '>
          <div className='col-md-6'>
            <IPInput
              type='text'
              className='form-control'
              name='productCode'
              placeholder='Ürün Kodu / Malzeme Numarası'
              value={productCode}
              onChange={(e) => setProductCode(e.target.value)}
            />
          </div>
          <div className='col-md-1'>
            <IPButton
              type='button'
              className='btn btn-success'
              text={"Sorgula"}
              onClick={isProduct}
            />
          </div>
          <div className='col-md-3 mt-3'>
            <label style={{ color: 'red' }}>Tüm ürünler için fiyat hesaplanacaktır. Yavaş çalışabilir.</label>
          </div>
          <div className='col-md-2'>
            <IPButton
              type='button'
              className='btn btn-success'
              text={"REDİS"}
              style={{ display: productCode == 'YAZGEL REDİS' ? '' : 'none' }}
              onClick={updateRedis}
            />
          </div>
        </div>

      </form>
      <div className='col-lg-12'>
        <Table columns={tableColumns} data={tableData} currentPage={0} />
      </div>
      <br></br>
      <div className='row'>
        <div className='col-lg-6'>
          <div className='row'>
            <div className='col-lg-4'>
              <IPInput
                type='text'
                className='form-control'
                name='productSearch'
                placeholder='Ürün Bilgilerinde Ara'
                value={productSearch}
                onChange={(e) => setProductSearch(e.target.value)}
              />
            </div>
            <div className='col-lg-4'>
              <IPInput
                type='text'
                className='form-control'
                name='catSearch'
                placeholder='Kategori Bilgilerinde Ara'
                value={catSearch}
                onChange={(e) => setCatSearch(e.target.value)}
              />
            </div>
          </div>
          <br></br>
          <div className='row'>
            {
              allProductsFilter &&
              <>
                <div className='col-lg-4'>
                  <IPSelectBox
                    name='company'
                    options={companies}
                    value={selectedCompany}
                    placeholder={'Şirket Seçiniz...'}
                    onChangeSingle={(option) => setSelectedCompany(option)}
                    isClearable
                  />
                </div>
                <div className='col-lg-4'>
                  <IPSelectBox
                    name='status'
                    options={statusOption}
                    value={selectedStatus}
                    placeholder={'Durum Seçiniz...'}
                    onChangeSingle={(option) => setSelectedStatus(option)}
                    isClearable
                  />
                </div>
              </>
            }
          </div>
        </div>
        <div className='col-lg-6'>
          <div className='row d-flex justify-content-end'>
            <div className='col-md-3'>
              <IPSelectBox
                name='searchType'
                options={searchType}
                value={selectedSearch}
                onChangeSingle={(option) => setSelectedSearch(option)}
              />
            </div>
            <div className='col-md-4'>
              <IPInput
                type='text'
                className='form-control'
                name='searchData'
                value={searchData}
                onChange={(e) => setSearchData((e.target.value))}
              />
            </div>
            <div className='col-md-3'>
              <IPButton
                type='button'
                className='btn btn-success'
                text={"Seçili Ürünü Güncelle (Redis)"}
                onClick={() => updateProductById(searchData)}
              />
            </div>
          </div>
          <br></br>
          <div className='row d-flex justify-content-end'>
            <div className='col-md-3'>
              <IPButton
                type='button'
                className='btn btn-success'
                text={"Excel'e Aktar"}
                onClick={excelButton}
              />
            </div>
            <div className='col-md-4'>
              <IPButton
                type='button'
                className='btn btn-success'
                text={"Seçili Ürünü Bul (Redis)"}
                onClick={() => getProductById(searchData)}
              />
            </div>
            <div className='col-md-3'>
              <IPButton
                type='button'
                className='btn btn-success'
                text={"Tüm Ürünleri Göster (Redis)"}
                onClick={getAllProducts}
              />
              <label style={{ color: 'red' }}>
                Yavaş çalışabilir.
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='col-lg-12'>
        <Table columns={tableColumnsAllProducts} data={tableDataAllProducts} currentPage={0} />
      </div>
    </div>
  )
}

export default componentWithContext(IsProductActive)
