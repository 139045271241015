/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from '@emotion/css'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../../../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { translate } from '../../../../../base/localization'
import { IException } from '../../../../../base/models'
import IPButton from '../../../../../components/IPButton'
import IPModal from '../../../../../components/IPModal'
import Table from '../../../../../components/Table'
import Translations from '../../../../../translations'
import { IBankAccountNumbers } from '../../../../FinancialInformation/models/models'
import { BankAccountListApi } from '../services/bankAccountListApi'
import { DeleteBankAccountApi } from '../services/deleteBankAccountApi'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'

export interface BankAccountListState {
  bankAccounts: IBankAccountNumbers[]
  filterBankAccounts: IBankAccountNumbers[]
  selectedCompany?: ISelectOption
  searchFilter: string
}

function BankAccountList(props: BaseComponentWithContextProps) {
  const t = translate(Translations)
  const getInitialState = (): BankAccountListState => {
    return {
      bankAccounts: [],
      filterBankAccounts: [],
      searchFilter: ''
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<BankAccountListState>(intialState)

  useEffect(() => {
    getBankAccounts()
  }, [])

  useEffect(() => {
    const filterConditions: ((item: IBankAccountNumbers) => boolean)[] = []
    if (state.selectedCompany && state.selectedCompany != null) {
      filterConditions.push(b => b.Company === state.selectedCompany?.value)
    }

    if (state.searchFilter && state.searchFilter != '') {
      filterConditions.push(x => {
        const searchTerm = state.searchFilter.toUpperCase()
        return (
          x.BankName.includes(searchTerm) ||
          x.Type.includes(searchTerm) ||
          x.BranchName.includes(searchTerm) ||
          x.AccountNumber.includes(searchTerm) ||
          x.Branch.includes(searchTerm) ||
          x.Iban.includes(searchTerm) ||
          x.Company.toString().includes(searchTerm) ||
          x.BranchCode.includes(state.searchFilter)
        )
      })
    }

    let filteredData: IBankAccountNumbers[] = [];

    if (filterConditions.length > 0) {
      filteredData = state.bankAccounts.filter(item => {
        return filterConditions.every(condition => condition(item))
      })
      setState({ filterBankAccounts: filteredData })
    }
    else
      setState({ filterBankAccounts: state.bankAccounts })


  }, [state.searchFilter, state.selectedCompany])

  const getBankAccounts = async () => {
    try {
      props.showLoading()
      const api: BankAccountListApi = new BankAccountListApi({})
      api.listBankAccount().then((result: IBankAccountNumbers[]) => {
        props.hideLoading()
        if (result) {
          setState({ bankAccounts: result, filterBankAccounts: result })
        }
      }).catch((err: any) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: (err as IException).description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
    } catch (err) {
      props.hideLoading()
      Swal.fire({
        icon: 'error',
        title: (err as IException).description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  const deleteBankAccount = async (ID: number) => {
    const shouldDelete = await Swal.fire({
      icon: 'warning',
      title: 'Bu banka bilgisini silmek istediğinizden emin misiniz?',
      showCancelButton: true,
      showConfirmButton: true,
      allowOutsideClick: false,
      confirmButtonText: 'Evet',
      cancelButtonText: 'Hayır',
    })

    if (shouldDelete.isConfirmed) {
      const deleteBankNote = new DeleteBankAccountApi({})
      try {
        const result = await deleteBankNote.deleteBankAccount(ID)

        if (result) {
          Swal.fire({
            icon: 'success',
            title: 'Banka kaydı silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          getBankAccounts()
        }
      } catch (err: any) {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    }
  }

  const CompanyOption = [
    { label: 'Index', value: 1000 },
    { label: 'Datagate', value: 1100 },
    { label: 'Despec', value: 1200 },
    { label: 'Netex', value: 2300 },
  ]


  const navigate: any = useNavigate()

  const updateBankNote = async (Id: number) => {
    var dt = state.bankAccounts.find(auth => {
      return auth.Id === Id
    })
    navigate('/admin/BankAndInstallementOperation/BankAccounts/AddBankAccount', {
      state: { bankAccountData: dt },
    })
  }

  // Tablo başlıkları ve sütun değerleri
  const columnDefinitions = [
    { Header: '#', accessor: 'index' },
    { Header: 'Şirket', accessor: 'company' },
    { Header: 'Banka', accessor: 'bank' },
    { Header: 'Şube', accessor: 'branch' },
    { Header: 'Şube Kodu', accessor: 'branchCode' },
    { Header: 'Şube Adı', accessor: 'branchName' },
    { Header: 'Hesap Türü', accessor: 'type' },
    { Header: 'Hesap Numarası', accessor: 'accountNumber' },
    { Header: 'IBAN', accessor: 'iban' },
    { Header: 'İşlemler', accessor: 'actions' },
  ]
  const tableColumns = useMemo(() => columnDefinitions, [])

  const tableData = useMemo(() => {
    if (!Array.isArray(state.bankAccounts)) {
      return []
    }

    return state.filterBankAccounts.map((item, index) => {
      const data = {
        index: index + 1,
        company: CompanyOption.find(a => a.value === item.Company)?.label,
        bank: item.BankName,
        branch: item.Branch,
        branchCode: item.BranchCode,
        branchName: item.BranchName,
        type: item.Type,
        accountNumber: item.AccountNumber,
        iban: item.Iban,
        actions: (
          <>
            <a
              onClick={() => updateBankNote(item.Id)}
              data-action-type='edit'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={'Düzenle'}
            >
              <i
                className={`${css`
                  font-size: 1.2rem;
                  color: green;
                `} icon-edit`}
              ></i>
            </a>
            <a
              onClick={() => deleteBankAccount(item.Id)}
              data-action-type='delete'
              role='button'
              className={css`
                cursor: pointer;
              `}
              data-toggle='tooltip'
              data-original-title={'Sil'}
            >
              <i
                className={`${css`
                  font-size: 1.2rem;
                  color: red;
                `} icon-trash-2`}
              ></i>
            </a>
          </>
        ),
      }
      return data
    })
  }, [state.filterBankAccounts])

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between align-items-center'>
            <div className='col-12 col-md-9 mb-2'>
              <span className='h4 mb-0'>Banka Hesap Bilgileri</span>
            </div>
            <div className='col-12 col-md-2 mb-2'>
              <label className='p-switch switch-color-success'>
                <Link to='/admin/BankAndInstallementOperation/BankAccounts/AddBankAccount'>
                  <IPButton
                    text='Hesap Bilgisi Ekle'
                    className='btn btn-primary'
                    style={{ height: '45px', marginLeft: '15px' }}
                  />
                </Link>
              </label>
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='form-group col-md-2'>
                <IPSelectBox
                  isClearable
                  placeholder='Şirket'
                  options={CompanyOption}
                  value={state.selectedCompany}
                  onChangeSingle={option => {
                    setState({ selectedCompany: (option) })
                  }}
                />
              </div>
              <div className='form-group col-md-2'>
                <IPInput
                  type='text'
                  id='searchFilter'
                  className='form-control'
                  name='searchFilter'
                  placeholder='Arama'
                  onChange={e => setState({ searchFilter: e.target.value?.toUpperCase() })}
                  value={state.searchFilter}
                />
              </div>
            </div>
            <div className='m-4'>
              <Table columns={tableColumns} data={tableData} currentPage={0} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default componentWithContext(BankAccountList)
