import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IDeleteImagesModel } from '../models/requestModels'

export class DeleteImageListApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/product/image-delete-list',
      requesterInfo,
    })
  }

  public async deleteImage(req: IDeleteImagesModel): Promise<any> {
    return await this.postAsync<any>(req)
  }
}
