import React, { useEffect, useRef, useState } from 'react'
import { useCustomReducer } from '../../../../../base/customHooks'
import Image from '../../../../../components/IPImage/IPImage'
import IPModal from '../../../../../components/IPModal/IPModal'
import IPInput from '../../../../../components/IPInput'
import IPButton from '../../../../../components/IPButton'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getUploadFileRequest } from '../../../../../base/utils/dataHelper'
import Swal from 'sweetalert2'
import { IDeleteImagesModel, IImage, IProduct } from '../models/requestModels'
import { DeleteImageApi } from '../services/deleteImageApi'
import { PostImageApi } from '../services/postImageApi'
import { UpdateImageApi } from '../services/updateImageApi'
import _ from 'lodash'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import { DeleteImageListApi } from '../services/deleteImageListApi'
import IPCheckbox from '../../../../../components/IPCheckBox'

interface IListHomePageProductsState {
  showProduct: boolean
  name: string
  image: string
  description: string
  imageId: number
  deleteImageModel?: IDeleteImagesModel
}

function HomePageProducts(props: BaseComponentWithContextProps) {
  const routeLocation = useLocation()
  const getInitialState = (): IListHomePageProductsState => {
    return {
      showProduct: false,
      name: '',
      description: '',
      imageId: 0,
      image: '',
      deleteImageModel: { Images: [], ProductId: 0 }
    }
  }
  let navigate = useNavigate()
  const [product, setProduct] = useState<IProduct>()
  const [images, setImages] = useState<IImage[]>([])
  const [numbers, setNumbers] = useState<number[]>([])
  const inputEl: any = useRef(null)

  const handleAddNumber = (id: number) => {
    if (numbers.includes(id)) {
      setNumbers(numbers.filter((number) => number !== id))
    } else {
      setNumbers([...numbers, id])
    }
  }


  const addProduct = async () => {
    const data = {
      ProductId: product ? product.Id : 0,
      Images: images,
    }
    const updateImage: UpdateImageApi = new UpdateImageApi({})
    updateImage
      .updateImage(data)
      .then((result: IImage) => {
        Swal.fire({
          icon: 'success',
          title: 'Ürün Güncellendi',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(async res => {
          navigate('/admin/ProductOperation/VisualDocumentationList')
        })
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }
  const addImage = async () => {
    props.showLoading()
    const data = {
      ProductId: product ? product.Id : 0,
      Images: [
        {
          Id: state.imageId,
          Label: state.name,
          Description: state.description,
        },
      ],
    }
    const updateImage: UpdateImageApi = new UpdateImageApi({})
    updateImage
      .updateImage(data)
      .then((result: any) => {
        props.hideLoading()
        Swal.fire({
          icon: 'success',
          title: 'Resim Güncellendi',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(async res => {
          setState({ showProduct: false })
          const data: IImage[] = []
          images.map((img: IImage) => {
            if (img.Id === state.imageId) {
              data.push({
                Id: img.Id,
                Image: img.Image,
                ImageUrl: img.ImageUrl,
                Label: state.name,
                Description: state.description,
                IsCover: img.IsCover,
              })
            } else data.push(img)
          })
          setImages(data)
        })
      })
      .catch((err: any) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<IListHomePageProductsState>(intialState)
  const handleImageFileUpload = async (e: any) => {
    const fileList: any[] = await getUploadFileRequest(e)

    for (const fileData of fileList) {
      if (fileData.size > 2000000) {
        return Swal.fire('Hata', 'Her resim 2 MB\'den küçük olmalıdır!', 'error');
      }

      const allowedTypes = ['.jpeg', '.png', '.gif', '.jpg'];
      if (!allowedTypes.includes(fileData.FileType)) {
        return Swal.fire('Hata', 'Her resim jpeg, png veya gif formatında olmalıdır!', 'error');
      }
    }

    // Create the Files array from the fileList array
    const data = {
      ProductId: product ? product.Id : 0,
      Files: fileList.map((fileData, index) => ({
        Id: index,
        Label: '',
        Description: '',
        File: fileData,
      })),
    };

    const postImage: PostImageApi = new PostImageApi({});
    postImage
      .addImage(data)
      .then((result: IImage[]) => {
        const datas = [...images, ...result];
        setImages(datas);
      })
      .catch((err: any) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        });
      });
  }

  useEffect(() => {
    if (routeLocation.state as any) setProduct((routeLocation.state as any)?.productData)
    setImages((routeLocation.state as any)?.productData.Images)
  }, [])

  const deleteImage = async (prodId: number, imageId: number) => {
    Swal.fire({
      icon: 'question',
      title: 'Resmi silmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        const api: DeleteImageApi = new DeleteImageApi({})
        const res = await api.deleteImage(prodId, imageId)
        if (res) {
          Swal.fire({
            icon: 'success',
            title: 'Resim Silindi',
            showConfirmButton: true,
            allowOutsideClick: false,
          }).then(async res => {
            setImages(prevState => {
              return prevState
                .filter((prev: IImage) => {
                  return prev.Id != imageId
                })
                .map(p => {
                  return p
                })
            })
          })
        }
      }
    })
  }

  const deleteImageList = async () => {
    if (product && numbers.length > 0) {
      const req: IDeleteImagesModel = {
        Images: numbers,
        ProductId: product.Id,
      };
      Swal.fire({
        icon: 'question',
        title: 'Seçili resimleri silmek istediğinize emin misiniz?',
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(async (result) => {
        if (result.isConfirmed) {
          const api: DeleteImageListApi = new DeleteImageListApi({});
          await api.deleteImage(req).then((res) => {
            if (res) {
              Swal.fire({
                icon: 'success',
                title: 'Resimler Silindi',
                showConfirmButton: true,
                allowOutsideClick: false,
              }).then(async (res) => {
                setImages((prevState) => {
                  return prevState.filter(
                    (prev: IImage) => !req.Images.includes(prev.Id)
                  );
                });
              });
            }
          })
            .catch((err: any) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              });
            })
        }
      });
    } else {
      return Swal.fire({
        icon: 'error',
        title: 'Silinmesi için resim seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      });
    }
  };



  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header'>
            <span className='h4'>
              <h5>{product?.Name && product?.Name + ' Ürününe'} Resim Ekleme</h5>
            </span>
          </div>
          <div className='card-body'>
            <div className='col-lg-12'>
              <div className='buttonsuz' style={{ justifyContent: 'space-between', display: 'flex', width: '100%' }}>
                <Link to='/admin/ProductOperation/VisualDocumentationList'>
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
                <Link to=''>
                  <IPButton type='button' className='btn btn-primary' text='Seçilenleri Sil' onClick={deleteImageList} />
                </Link>
              </div>
            </div>

            <div className='row m-t-10'>
              {product &&
                images.length > 0 &&
                images.map(img => (
                  <div className='col-lg-3 card-mobile-resize'>
                    <div className='your-recent-visits text-center'>
                      <div className='room '>
                        <div className='room-image text-center '>
                          <IPCheckbox
                            checked={img.checked}
                            onCheckedChange={() => handleAddNumber(img.Id)}
                          />
                          <Image src={img.ImageUrl} alt='' className='RecentVisits' />
                        </div>
                      </div>
                      <div className='product-description '>
                        <div className='product-title text-left'></div>
                        <div className='product-title text-left'>Görsel Adı:{img.Label}</div>
                        <div className='product-title text-left'>Sıralama:{img.Description}</div>
                        <a
                          className='ml-2'
                          data-toggle='tooltip'
                          onClick={() =>
                            setState({
                              showProduct: true,
                              imageId: img.Id,
                              name: img.Label,
                              description: img.Description,
                              image: img.ImageUrl,
                            })
                          }
                          style={{ cursor: 'pointer', color: 'green', fontSize: '35px' }}
                        >
                          <i className='icon-edit' style={{ marginTop: '50px' }}></i>
                        </a>
                        {/* {
                          !img.IsCover && */}
                        <a
                          className='ml-2'
                          data-toggle='tooltip'
                          onClick={() => deleteImage(product.Id, img.Id)}
                          style={{ cursor: 'pointer', color: 'red', fontSize: '35px' }}
                        >
                          <i className='icon-trash-2' style={{ marginTop: '50px' }}></i>
                        </a>
                        {/* } */}
                      </div>
                    </div>
                  </div>
                ))}
              <div className='col-lg-3 mt-sm-4 mt-lg-0 card-mobile-resize'>
                <div className='your-recent-visits text-center' style={{ minHeight: '300px', height: '300px' }}>
                  <i
                    className='icon-plus'
                    style={{ fontSize: '150px', color: '#adb5bd' }}
                    onClick={() => inputEl.current.click()}
                  ></i>
                  <input
                    ref={inputEl}
                    type='file'
                    name='mobilImage'
                    className='form-control'
                    id='mobilImage'
                    style={{ display: 'none' }}
                    onChange={event => {
                      handleImageFileUpload(event)
                    }}
                    multiple
                  />
                  <br />
                  <h5 style={{ color: '#adb5bd' }}>Resim Ekle</h5>
                </div>
              </div>
            </div>
          </div>
          <div className='btnstyle'>
            <IPButton
              type='button'
              onClick={() => addProduct()}
              className='btn btn-success float-right col-md-2 m-10'
              text='Yeni Eklenen Resimleri Kaydet'
            />
          </div>
        </div>
      </div>
      <IPModal
        title='Resim Ekleme'
        show={state.showProduct}
        onClose={() => setState({ showProduct: false })}
        width={'auto'}
        onConfirm={() => addImage()}
      >
        <div>
          <label>Görsel Adı</label>
          <IPInput
            type='text'
            id='name'
            className='form-control'
            name='name'
            value={state.name}
            onChange={e => setState({ name: e.target.value })}
          />
          <label>Sıralama</label>
          <IPInput
            type='text'
            id='description'
            className='form-control'
            name='description'
            value={state.description}
            onChange={e => setState({ description: e.target.value })}
          />
        </div>
      </IPModal>
    </div >
  )
}
export default componentWithContext(HomePageProducts)