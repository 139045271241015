/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react'
import Cards, { Focused } from 'react-credit-cards'
import 'react-credit-cards/lib/styles.scss'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import { useCustomReducer } from '../../base/customHooks'
import { BaseComponentWithContextProps, componentWithContext } from '../../base/customHooks/componentHOC'
import IPButton from '../../components/IPButton'
import IPCheckbox from '../../components/IPCheckBox'
import IPDatePicker from '../../components/IPDatePicker'
import IPInput from '../../components/IPInput'
import Modal from '../../components/IPModal/IPModal'
import ModalAddress from '../../components/IPModal/IPModalAddress'
import IPSelectBox, { ISelectOption } from '../../components/IPSelectBox/IPSelectBox'
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks'
import {
  CompaniesGroup,
  IBasketDetail,
  ISimpleBasket,
  ISingleCampaignReplaceRequest,
  WarehousesGroup,
} from '../../redux/slices/basket/basketApi'
import {
  basketApiCallStatusRedux,
  changeGiftItemOnBasketRedux,
  getBasketCountRedux,
  getBasketRedux,
  setBasketRedux,
} from '../../redux/slices/basket/basketSlice'
import { IProduct } from '../ProductList/models/response/getProductListResponseModel'
import styles from './MyCard.module.scss'
import { formatCVC, formatExpirationDate } from './creditCardUtils'
import {
  AddAddressRequest,
  CurrencyType,
  IAddress,
  IAddressModel,
  IBasicProductModel,
  IBinResponse,
  ICargoItem,
  ICargoResponse,
  IConditionList,
  ICreateOfferProduct,
  ICreateOfferToSubDealerResponse,
  IData,
  IDealerSavedCondition,
  IDealerSavedConditionRequest,
  IDistrict,
  IGetBasketListResponse,
  IInstallmentPrices,
  IOrderDetail,
  IOrderNote,
  IPaymentMetod,
  ISavedConditionList,
  ISetBasketManagementByIdsRequest,
  IShippingDate,
  ISoftwareWarehauseOrderModel,
  ISwitchBasketResponse,
  IWarehouses,
  KampanyaVade,
  OrderThreedsRequestModel,
  PaymentRequestModel,
  PriceType,
  binNumberRequest,
  binRequest,
} from './models/models'
import { AddAddressApi } from './services/addAddressApi'
import { GetAddressesApi } from './services/getAddressesApi'
import { SaveOrderApi } from './services/saveOrderApi'
import useIyzicoPayment from './useIyzicoPayment'

import { IException } from '../../base/models'
// import required modules
import { Autoplay, Keyboard, Mousewheel, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import Image from '../../components/IPImage'
import '../../pages/Homepage/css/HomePageHeader.scss'
import '../../pages/Homepage/css/HomePageRedBanner.scss'
import '../../pages/ProductList/styles/_ProductCard1.scss'
import '../../pages/ProductList/styles/_ProductCard2.scss'

// Import Swiper styles
import { css } from '@emotion/css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { translate } from '../../base/localization'
import {
  createImageUrl,
  downloadFileFromUrl,
  printElement,
  printElementOrderRecord,
  stockIndicator,
} from '../../base/utils/dataHelper'
import AddToBasket from '../../components/AddToBasket'
import { IPInputDataPattern } from '../../components/IPInput/IPInput'
import IPPriceDisplay from '../../components/IPPriceDisplay'
import IPRadio from '../../components/IPRadio'
import IPTextarea from '../../components/IPTextarea'
import InputMask from '../../components/InputMask'
import { Spinner } from '../../components/Spinner'
import Tooltip, { TooltipState, getPosition } from '../../components/Tooltip'
import { GetCompaniesApi } from '../../layouts/components/client/services/getCompaniesApi'
import {
  addToFavoriteProductListRedux,
  favoriteProductsRedux,
} from '../../redux/slices/favoriteProduct/favoriteProductSlice'
import { IsNot3DPayment } from '../../services/isNot3DPayment'
import Translations from '../../translations'
import { IBannerTypeEnum } from '../Homepage/commonModels/models'
import { IBanner } from '../MySpecial/Payment/models/PaymentModel'
import { GetBannerDetailsApi } from '../MySpecial/Payment/services/getBannerDetailsApi'
import ProductCard4 from '../ProductList/components/ProductCard4'
import BasketItem, { Props } from './BasketItem'
import './BasketItem.scss'
import './MyCard.module.scss'
import WarehouseNote from './WarehouseNote'
import { OrderExcelResponse } from './models/orderExcel'
import { CreateOfferToSubDealerApi } from './services/createOfferToSubDealerApi'
import { ExcelToOfferApi } from './services/excelToOfferApi'
import { FindBankInfosApi } from './services/findBankInfosApi'
import { GetCargoListApi } from './services/getCargoListApi'
import { GetCityApi } from './services/getCityApi'
import { GetDealerSavedConditionApi } from './services/getDealerSavedConditionApi'
import { GetDistrictApi } from './services/getDistrictApi'
import { GetIsContinueToPurchasePhaseApi } from './services/getIsContinueToPurchasePhaseApi'
import { GetShippingDateApi } from './services/getShippingDate'
import { GetSuggestApi } from './services/getSuggestApi'
import { GetTownApi } from './services/getTownApi'
import { GetUserBasketListApi } from './services/getUserBasketListApi'
import { GetWarehousesApi } from './services/getWarehousesApi'
import { GiftCodeInputApi } from './services/giftCodeInputApi'
import { OfferSendMailApi } from './services/offerSendMailApi'
import { OrderMailApi } from './services/orderMailApi'
import { PostOrderExcelApi } from './services/postOrderExcelApi'
import { SaveOfferToSubDealerApi } from './services/saveOfferApi'
import { SendBinNumberApi } from './services/sendBinNumberApi'

interface MyCardState {
  sentBinNumber: boolean
  changeBasket: boolean
  activeBasket: ISelectOption[]
  newBasketNameBool: boolean
  showBasketsUpdateHistoryModal: boolean
  selectedBasketToReadyAction: ISelectOption[]
  selectedBasketToMoveOrCopyAction: ISelectOption[]
  baskets: ISelectOption[]
  newBasketInputValue: string
  selectedTab: string
  paymenyType?: ISelectOption
  paymenyCurrencies?: ISelectOption[]
  selectedAddress?: ISelectOption
  selectedBillAddress?: ISelectOption
  selectedShipmentFormat?: ISelectOption
  selectedLicenseAddress?: ISelectOption | undefined
  addresses?: IAddress
  addAddressParams: AddAddressRequest
  showAddressModal: boolean
  showCreateOfferModal: boolean
  recommendedProducts?: IProduct[]
  paymentMethod: string
  ccCVC: string | number
  ccExpiry: string | number
  ccFocus: Focused | undefined
  ccName: string
  ccNumber: string
  binResponse?: IBinResponse[]
  installmentInfo: IInstallmentPrices[]
  bankCode: number
  bankLogo: string
  ccInstallmentNumber: number
  buttonChange: boolean
  selectedProductIds: number[]
  calculatedOffer?: ICreateOfferToSubDealerResponse
  createOfferCurrencyType?: CurrencyType
  createOfferPercentage?: number | string
  createOfferPriceType?: PriceType
  cities?: ISelectOption[]
  towns?: ISelectOption[]
  town?: ISelectOption | null
  city?: ISelectOption | null
  country?: ISelectOption | null
  districts?: ISelectOption[]
  districtsData?: IDistrict[]
  district?: ISelectOption | null
  postCode: string
  showChangeProduct: boolean
  SingleCampaignProduct?: any
  SingleCampaignProductCheckbox: number
  SingleCampaignProductPrice: number
  SingleCampaignProductData: ISingleCampaignReplaceRequest
  selectedSingleCampaignId: number
  companies: any[]
  code: string
  approval: boolean
  shipmentDate: boolean
  SevkCode: string
  ShippingDate: Date
  ShippingTypes: ISelectOption[]
  ShippingType: string
  ShippingName: string
  shipping: boolean
  type: boolean
  dealerSavedCondition: IDealerSavedCondition[]
  savedConditionListCount: number
  showOrderDetails: boolean
  orderDetails: IOrderDetail
  totalTL: number
  totalUSD: number
  totalEUR: number
  errorMessage: []
  showSendMailModal: boolean
  sendMail: string
  cargoList?: ICargoResponse
  invoiceAddressList: boolean
  orderNotes: IOrderNote[]
  expandedIntallment: number
  productsOnBasket: IBasicProductModel[]
  cargoItem: ICargoItem[]
  shippingAddress?: IAddressModel
  approvalData?: []
  securePayment: boolean
  bannerProduct: IBanner[]
  bannerPayment?: IBanner
  isCampaignGiftApproved?: boolean
  adresModalName: string
  singleCampaigns: any[]
  isSingleCampaignsSet: boolean
  loading: boolean
  billAddressBool: boolean
  cardType: string
  softwareProducts: ISoftwareWarehauseOrderModel[]
  lisans: {
    ProductId: number
    Company: number
    Preferred: string
  }
  createOfferBool: boolean
  threedPaymentCompanyCode: string[]
  threedPaymentDisabled: boolean
  showMail: boolean
  mailName: string
  mailEmailText: string
  mailSubject: string
  mailNote: string
  warehouses: IWarehouses[]
  showInstallments: boolean
}

const formatDate = (date: Date) => {
  const d = new Date(date),
    year = '' + d.getFullYear()
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate()
  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day
  return [year, month, day].join('-')
}

const CountryOptions = [{ value: 'Türkiye', label: 'Türkiye' }]

export const EmailValidation = new RegExp(`^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$`)

function MyCard(props: BaseComponentWithContextProps & Props) {
  const [savedConditionList, setSavedConditionList] = useState<ISavedConditionList[]>([])
  const favoriteProducts = useAppSelector(favoriteProductsRedux)
  const paymentRef = useRef<any>()
  //const changeGiftButtonRef = useRef<HTMLDivElement>(null)

  const getDistrict = async (id: number) => {
    const getDistrictApi: GetDistrictApi = new GetDistrictApi({})

    const res = await getDistrictApi.getDistrict(id)

    if (res) {
      const data: ISelectOption[] = []
      res.map(district => {
        data.push({ value: district.Id, label: district.MahalleAdi })
      })
      dispatchState({ districts: data, districtsData: res })
    }
  }

  const t = translate(Translations)

  var xdata = false

  const getSuggest = async () => {
    if (!xdata) {
      xdata = true
      setSuggestList([])
      const getSuggest: GetSuggestApi = new GetSuggestApi({})

      const res = await getSuggest.getSuggestList().catch(() => {
        xdata = false
      })

      if (res) {
        setSuggestList(res)
        xdata = false
      }
    }
  }

  const switchBasket = () => {
    const basketId = Number(state.selectedBasketToReadyAction[0].value)

    props.switchBasket &&
      props
        .switchBasket({ BasketId: basketId })
        .then((res: ISwitchBasketResponse) => {
          if (res) {
            dispatchState({
              newBasketNameBool: true,
              baskets: res.Baskets.map((elem: ISimpleBasket) => {
                return { value: elem.Id, label: elem.BasketName }
              }),
            })

            const activeBasket = res.Baskets.find((item: ISimpleBasket) => item.IsActive)

            if (activeBasket) {
              Swal.fire({
                // position: 'top-end',
                icon: 'success',
                title: `Geçerli sepetiniz "${activeBasket.BasketName}" olacak şekilde değiştirildi.`,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            }
          }
        })
        .catch((err: IException) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
  }

  const getInitialState = (): MyCardState => {
    return {
      showInstallments: false,
      sentBinNumber: false,
      newBasketNameBool: false,
      changeBasket: false,
      activeBasket: [],
      showBasketsUpdateHistoryModal: false,
      newBasketInputValue: '',
      selectedBasketToReadyAction: [],
      selectedBasketToMoveOrCopyAction: [],
      baskets: [],
      selectedTab: 'products',
      // paymenyType: { label: "Ödeme Günü Havale", value: "ogh" },
      // paymenyCurrency: { label: "USD", value: "usd" },
      // selectedAddress: { label: "Merkez Mah. Erseven Sok. No: 8", value: "addr1" },
      selectedShipmentFormat: { label: 'Savah Sevk', value: 'savahsevk' },
      addAddressParams: Object.assign({}),
      showAddressModal: false,
      showCreateOfferModal: false,
      paymentMethod: '',
      ccCVC: '',
      ccExpiry: '',
      ccFocus: 'number',
      ccName: '',
      ccNumber: '',
      installmentInfo: [],
      bankCode: 0,
      bankLogo: '',
      ccInstallmentNumber: 1,
      buttonChange: false,
      selectedProductIds: [],
      createOfferCurrencyType: CurrencyType.TL,
      createOfferPriceType: PriceType.Normal,
      createOfferPercentage: 0,
      postCode: '',
      showChangeProduct: false,
      SingleCampaignProductCheckbox: 0,
      SingleCampaignProductPrice: 0,
      SingleCampaignProductData: {
        Id: 0,
        BasketId: 0,
        ProductId: 0,
        Quantity: 0,
        CompanyId: 0,
        WarehauseId: 0,
        IsCampaign: true,
        CampaignBundleId: 0,
        CampaignPrice: 0,
        IsCampaignExtra: true,
        IsSingleCampaign: true,
      },
      selectedSingleCampaignId: 0,
      companies: [],
      code: '',
      approval: false,
      shipmentDate: false,
      SevkCode: '',
      ShippingDate: new Date(),
      ShippingType: '',
      ShippingName: '',
      ShippingTypes: [],
      shipping: false,
      type: false,
      dealerSavedCondition: [],
      savedConditionListCount: 0,
      showOrderDetails: false,
      orderDetails: {
        DealerName: '',
        Address: '',
        City: '',
        District: '',
        OrderNumber: '',
        Currency: 0,
        CreatedDate: new Date(),
        ShipmentDate: new Date(),
        ShipmentType: '',
        OrderReturnCompanyList: [
          {
            Cargo: '',
            CargoValue: 0,
            Items: [
              {
                Warehouse: '',
                ProductCode: '',
                ProductName: '',
                Quantity: 0,
                Currency: 0,
                Price1: '',
                Price1Value: 0,
                Price2: '',
                Price2Value: 0,
                TotalPrice1: '',
                TotalPrice1Value: 0,
                TotalPrice2: '',
                TotalPrice2Value: 0,
              },
            ],
            Company: '',
            OrderNumber: '',
            PaymentMethod: '',
            CurrencyType: '',
            CurrencyValue: '',
            MT: '',
            TotalPrice: '',
            TotalKdv: '',
            Total: '',
          },
        ],
        Total: 0,
        ErrorMessages: [],
        IsStockError: [],
      },
      totalTL: 0,
      totalUSD: 0,
      totalEUR: 0,
      errorMessage: [],
      showSendMailModal: false,
      sendMail: '',
      invoiceAddressList: false,
      orderNotes: [],
      expandedIntallment: 0,
      productsOnBasket: [],
      cargoItem: [],
      securePayment: false,
      bannerProduct: [],
      adresModalName: 'Adres',
      singleCampaigns: [],
      isSingleCampaignsSet: false,
      loading: false,
      billAddressBool: false,
      cardType: '',
      softwareProducts: [],
      lisans: {
        ProductId: 0,
        Company: 0,
        Preferred: '0',
      },
      createOfferBool: false,
      threedPaymentCompanyCode: [],
      threedPaymentDisabled: false,
      showMail: false,
      mailName: '',
      mailEmailText: '',
      mailSubject: '',
      mailNote: '',
      warehouses: [],
    }
  }

  useEffect(() => {
    getSuggest()
    getBaskets()
    getWarehouses()
  }, [])

  useEffect(() => {
    if (state.changeBasket) {
      switchBasket()
      dispatchState({ changeBasket: false })
    }
  })

  const getBaskets = () => {
    const getBasketListApi: GetUserBasketListApi = new GetUserBasketListApi({})
    getBasketListApi
      .getBaskets()
      .then((res: IGetBasketListResponse) => {
        if (res && res.Baskets) {
          dispatchState({
            baskets: res.Baskets.map((elem: ISimpleBasket) => {
              return { value: elem.Id, label: elem.BasketName }
            }),
            selectedBasketToReadyAction: res.Baskets.filter((item: ISimpleBasket) => item.IsActive).map(
              (elem: ISimpleBasket) => {
                return { value: elem.Id, label: elem.BasketName }
              }
            ),
            activeBasket: res.Baskets.filter((item: ISimpleBasket) => item.IsActive).map((elem: ISimpleBasket) => {
              return { value: elem.Id, label: elem.BasketName }
            }),
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const getWarehouses = () => {
    const getWarehousesApi: GetWarehousesApi = new GetWarehousesApi({})
    getWarehousesApi
      .getWarehouses()
      .then((res: IWarehouses[]) => {
        dispatchState({ warehouses: res })
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const [isPaying, setIsPaying] = useState(false)
  const [shipmentDate, setShipmentDate] = useState<Date>()
  const [suggestProducts, setSuggestList] = useState<any>()

  const [iyzicoJs, setIyzicoJs] = useState('')
  const navigate = useNavigate()

  useIyzicoPayment(isPaying, iyzicoJs)

  // useScript(isPaying, 'https://zhenabia.com/iyzitest');
  // useScript('', content);
  // useScript('https://apis.google.com/js/api.js', content, 'goggle-tst');

  const intialState = getInitialState()
  const [state, dispatchState] = useCustomReducer<MyCardState>(intialState)
  const basketCount = useAppSelector(getBasketCountRedux)
  const basket = useAppSelector(getBasketRedux)
  const basketApiCallStatus = useAppSelector(basketApiCallStatusRedux)
  const dispatchRedux = useAppDispatch()

  const [AvailableCurrency, setAvailableCurrency] = useState<any[]>([])

  const currencyTypes = [
    { label: 'TL', value: CurrencyType.TL },
    { label: 'USD', value: CurrencyType.Dolar },
    { label: 'EUR', value: CurrencyType.Euro },
  ]

  const giftCodeInput = async () => {
    if (!state.code) {
      return Swal.fire('Hata', 'Kupon kodu girmediniz!', 'error')
    }

    if (state.approval) {
      Swal.fire({
        icon: 'question',
        title: 'Adresi onayladığınız için işlem yapamazsınız. Sayfayı yenilemek ister misiniz?',
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(res => {
        res.isConfirmed && window.location.reload()
      })
      return
    }

    props.showLoading()

    const giftCodeInput: GiftCodeInputApi = new GiftCodeInputApi({})
    giftCodeInput
      .giftCodeInput(state.code)
      .then((res: any) => {
        if (res) {
          dispatchRedux(setBasketRedux(res))
          props.hideLoading()
        }
      })
      .catch((err: IException) => {
        props.hideLoading()
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
      })
  }

  const savedList = async (companyId: string, data: ISelectOption, method, type?: any[]) => {
    if (savedConditionList.length > 0) {
      let i = 0

      if (method === 'currency') {
        setSavedConditionList(prevState => {
          return prevState.map((prev: ISavedConditionList) => {
            if (prev.Company === companyId) {
              prev = {
                Company: companyId,
                Condition: prev.Condition,
                Currency: data.label,
                Value: prev.Value,
                AvaibleCurrency: prev.AvaibleCurrency,
                CurrencySelect: method === 'currency' ? data : null,
              }
              i++
            }
            return prev
          })
        })

        if (i === 0) {
          const datas = {
            Company: companyId,
            Condition: '',
            Currency: data.label,
            Value: '',
            CurrencySelect: method === 'currency' ? data : null,
          }
          setSavedConditionList([...savedConditionList, datas])
        }

        return
      }

      setSavedConditionList(prevState => {
        return prevState.map((prev: ISavedConditionList) => {
          if (prev.Company === companyId) {
            prev = {
              Company: companyId,
              Condition: String(data.value),
              Value: data.label,
              Currency: type && type[0].label,
              AvaibleCurrency: type ? type : [],
              CurrencySelect: type && type[0],
            }
            i++
          }
          return prev
        })
      })

      if (i === 0) {
        const datas = {
          Company: companyId,
          Condition: String(data.value),
          Currency: type && type[0].label,
          Value: data.label,
          AvaibleCurrency: type ? type : [],
          CurrencySelect: type && type[0],
        }

        setSavedConditionList([...savedConditionList, datas])
      }

      return
    }

    const dt: ISavedConditionList[] = []

    if (method === 'currency') {
      dt.push({
        Company: companyId,
        Condition: '',
        Currency: method === 'currency' ? data.label : '',
        Value: '',
        CurrencySelect: method === 'currency' ? data : null,
      })

      setSavedConditionList(dt)

      return
    }

    dt.push({
      Company: companyId,
      Condition: String(data.value),
      Currency: type && type[0].value,
      Value: String(data.label),
      AvaibleCurrency: type ? type : [],
      CurrencySelect: type && type[0],
    })

    setSavedConditionList(dt)
  }

  const onQuantityChange = async () => {
    dispatchState({ isSingleCampaignsSet: false })
  }

  const checkSingleCampaigns = (tab?: '#products-tab' | '#address-tab' | '#payment-method-tab') => {
    if (basket?.ActiveBasket?.SingleCampaignItems !== null && state.isCampaignGiftApproved === false) {
      Swal.fire('Hata', 'Hediye ürün seçimi yapmadınız.', 'error').then(() => {
        setGiftProducts()
      })
      $('#products-tab').click()
      return false
    }
    if (tab) $(tab).click()
    return true
  }
  const handleIlerleClick = () => {
    const prod: IProduct[] = []
    for (const com of basket.ActiveBasket.Companies) {
      for (const ware of com.Warehouses) {
        if (ware.WarehouseName === '1920') {
          for (const deta of ware.BasketDetail) {
            prod.push(deta.Product)
          }
        }
      }
    }
    if (prod.length > state.softwareProducts.length) {
      Swal.fire({
        iconHtml: '<div class="custom-icon"></div>',
        title: 'Lütfen Yazılım ürünlerinin lisans bilgilerini giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(() => {
        document.getElementById('products-tab')?.click()
      })
    } else {
      checkSingleCampaigns('#address-tab')
    }
    setActiveTab('address')
  }

  const paymentMethod = async e => {
    e.preventDefault()
    e.stopPropagation()

    const control = checkSingleCampaigns('#payment-method-tab')

    if (!control) return

    if (!state.approval) {
      Swal.fire({
        icon: 'warning',
        title: 'Lütfen adresi onaylayınız',
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(() => {
        setActiveTab('address')
        $('#address-tab').click()
      })
      return
    }

    props.showLoading()
    const productCodes: string[] = []
    basket.ActiveBasket.Companies?.map((company: CompaniesGroup) => {
      return company.Warehouses.map(warehouse => {
        return warehouse.BasketDetail.map(det => {
          if (det.Product && det.Product.ProductCode) return productCodes.push(det.Product.ProductCode)
          return
        })
      })
    })
    const data: IDealerSavedConditionRequest = {
      ShippingType: state.ShippingType,
      ProductCodes: productCodes,
    }

    const getDealerSavedConditionApi: GetDealerSavedConditionApi = new GetDealerSavedConditionApi({})
    getDealerSavedConditionApi
      .getDealerSavedCondition(data)
      .then((result: IPaymentMetod[]) => {
        if (result) {
          const data: IDealerSavedCondition[] = []
          let savedList = 0
          if (state.companies.length > 0) {
            state.companies.map(com => {
              if (com.Id === 1) {
                result.map(r => {
                  if (r.Company === '1000') {
                    data.push({
                      Company: r.Company,
                      Logo: com.Image,
                      ConditionList: r.ConditionList,
                      Currency: r.Currency,
                    })
                    savedList++
                  }
                })
              }
              if (com.Id === 2) {
                result.map(r => {
                  if (r.Company === '2300') {
                    data.push({
                      Company: r.Company,
                      Logo: com.Image,
                      ConditionList: r.ConditionList,
                      Currency: r.Currency,
                    })
                    savedList++
                  }
                })
              }
              if (com.Id === 3) {
                result.map(r => {
                  if (r.Company === '1200') {
                    data.push({
                      Company: r.Company,
                      Logo: com.Image,
                      ConditionList: r.ConditionList,
                      Currency: r.Currency,
                    })
                    savedList++
                  }
                })
              }
              if (com.Id === 4) {
                result.map(r => {
                  if (r.Company === '1100') {
                    data.push({
                      Company: r.Company,
                      Logo: com.Image,
                      ConditionList: r.ConditionList,
                      Currency: r.Currency,
                    })
                    savedList++
                  }
                })
              }
            })
            dispatchState({
              dealerSavedCondition: data,
              savedConditionListCount: savedList,
            })
          }
          setActiveTab('payment-method')
          props.hideLoading()
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
      })
  }

  const addressMethod = async e => {
    e.preventDefault()
    e.stopPropagation()

    const prod: IProduct[] = []
    for (const com of basket.ActiveBasket.Companies) {
      for (const ware of com.Warehouses) {
        if (ware.WarehouseName === '1920') {
          for (const deta of ware.BasketDetail) {
            prod.push(deta.Product)
          }
        }
      }
    }
    if (prod.length > state.softwareProducts.length) {
      Swal.fire({
        iconHtml: '<div class="custom-icon"></div>',
        title: 'Lütfen Yazılım ürünlerinin lisans bilgilerini giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(() => {
        document.getElementById('products-tab')?.click()
      })
    } else {
      checkSingleCampaigns('#address-tab')
    }

    // checkSingleCampaigns('#address-tab')
  }

  enum bankNames {
    akbank = '46',
    halkbank = '12',
    denizbank = '134',
    Akbank = '46',
    AlbarakaTurk = '203',
    AlternatifBank = '124',
    Anadolubank = '135',
    Denizbank = '134',
    Fibabanka = '103',
    QNBFinansbank = '111',
    GarantiBBVA = '62',
    Halkbank = '12',
    HSBC = '123',
    INGBank = '99',
    IsBankasi = '64',
    KuveytTurk = '205',
    Odeabank = '146',
    TurkEkonomiBankasi = '32',
    TurkiyeFinans = '206',
    Vakifbank = '15',
    YapiKrediBankasi = '67',
    Sekerbank = '59',
    ZiraatBankasi = '10',
    AktifYatirimBankasi = '143',
  }

  const customStyles = {
    //TODO: set height
  }

  useEffect(() => {
    // getRecommendedProducts();
    getCity()
    setTimeout(() => {
      window.scrollTo({ top: 0 })
    }, 500)
  }, [])

  useEffect(() => {
    getAddresses()
    getCompanies()
    createNoteList()

    if (basket?.ActiveBasket?.Companies) {
      const products: IBasicProductModel[] = []
      const singleCampaigns: any = []

      for (const company of basket.ActiveBasket.Companies) {
        for (const warehouse of company.Warehouses) {
          for (const detail of warehouse.BasketDetail) {
            if (products.findIndex((elem: IBasicProductModel) => elem.Id === detail.Product.Id) === -1) {
              products.push({
                Id: detail.Product.Id,
                Name: detail.Product.Name,
                Code: detail.Product.ProductCode,
              })
            }
            if (detail.IsSingleCampaign && !state.isSingleCampaignsSet) {
              singleCampaigns.push({
                ...detail,
                isSelected: false,
              })
            }
          }
        }
      }

      dispatchState({ productsOnBasket: products })

      if (!state.isSingleCampaignsSet) {
        dispatchState({ singleCampaigns, isSingleCampaignsSet: true, isCampaignGiftApproved: false })
      }
    }
  }, [basket.ActiveBasket])

  const clearSingleCampaigns = () => {
    dispatchState({ isSingleCampaignsSet: false })
  }

  const createNoteList = () => {
    const notes: IOrderNote[] = []

    if (basket.ActiveBasket?.Companies) {
      for (const company of basket.ActiveBasket.Companies) {
        for (const warehouse of company.Warehouses) {
          const noteIdx = state.orderNotes.findIndex((elem: IOrderNote) => {
            return elem.CompanyId === company.CompanyID && elem.WarehouseId === warehouse.WarehouseID
          })

          if (noteIdx === -1) {
            notes.push({
              CompanyId: company.CompanyID,
              WarehouseId: warehouse.WarehouseID,
              Note: '',
            })
          } else {
            notes.push(state.orderNotes[noteIdx])
          }
        }
      }
    }

    if (notes.length) {
      dispatchState({ orderNotes: notes })
    }
  }

  const getCompanies = async () => {
    const data: IData[] = []
    if (basket && basket.ActiveBasket && basket.ActiveBasket.Companies) {
      basket.ActiveBasket.Companies.map(com => {
        let i = 0
        if (data.length > 0) {
          data.map(dt => {
            if (dt.Id === com.CompanyID) {
              return i++
            }
          })
        }
        if (i === 0) {
          data.push({ Id: com.CompanyID, Image: com.CompanyLogo })
        }
      })
    }
    dispatchState({ companies: data })
    // const getCompanyApi: GetCompanyApi = new GetCompanyApi({});

    // const res = await getCompanyApi.getCompany();

    // if (res) {
    //   dispatchState({ companies: res });
    // }
  }
  // useEffect(() => {
  //   if (!(basket && basket.ActiveBasket && basket.ActiveBasket.BasketDetails)) {
  //     return;
  //   }

  //   const companies: any[] = [];

  //   for (const item of basket.ActiveBasket.BasketDetails) {
  //     if (item.CompanyID) {
  //       const foundedCompany = companies.find(
  //         (elem: any) => elem.CompanyId === item.CompanyID
  //       );

  //       if (!foundedCompany) {
  //         companies.push({
  //           CompanyId: item.CompanyID,
  //           CompanyName: item.CompanyName,
  //           CompanyLogo: item.CompanyLogo,
  //           Currency: item.Currency,
  //           Warehouses: [
  //             {
  //               WarehouseId: item.WareHauseID,
  //               WarehouseName: item.WarehouseName,
  //               CompanyId: item.CompanyID,
  //             },
  //           ],
  //         });
  //       } else {
  //         const warehouse = foundedCompany.Warehouses.find(
  //           (elem: any) =>
  //             item.CompanyID === elem.CompanyId &&
  //             elem.WarehouseId === item.WareHauseID
  //         );

  //         if (!warehouse) {
  //           foundedCompany.Warehouses.push({
  //             WarehouseId: item.WareHauseID,
  //             WarehouseName: item.WarehouseName,
  //             CompanyId: item.CompanyID,
  //           });
  //         }
  //       }
  //     }
  //   }

  //   companies.forEach((company) => {
  //     let warehouseTotal = 0;
  //     let companyTotal = 0;

  //     for (const warehouse of company.Warehouses) {
  //       for (const bItem of basket.ActiveBasket.BasketDetails) {
  //         const price = bItem.CampaignInfoModel
  //           ? bItem.CampaignBasketDetailPrice?.Normal
  //           : bItem.BasketDetailPrice?.Normal;

  //         if (warehouse.WarehouseId === bItem.WareHauseID) {
  //           warehouseTotal = warehouseTotal + price;
  //         }

  //         if (company.CompanyId === bItem.CompanyID) {
  //           companyTotal = companyTotal + price;
  //         }
  //       }
  //       warehouse.TotalWarehousePrice = warehouseTotal;
  //     }

  //     company.CompanyTotalPrice = companyTotal;
  //   });

  //   dispatchState({ companies });
  // }, [basket]);

  // useEffect(() => {
  // dispatchState({
  //   selectedProductIds:
  //     basket &&
  //       basket.ActiveBasket &&
  //       basket.ActiveBasket.BasketDetails &&
  //       basket.ActiveBasket.BasketDetails.length > 0
  //       ? basket.ActiveBasket.BasketDetails.map(
  //         (elem: IBasketDetail) => elem.Id
  //       )
  //       : [],
  // });
  // }, [basket])

  useEffect(() => {
    if (basketApiCallStatus === 'loading') {
      getSuggest()

      props.showLoading && props.showLoading()
    } else {
      props.hideLoading && props.hideLoading()
    }
  }, [basketApiCallStatus])

  const getCity = async () => {
    const getCityApi: GetCityApi = new GetCityApi({})

    const res = await getCityApi.getCity()

    if (res) {
      const data: ISelectOption[] = []
      res.map(city => {
        data.push({ value: city.Id, label: city.Il })
      })
      dispatchState({ cities: data })
    }
  }

  const getTown = async (id: number) => {
    const getTownApi: GetTownApi = new GetTownApi({})

    const res = await getTownApi.getTown(id)
    const data: ISelectOption[] = []
    if (res) {
      res.map(town => {
        data.push({ value: town.IlceKodu, label: town.IlceAdi })
      })
      dispatchState({ towns: data })
    }
  }

  const SingleCampaignProduct = async (data: any, modal: boolean) => {
    if (state.approval) {
      Swal.fire({
        icon: 'question',
        title: 'Adresi onayladığınız için işlem yapamazsınız. Sayfayı yenilemek ister misiniz?',
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(res => {
        res.isConfirmed && window.location.reload()
      })
      return
    }

    if (modal) dispatchState({ showChangeProduct: true })

    dispatchState({
      SingleCampaignProductCheckbox: data.ProductID,
      SingleCampaignProductPrice: data.CampaignBasketDetailPrice?.Normal,
      SingleCampaignProductData: {
        Id: data.Id,
        BasketId: data.BasketID,
        ProductId: data.ProductID,
        Quantity: data.Quantity,
        CompanyId: data.CompanyID,
        WarehauseId: data.WareHauseID,
        IsCampaign: true,
        CampaignBundleId: data.CampaignBundleId,
        CampaignPrice: data.CampaignBasketDetailPrice?.Normal,
        IsCampaignExtra: true,
        IsSingleCampaign: true,
      },
      selectedSingleCampaignId: data.CampaignInfoModel?.CampaignBundleID,
    })
  }

  const [suggestQuantities, setSuggestQuantities] = useState({})

  const handleQuantityChange = (productId, quantity) => {
    setSuggestQuantities(prevState => ({
      ...prevState,
      [productId]: quantity,
    }))
  }

  const SingleCampaignProductReplace = async () => {
    // TODO : Seçimi değiştirmemişsse api isteği atılmamalı
    dispatchRedux(changeGiftItemOnBasketRedux(state.SingleCampaignProductData))

    const singleCampaigns = state.singleCampaigns.map(x => {
      if (x.CampaignInfoModel.CampaignBundleID === state.selectedSingleCampaignId) {
        x.isSelected = true
      }
      return x
    })

    const isCampaignGiftApproved = singleCampaigns.every(x => x.isSelected)

    dispatchState({
      showChangeProduct: false,
      singleCampaigns,
      isCampaignGiftApproved,
    })
  }

  const getAddresses = async () => {
    const getAddressesApi: GetAddressesApi = new GetAddressesApi({})
    if (basket.ActiveBasket?.Companies) {
      const res = await getAddressesApi.getAddresses(basket.ActiveBasket.Companies)

      if (res) {
        dispatchState({ addresses: res })
        if (!state.billAddressBool) {
          res?.InvoiceAddressList.filter((addr: IAddressModel) => {
            return addr.Preferred === '1'
          }).map((address: IAddressModel) => {
            dispatchState({
              selectedBillAddress: {
                value: `${address.Id}`,
                label: address.AddressTitle ? address.AddressTitle : address.AddressDetail,
              },
              billAddressBool: true,
            })
          })
        }
      }
    }
  }

  const addAddress = async () => {
    state.addAddressParams.Country = CountryOptions[0].label

    if (!state.addAddressParams.NameAndSurname) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen adı soyadı alanını doldurunuz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }
    if (state.addAddressParams.NameAndSurname.length < 5 || state.addAddressParams.NameAndSurname.length > 40) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Adı soyadı en az 5 ve en fazla 40 karakter olmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }
    if (state.addAddressParams.Unvan.length < 5 || state.addAddressParams.Unvan.length > 40) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Yetkili adı en az 5 ve en fazla 40 karakter olmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.Country) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen ülke seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.City) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen il seçilmelidir!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.District) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lürfen ilçe seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.PostCode) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen Mahalle seçiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.Phone) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen Telefon alanını doldurunuz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.AddressTitle) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen adres başlığı giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (state.addAddressParams.AddressTitle.length < 5 || state.addAddressParams.AddressTitle.length > 40) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Adres başlığı en az 5 ve en fazla 40 karakter olmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.AddressDetail) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen adres giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (state.addAddressParams.AddressDetail.length < 5 || state.addAddressParams.AddressDetail.length > 40) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Adres en az 5 ve en fazla 40 karakter olmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (state.addAddressParams.AddressDetail2.length > 40) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Adres 2 en fazla 40 karakter olmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.Email) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Lütfen email giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (!state.addAddressParams.Email.match(EmailValidation)) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Email formatı hatalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (
      (state.addAddressParams.Email.length < 5 || state.addAddressParams.Email.length > 128) &&
      state.adresModalName === 'Lisans'
    ) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Email en az 5 ve en fazla 128 karakter olmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }

    if (state.addAddressParams.Email.length < 5 || state.addAddressParams.Email.length > 128) {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: 'Email en az 5 ve en fazla 128 karakter olmalı!',
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }
    if (state.adresModalName === 'Adres') {
      const addAddressApi: AddAddressApi = new AddAddressApi({})
      await addAddressApi
        .addAddress(state.addAddressParams)
        .then(async res => {
          props.hideLoading()

          if (res) {
            const reqAdres: AddAddressRequest = {
              Id: 0,
              NameAndSurname: '',
              Country: CountryOptions[0].label,
              City: '',
              District: '',
              PostCode: '',
              SevkCode: '',
              Phone: '',
              Email: '',
              AddressDetail: '',
              AddressDetail2: '',
              AddressTitle: '',
              Unvan: '',
              Neighbourhood: '',
              isLicense: 0,
            }
            dispatchState({
              shippingAddress: res,
              showAddressModal: false,
              addAddressParams: reqAdres,
              towns: [],
              town: null,
              district: null,
              city: null,
              districts: [],
              postCode: '',
              SevkCode: '',
              country: CountryOptions[0],
            })

            Swal.fire({
              // position: 'top-end',
              icon: 'success',
              title: 'Yeni sevk adresi eklendi!',
              showConfirmButton: true,
              allowOutsideClick: false,
              // timer: 1500
            })
          }
        })
        .catch((err: any) => {
          props.hideLoading()
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
        })
    }
    if (state.adresModalName === 'Lisans') {
      const req: ISoftwareWarehauseOrderModel = {
        ...state.lisans,
        ...state.addAddressParams,
        Neighbourhood: state.district?.label,
      }
      saveAddress()
      const reqAdres: AddAddressRequest = {
        Id: 0,
        NameAndSurname: '',
        Country: CountryOptions[0].label,
        City: '',
        District: '',
        PostCode: '',
        SevkCode: '',
        Phone: '',
        Email: '',
        AddressDetail: '',
        AddressDetail2: '',
        AddressTitle: '',
        Unvan: '',
        Neighbourhood: '',
        isLicense: 0,
      }
      if (state.softwareProducts.length === 0)
        return dispatchState({
          softwareProducts: [req],
          showAddressModal: false,
          addAddressParams: reqAdres,
          towns: [],
          town: null,
          district: null,
          city: null,
          districts: [],
          postCode: '',
          SevkCode: '',
          country: CountryOptions[0],
        })
      let i = 0
      const software = state.softwareProducts.map(soft => {
        if (soft.ProductId === req.ProductId && soft.Company === req.Company) {
          i++
          return (soft = req)
        }
        return soft
      })
      if (i === 1) {
        dispatchState({
          softwareProducts: software,
          showAddressModal: false,
          addAddressParams: reqAdres,
          towns: [],
          town: null,
          district: null,
          city: null,
          districts: [],
          postCode: '',
          SevkCode: '',
          country: CountryOptions[0],
        })

        return
      }
      dispatchState({
        softwareProducts: [...state.softwareProducts, req],
        showAddressModal: false,
        addAddressParams: reqAdres,
        towns: [],
        town: null,
        district: null,
        city: null,
        districts: [],
        postCode: '',
        SevkCode: '',
        country: CountryOptions[0],
      })
    }
  }

  // const getAvaibleCurrency = (dealer: any) => {
  //   const data = savedConditionList.find(save => save.Company === dealer.Company)
  //   return dealer.ConditionList.find(x => x.Condition === data?.Value)?.AvailableCurrency?.map((item: any) => {
  //     return {
  //       label: item,
  //       value: `${item}`,
  //     }
  //   })
  // }

  useEffect(() => {
    if (state.shippingAddress !== undefined) {
      const adres: IAddress = {
        InvoiceAddressList: state.addresses ? state.addresses.InvoiceAddressList : [],
        ShippAddresList: state.addresses
          ? [...state.addresses.ShippAddresList, state.shippingAddress]
          : [state.shippingAddress],
        LicenseAddressList: state.addresses ? state.addresses.LicenseAddressList : [],
      }
      dispatchState({
        showAddressModal: false,
        addresses: adres,
        towns: [],
        town: null,
        district: null,
        // cities: [],
        city: null,
        districts: [],
        shippingAddress: undefined,
        country: CountryOptions[0],
      })
    }
  }, [state.shippingAddress])

  useEffect(() => {
    if (!state.cargoList) {
      dispatchState({
        shippingAddress: undefined,
        selectedAddress: undefined,
        shipping: false,
        type: false,
        ShippingType: undefined,
        ShippingTypes: undefined,
        ShippingName: undefined,
      })
      setShipmentDate(
        new Date().getHours() > 16 || (new Date().getHours() === 16 && new Date().getMinutes() >= 30)
          ? new Date(new Date().setDate(new Date().getDate() + 1))
          : new Date()
      )
    }
  }, [basket.ActiveBasket])

  const approval = async () => {
    if (state.approval) {
      Swal.fire({
        icon: 'question',
        title: 'Adresi onayladığınız için işlem yapamazsınız. Sayfayı yenilemek ister misiniz?',
        showConfirmButton: true,
        allowOutsideClick: false,
        showDenyButton: true,
        confirmButtonText: 'Evet',
        denyButtonText: 'Hayır',
      }).then(res => {
        res.isConfirmed && window.location.reload()
      })
      return dispatchState({ approval: true })
    }
    if (!state.shipping) {
      if (state.addresses?.ShippAddresList && state.addresses?.ShippAddresList.length > 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Sevk Adresi ve tarih seçmelisiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Tarih seçmelisiniz',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }

      return
    }
    if (!state.type) {
      Swal.fire({
        icon: 'warning',
        title: 'Gönderim formatı seçiniz',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    if (!state.approval) {
      props.showLoading()
      const getCargoListApi: GetCargoListApi = new GetCargoListApi({})
      let datas = {}
      if (state.addresses?.ShippAddresList && state.addresses?.ShippAddresList.length > 0) {
        datas = {
          // Basket: basket.ActiveBasket,
          ShippingAddresId: Number(state.selectedAddress?.value),
          ShippingType: state.ShippingType,
        }
      } else {
        datas = {
          // Basket: basket.ActiveBasket,
          ShippingAddresId: Number(state.selectedBillAddress?.value),
          ShippingType: state.ShippingType,
        }
      }
      const res = await getCargoListApi.getCargoList(datas).catch(() => props.hideLoading())

      if (res) {
        dispatchState({ cargoList: res, cargoItem: res.CargoItem, approval: true })
        dispatchRedux(setBasketRedux(res.Basket))
        props.hideLoading()
      }
      props.hideLoading()
    } else dispatchState({ approval: false })
    props.hideLoading()
  }

  const [quantity, setQuantity] = useState(1)
  const handleQuantity = (e: any) => {
    setQuantity(e.target.value)
  }

  const handleQuantityOnBlur = (e: any) => {
    if (e.target.value?.length < 1 || isNaN(Number(e.target.value))) {
      setQuantity(1)
    } else {
      setQuantity(e.target.value)
    }
  }

  // this method send the bin number for installment and info of bankname
  const sendBinNumber = async request => {
    try {
      const sendBinService = new SendBinNumberApi({})
      const res = await sendBinService.sendBinNumber(request)
      if (res) {
        dispatchState({ binResponse: res })
        state.binResponse = res
        dispatchState({ installmentInfo: res.InstallmentPrices })
        dispatchState({ bankCode: res.BankCode, cardType: res.CardType, showInstallments: true })
        state.bankCode = res.BankCode
      }
      bankImageUrl()
    } catch (e: any) {
      dispatchState({
        bankCode: 0,
        bankLogo: '',
        installmentInfo: undefined,
        showInstallments: false,
        binResponse: undefined,
        cardType: undefined,
      })
      Swal.fire({
        icon: 'warning',
        title: e.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
  }

  const findBankInfos = async (req: binRequest) => {
    try {
      const findBankInfosService = new FindBankInfosApi({})
      const res = await findBankInfosService.findBankInfos(req)
      if (res) {
        dispatchState({ bankCode: res.BankCode, cardType: res.CardType })
        state.bankCode = res.BankCode
      }
      bankImageUrl()
    } catch (e: any) {
      dispatchState({ bankCode: 0, bankLogo: '' })
      Swal.fire({
        icon: 'warning',
        title: e.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
  }

  const bankImageUrl = () => {
    const bankCodeString = state.bankCode.toString()
    switch (bankCodeString) {
      case bankNames.akbank:
        dispatchState({ bankLogo: '/images/akbank.png' })
        break
      case bankNames.halkbank:
        dispatchState({ bankLogo: '/images/halkbank.png' })
        break
      case bankNames.denizbank:
        dispatchState({ bankLogo: '/images/denizBank.png' })
        break
      case bankNames.Akbank:
        dispatchState({ bankLogo: '/images/akbank.png' })
        break
      case bankNames.AktifYatirimBankasi:
        dispatchState({ bankLogo: '/images/product-detail/aktifBank.svg' })
        break
      case bankNames.AlbarakaTurk:
        dispatchState({ bankLogo: '/images/product-detail/alBaraka.svg' })
        break
      case bankNames.AlternatifBank:
        dispatchState({
          bankLogo: '/images/product-detail/alternatifBank.svg',
        })
        break
      case bankNames.Anadolubank:
        dispatchState({ bankLogo: '/images/product-detail/anadoluBank.svg' })
        break
      case bankNames.Denizbank:
        dispatchState({ bankLogo: '/images/denizBank.png' })
        break
      case bankNames.Fibabanka:
        dispatchState({ bankLogo: '/images/product-detail/fibabanka.svg' })
        break
      case bankNames.GarantiBBVA:
        dispatchState({ bankLogo: '/images/garantiBankasi.png' })
        break
      case bankNames.HSBC:
        dispatchState({ bankLogo: '/images/product-detail/hsbcBank.svg' })
        break
      case bankNames.Halkbank:
        dispatchState({ bankLogo: '/images/halkbank.png' })
        break
      case bankNames.INGBank:
        dispatchState({ bankLogo: '/images/product-detail/ingBank.svg' })
        break
      case bankNames.IsBankasi:
        dispatchState({ bankLogo: '/images/isBank.png' })
        break
      case bankNames.KuveytTurk:
        dispatchState({ bankLogo: '/images/product-detail/kuveytTurk.svg' })
        break
      case bankNames.Odeabank:
        dispatchState({ bankLogo: '/images/product-detail/odeabank.svg' })
        break
      case bankNames.QNBFinansbank:
        dispatchState({ bankLogo: '/images/finansbank.png' })
        break
      case bankNames.Sekerbank:
        dispatchState({ bankLogo: '/images/product-detail/sekerBank.svg' })
        break
      case bankNames.TurkEkonomiBankasi:
        dispatchState({ bankLogo: '/images/product-detail/teb.svg' })
        break
      case bankNames.TurkiyeFinans:
        dispatchState({
          bankLogo: '/images/product-detail/turkiyeFinansBank.svg',
        })
        break
      case bankNames.Vakifbank:
        dispatchState({ bankLogo: '/images/product-detail/vakifBank.svg' })
        break
      case bankNames.YapiKrediBankasi:
        dispatchState({ bankLogo: '/images/yapiKredi.png' })
        break
      case bankNames.ZiraatBankasi:
        dispatchState({ bankLogo: '/images/ZiraatBankasi.png' })
        break
    }
  }

  const onProductSelectionChange = (checked: boolean, productId: number) => {
    if (checked) {
      if (state.selectedProductIds.findIndex((id: number) => id === productId) === -1) {
        dispatchState({
          selectedProductIds: [...state.selectedProductIds, productId],
        })
      }
    } else {
      dispatchState({
        selectedProductIds: state.selectedProductIds.filter((id: number) => id !== productId),
      })
    }
  }

  const orderNow = async () => {
    const control = checkSingleCampaigns('#payment-method-tab')
    if (control) {
      if (props.user.IsRemote) return getIsContinueToPurchasePhase()
      saveOrder()
    }
  }

  const getIsContinueToPurchasePhase = () => {
    const productCodes: string[] = []
    basket.ActiveBasket.Companies?.map((company: CompaniesGroup) => {
      return company.Warehouses.map(warehouse => {
        return warehouse.BasketDetail.map(det => {
          if (det.Product && det.Product.ProductCode) return productCodes.push(det.Product.ProductCode)
          return
        })
      })
    })
    const getIsContinueToPurchasePhaseApi: GetIsContinueToPurchasePhaseApi = new GetIsContinueToPurchasePhaseApi({})
    getIsContinueToPurchasePhaseApi
      .getIsContinueToPurchasePhase(productCodes)
      .then((result: any) => {
        if (result) {
          saveOrder()
          return true
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
      })
  }

  const shipmentData = async (date: Date) => {
    //debugger
    setShipmentDate(date)
    let datas: IShippingDate = {
      AddressId: 0,
      ShippingDate: '',
    }
    dispatchState({ shipmentDate: true })
    if (state.addresses?.ShippAddresList && state.addresses?.ShippAddresList?.length > 0) {
      if (!state.selectedAddress || !state.selectedAddress.value) {
        Swal.fire({
          // position: 'top-end',
          icon: 'warning',
          title: 'Lütfen Sevk Adresi Seçiniz!',
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        return
      }
      datas = {
        AddressId: Number(state.selectedAddress.value),
        ShippingDate: formatDate(date),
      }
    } else {
      datas = {
        AddressId: Number(state.selectedBillAddress?.value),
        ShippingDate: formatDate(date),
      }
    }
    shippingDate(datas)
  }

  const shipmentAdres = async (option: ISelectOption, type: string) => {
    //debugger
    if (shipmentDate && (type === 'Sevk' || type === 'Fatura ve Sevk')) {
      const data = {
        AddressId: option.value,
        ShippingDate: formatDate(shipmentDate),
      }
      dispatchState({
        ShippingTypes: [],
        ShippingName: '',
      })
      shippingDate(data)
    }
    if (state.selectedAddress) dispatchState({ shipmentDate: false, approval: false })
    dispatchState({ selectedAddress: option })
  }

  useEffect(() => {
    //debugger
    if (state.addresses && state.addresses.ShippAddresList.length === 0 && shipmentDate && !state.shipping) {
      shipmentData(shipmentDate)
    }
  }, [state.selectedBillAddress])

  const shippingDate = async data => {
    props.showLoading()
    const getShippingDate: GetShippingDateApi = new GetShippingDateApi({})
    await getShippingDate
      .getShippingDate(data)
      .then(async result => {
        props.hideLoading()
        if (result) {
          if (result.ShippingTypes.length === 0) {
            dispatchState({
              ShippingType: undefined,
              ShippingName: undefined,
              type: false,
            })
            Swal.fire({
              icon: 'error',
              title: 'Bu tarihte seçmiş olduğunuz adrese gönderim yoktur. Lütfen yeni tarih seçiniz!!!',
              showConfirmButton: true,
              allowOutsideClick: false,
            })
            return
          }
          const data: ISelectOption[] = []
          result.ShippingTypes.map(res => {
            return data.push({
              value: res.ShippingType,
              label: res.ShippingName,
            })
          })
          setShipmentDate(new Date(result.ShippingDate))
          dispatchState({
            ShippingDate: result.ShippingDate,
            ShippingTypes: data,
            ShippingType: data ? String(data[0].value) : undefined,
            ShippingName: data ? data[0].label : undefined,
            shipping: true,
            type: data ? (data[0].label ? true : false) : false,
          })
        }
      })
      .catch((err: any) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const finishOrder = res => {
    if (typeof res === 'string') {
      Swal.fire({
        // position: 'top-end',
        icon: 'error',
        title: res,
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }
    if (res.IsStockError && res.IsStockError.length > 0) {
      let msg = `<div>Sipariş Oluşturulmadı!<br />Aşağıdaki ürünler için stoğumuz tükenmiştir.<br/>`

      for (const orderNumber of res.IsStockError) {
        if (!orderNumber.StockAvaible) {
          msg += `<br /><span>${orderNumber.OutOfStock}</span>`
        }
      }
      msg += `<br/>Siparişinize devam edebilmek için sepetinizdeki tükenen ürünleri kaldırmanız gerekmektedir.</div>`

      Swal.fire({
        // position: 'top-end',
        icon: 'warning',
        title: msg,
        showConfirmButton: true,
        allowOutsideClick: false,
        // timer: 1500
      })
      return
    }
    if (res.ErrorMessages && res.ErrorMessages.length > 0) {
      if (!res.OrderReturnCompanyList || res.OrderReturnCompanyList.length === 0) {
        let msg = `<div>Sipariş Oluşturulmadı!`

        for (const orderNumber of res.ErrorMessages) {
          msg += `<br /><span>${orderNumber}</span>`
        }

        msg += `Lütfen Müşteri Temsilciniz ile iletişime geçiniz!</div>`

        Swal.fire({
          // position: 'top-end',
          icon: 'warning',
          title: msg,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        return
      }
      dispatchState({
        showOrderDetails: true,
        orderDetails: res,
        errorMessage: res.ErrorMessages,
      })
      return
    }
    dispatchState({ showOrderDetails: true, orderDetails: res })
  }

  const saveOrder = async () => {
    const prod: IProduct[] = []
    for (const com of basket.ActiveBasket.Companies) {
      for (const ware of com.Warehouses) {
        if (ware.WarehouseName === '1920') {
          for (const deta of ware.BasketDetail) {
            prod.push(deta.Product)
          }
        }
      }
    }
    if (prod.length > state.softwareProducts.length) {
      Swal.fire({
        iconHtml: '<div class="custom-icon"></div>',
        title: 'Lütfen Yazılım ürünlerinin lisans bilgilerini giriniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(() => {
        document.getElementById('products-tab')?.click()
      })
      return
    }
    const saveOrderApi: SaveOrderApi = new SaveOrderApi({})
    if (state.addresses?.ShippAddresList && state.addresses?.ShippAddresList.length > 0) {
      if (!state.selectedAddress || !state.selectedAddress.value) {
        Swal.fire({
          icon: 'warning',
          title: 'Lütfen Adres Seçiniz!',
          showConfirmButton: true,
          allowOutsideClick: false,
        }).then(() => {
          document.getElementById('address-tab')?.click()
        })
        return
      }
    }
    if (state.paymentMethod === '') {
      Swal.fire({
        icon: 'warning',
        title: 'Ödeme şekli seçmelisiniz!',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      document.getElementById('payment-method-tab')?.click()
      return
    }
    if (state.paymentMethod === 'iyzico') {
      if (!state.ccNumber) {
        Swal.fire({
          icon: 'warning',
          title: 'Lütfen Kredi Kartı Numarası Giriniz!',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        document.getElementById('payment-method-tab')?.click()
        return
      }

      if (state.ccNumber.length < 16) {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: 'Geçerli kart numarasını giriniz',
          showConfirmButton: true,
          allowOutsideClick: false,
          //timer: 1500
        })
        document.getElementById('payment-method-tab')?.click()
        return
      }

      if (!state.ccName) {
        Swal.fire({
          // position: 'top-end',
          icon: 'warning',
          title: 'Lütfen Kredi Kartı Sahibini Giriniz!',
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        document.getElementById('payment-method-tab')?.click()
        return
      }

      if (!state.ccName.includes(' ')) {
        Swal.fire({
          // position: 'top-end',
          icon: 'warning',
          title: 'Lütfen Kredi Kartı Sahibi İçin Soyad Bilgisini Giriniz!',
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        document.getElementById('payment-method-tab')?.click()
        return
      }

      if (!state.ccExpiry) {
        Swal.fire({
          // position: 'top-end',
          icon: 'warning',
          title: 'Lütfen Kredi Kartı Geçerlilik Tarihini Giriniz!',
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        document.getElementById('payment-method-tab')?.click()
        return
      }

      if (!state.ccCVC) {
        Swal.fire({
          // position: 'top-end',
          icon: 'warning',
          title: 'Lütfen Kredi Kartı CVC No Giriniz!',
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        document.getElementById('payment-method-tab')?.click()
        return
      }
    }

    if (!state.approval) {
      Swal.fire({
        icon: 'warning',
        title: 'Lütfen adresi onaylayınız',
        showConfirmButton: true,
        allowOutsideClick: false,
      }).then(() => {
        document.getElementById('address-tab')?.click()
      })
      return
    }
    const savedCondition: any[] = []
    if (state.paymentMethod === 'savedcondition') {
      if (state.savedConditionListCount > savedConditionList.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Kayıtlı çalışma koşullarını tüm şirketler için yapmalısınız',
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        document.getElementById('payment-method-tab')?.click()
        return
      }
      savedConditionList.map(saved => {
        return savedCondition.push({
          Company: saved.Company,
          Condition: saved.Condition,
          Currency: saved.Currency,
          Value: saved.Value,
        })
      })
    }

    const req: PaymentRequestModel = {
      ShippingAddressId: Number(
        state.addresses?.ShippAddresList
          ? state.addresses.ShippAddresList.length > 0
            ? state.selectedAddress
              ? state.selectedAddress.value
              : state.selectedBillAddress?.value
            : state.selectedBillAddress?.value
          : state.selectedBillAddress?.value
      ),
      BillingAddressId: Number(state.selectedBillAddress?.value),
      Note: '',
      CardNumber: `${state.ccNumber}`.replaceAll(' ', ''),
      HolderName: state.ccName,
      ExpireDate: `${state.ccExpiry}`,
      Cvc: `${state.ccCVC}`,
      Installment: state.ccInstallmentNumber,
      PaymentWay: state.paymentMethod,
      isSapAddress: false,
      ShippingDate: state.ShippingDate,
      ShippingType: state.ShippingType,
      ShippingName: state.ShippingName,
      Id: 0,
      MakePayment: state.paymentMethod === 'savedcondition' ? false : true,
      Amount:
        basket && basket.ActiveBasket && basket.ActiveBasket.BasketPrice && basket.ActiveBasket.BasketPrice.TL
          ? basket.ActiveBasket.BasketPrice.TL.Total
          : 0,
      SavedConditionList: savedCondition,
      Basket: basket && basket.ActiveBasket && basket.ActiveBasket,
      CargoPayment: state.cargoList?.CargoItem,
      InstallmentPrices: state.installmentInfo,
      Notes: state.orderNotes,
      SoftwareWarehauseOrderModels: state.softwareProducts,
      MTID: props.user.IsRemote ? props.user.UserId : '0',
    }

    localStorage.setItem('SecretKey', req.Amount.toString())

    dispatchState({ loading: true })

    if (state.securePayment && state.paymentMethod !== 'savedcondition') {
      saveOrderApi
        .saveOrder3D(req)
        .then(res => {
          dispatchState({ loading: false })
          if (res.HtmlContent) {
            const tmpHtml = document.createElement('html')
            tmpHtml.innerHTML = res.HtmlContent
            let form = tmpHtml.querySelector('form')!.outerHTML
            if (state.bankCode === 62 && state.cardType === 'CREDIT_CARD') {
              form = tmpHtml.querySelector('form:not([id="form1"])')!.outerHTML
            }

            if (state.bankCode === 134 || state.bankCode === 206) {
              form = tmpHtml.querySelector('form:not([id="form1"])')!.outerHTML
            }

            if (paymentRef.current) {
              paymentRef.current.innerHTML = form
              paymentRef.current.querySelector('form')!.submit()
            }

            return
          }
          if (res.IsStockError && res.IsStockError.length > 0) {
            let msg = `<div>Sipariş Oluşturulmadı!<br />Aşağıdaki ürünler için stoğumuz tükenmiştir.<br/>`

            for (const orderNumber of res.IsStockError) {
              if (!orderNumber.StockAvaible) {
                msg += `<br /><span>${orderNumber.OutOfStock}</span>`
              }
            }
            msg += `<br/>Siparişinize devam edebilmek için sepetinizdeki tükenen ürünleri kaldırmanız gerekmektedir.</div>`

            Swal.fire({
              // position: 'top-end',
              icon: 'warning',
              title: msg,
              showConfirmButton: true,
              allowOutsideClick: false,
              // timer: 1500
            })
            return
          }

          Swal.fire('Hata', res.ErrorMessage ? res.ErrorMessage : 'Beklenmedik bir hata gerçekleşti!', 'error')
        })
        .catch((err: any) => {
          Swal.fire({
            icon: 'error',
            title: err.description,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          dispatchState({ loading: false })
        })

      return
    }

    saveOrderApi
      .saveOrder(req)
      .then((res: any) => {
        finishOrder(res)
        dispatchState({ loading: false })
      })
      .catch((err: IException) => {
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
        dispatchState({ loading: false })
      })
      .finally(() => {
        dispatchState({ loading: false })
      })
  }

  const [searchParams] = useSearchParams()

  const secureOrderReturn = async () => {
    const api: SaveOrderApi = new SaveOrderApi({})

    const req: OrderThreedsRequestModel = {
      PaymentResponse: window.location.search.split('paymentresponse=')[1],
      SecretKey: localStorage.getItem('SecretKey') as string,
    }

    api
      .orderThreedsReturn(req)
      .then((res: ICreateOfferToSubDealerResponse) => {
        dispatchState({ loading: false })
        if (res) {
          localStorage.removeItem('SecretKey')

          finishOrder(res)
          props.hideLoading && props.hideLoading()
        }
      })
      .catch((err: IException) => {
        dispatchState({ loading: false })
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        props.hideLoading && props.hideLoading()
      })
  }

  const getBanner = () => {
    const getBannerDetails: GetBannerDetailsApi = new GetBannerDetailsApi({})
    getBannerDetails.getBannerDetails([16, 17]).then((res: IBanner[]) => {
      const data: IBanner[] = []
      if (res) {
        res.map(re => {
          if (re.Location === 16) {
            data.push(re)
          }
          if (re.Location === 17) {
            dispatchState({ bannerPayment: re })
          }
        })
      }
      dispatchState({ bannerProduct: data })
    })
  }

  useEffect(() => {
    if (searchParams.get('status') === 'true') {
      dispatchState({ loading: true })
      secureOrderReturn()
    }

    if (searchParams.get('status') === 'false') {
      Swal.fire({
        icon: 'error',
        title: '3D doğrulama aşamasında genel bir hata oluştu',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
    getBanner()
  }, [])

  // const requestOffer = () => {}

  const createOffer = () => {
    props.showLoading()
    const createOfferApi: CreateOfferToSubDealerApi = new CreateOfferToSubDealerApi({})
    createOfferApi
      .createOffer({
        Id: 0,
        CurrencyType: state.createOfferCurrencyType ?? CurrencyType.TL,
        PriceType: state.createOfferPriceType ?? PriceType.Special,
        PercentageRate: Number(state.createOfferPercentage),
        BasketList: state.selectedProductIds && state.selectedProductIds.length > 0 ? state.selectedProductIds : [],
      })
      .then((res: ICreateOfferToSubDealerResponse) => {
        props.hideLoading()
        if (res) {
          dispatchState({ calculatedOffer: res, createOfferBool: true })
        } else {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Teklif Oluşturulamadı!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        props.hideLoading()
      })
  }

  const orderSendMail = () => {
    var mailList = state.mailEmailText.split(',')

    if (
      !state.mailEmailText ||
      state.mailEmailText.length === 0 ||
      !state.mailName ||
      state.mailName.length === 0 ||
      !state.mailSubject ||
      state.mailSubject.length === 0 ||
      !state.mailNote ||
      state.mailNote.length === 0 ||
      !mailList ||
      mailList.length === 0
    ) {
      Swal.fire({
        icon: 'error',
        title: 'Lütfen tüm alanları doldurunuz.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
      return
    }
    props.showLoading()
    const orderMailApi: OrderMailApi = new OrderMailApi({})
    orderMailApi
      .orderSendMail({
        Name: state.mailName,
        Emails: mailList,
        Subject: state.mailSubject,
        Note: state.mailNote,
      })
      .then(res => {
        props.hideLoading()
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `${mailList} adreslerine mail gönderildi`,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          dispatchState({ showMail: false })
          props.hideLoading()
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Mail Gönderiminde Hata!',
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          props.hideLoading()
        }
      })
      .catch((err: IException) => {
        props.hideLoading()
        Swal.fire({
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      })
  }

  const offerSendMail = () => {
    props.showLoading()
    const offerSendMailApi: OfferSendMailApi = new OfferSendMailApi({})
    offerSendMailApi
      .offerSendMail({
        Mail: state.sendMail,
        Data: {
          Id: 0,
          CurrencyType: state.createOfferCurrencyType ?? CurrencyType.TL,
          PriceType: state.createOfferPriceType ?? PriceType.Special,
          PercentageRate: Number(state.createOfferPercentage),
          BasketList: state.selectedProductIds && state.selectedProductIds.length > 0 ? state.selectedProductIds : [],
        },
      })
      .then((res: ICreateOfferToSubDealerResponse) => {
        props.hideLoading()
        if (res) {
          Swal.fire({
            icon: 'success',
            title: `${state.sendMail} adresine mail gönderildi`,
            showConfirmButton: true,
            allowOutsideClick: false,
          })
          dispatchState({ showSendMailModal: false })
        } else {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Teklif Oluşturulamadı!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
          props.hideLoading()
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
      })
  }
  const saveOffer = () => {
    if (!state.createOfferBool) return Swal.fire('Hata', 'Lütfen önce teklif oluşturunuz', 'error')
    const createOfferApi: SaveOfferToSubDealerApi = new SaveOfferToSubDealerApi({})
    createOfferApi
      .saveOffer({
        Id: 0,
        CurrencyType: state.createOfferCurrencyType ?? CurrencyType.TL,
        PriceType: state.createOfferPriceType ?? PriceType.Special,
        PercentageRate: Number(state.createOfferPercentage),
        BasketList: state.selectedProductIds && state.selectedProductIds.length > 0 ? state.selectedProductIds : [],
      })
      .then((res: ICreateOfferToSubDealerResponse) => {
        if (res) {
          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Teklifiniz kaydedilmiştir!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })

          dispatchState({
            showCreateOfferModal: false,
            createOfferCurrencyType: CurrencyType.TL,
            createOfferPriceType: PriceType.Normal,
            createOfferPercentage: 0,
            calculatedOffer: undefined,
          })
        } else {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Teklif Oluşturulamadı!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
        props.hideLoading()
      })
  }

  const excelToOffer = () => {
    if (!state.createOfferBool) return Swal.fire('Hata', 'Lütfen önce teklif oluşturunuz', 'error')
    const excelToOfferApi: ExcelToOfferApi = new ExcelToOfferApi({})
    excelToOfferApi
      .excelToOffer({
        Id: 0,
        CurrencyType: state.createOfferCurrencyType ?? CurrencyType.TL,
        PriceType: state.createOfferPriceType ?? PriceType.Special,
        PercentageRate: Number(state.createOfferPercentage),
        BasketList: state.selectedProductIds && state.selectedProductIds.length > 0 ? state.selectedProductIds : [],
      })
      .then((res: any) => {
        if (res) {
          downloadFileFromUrl('Tekliflerim', res.FileUrl)
        } else {
          Swal.fire({
            // position: 'top-end',
            icon: 'error',
            title: 'Teklif Oluşturulamadı!',
            showConfirmButton: true,
            allowOutsideClick: false,
            // timer: 1500
          })
        }
      })
      .catch((err: IException) => {
        Swal.fire({
          // position: 'top-end',
          icon: 'error',
          title: err.description,
          showConfirmButton: true,
          allowOutsideClick: false,
          // timer: 1500
        })
      })
  }

  const onRemoveProductFromBasket = (basketItem: IBasketDetail) => {
    const isProductOnFavorite =
      favoriteProducts && favoriteProducts.findIndex((element: IProduct) => element.Id === basketItem.ProductID) > -1
        ? true
        : false
    Swal.fire({
      title: `<div style="display:flex;align-items:center;justify-content:center;margin-bottom:-90px;padding:20px;">
      <div className='col-md-6' style="background-color:#EC4C4C;width:60px;height:60px;border-radius:50%;display:flex;align-items:center;margin-right:10px">
      <img src='/images/shopping-cart.svg' style="width:30px;margin:auto"/>
      </div></div>
      ${
        isProductOnFavorite
          ? ``
          : `<p style="color:#EC4C4C;font-weight:400;margin-top:65px;margin-bottom:-50px;font-size:1.5rem">SİL VE FAVORİLERE EKLE</p>`
      }
      <span style="color:#6B6B6B;font-weight:500;font-size:1.2rem;margin-top:-35px;line-height:1.2rem;letter-spacing:0;">
      ${basketItem?.Product?.Name} adlı ürünü ${
        isProductOnFavorite
          ? 'sepetinizden çıkarmak istiyor musunuz?'
          : 'sepetinizden çıkardıktan sonra favoriye eklemek ister misiniz?'
      }
      </span>`, // EE: Bu satırın değerini örnek olarak bu şekilde yaptım. Title değişkeninin içine string olarak html yazılabiliyor.
      showDenyButton: true,
      showCancelButton: isProductOnFavorite ? false : true,
      cancelButtonColor: '#FAC74B',
      cancelButtonText: 'Sil ve Favorilerime Ekle',
      confirmButtonText: 'Sil',
      denyButtonText: `Vazgeç`,
      width: '600',
    }).then(result => {
      if (result.isConfirmed) {
        props.removeItemFromBasket(basketItem.Id)
      } else if (result.isDismissed && result.dismiss === Swal.DismissReason.cancel) {
        props.removeItemFromBasket(basketItem.Id)

        if (
          favoriteProducts &&
          favoriteProducts.findIndex((element: IProduct) => element.Id === basketItem.ProductID) === -1
        ) {
          dispatchRedux(addToFavoriteProductListRedux(basketItem.ProductID))
        }
      }
    })
  }

  const getNeighborhood = async (id: number) => {
    state.districtsData &&
      state.districtsData.map(data => {
        if (data.Id === id) {
          const newParams = state.addAddressParams
          newParams.PostCode = data.PostaKodu
          newParams.SevkCode = data.SevkKodu
          dispatchState({
            addAddressParams: newParams,
            postCode: data.PostaKodu,
            SevkCode: data.SevkKodu,
          })
        }
      })
  }

  const postOrderExcel = () => {
    const postOrderExcelApi: PostOrderExcelApi = new PostOrderExcelApi({})
    postOrderExcelApi.postOrderExcel(state.orderDetails).then((res: OrderExcelResponse) => {
      if (res) {
        downloadFileFromUrl('Sipariş Detay', res.FileUrl)
      }
    })
  }

  // const isGiftAlertShowed = localStorage.getItem('GIFT_ALERT') ? false : true
  // const [giftAlert, setGiftAlert] = useState(isGiftAlertShowed)

  // if (changeGiftButtonRef.current && giftAlert) {
  // 	const rect = changeGiftButtonRef.current.getBoundingClientRect()
  // 	document.body.scrollTo({ top: rect.top / 2 })
  // 	document.body.style.overflow = 'hidden'
  // }

  // const handleGiftAlert = () => {
  // 	localStorage.setItem('GIFT_ALERT', 'true')
  // 	setGiftAlert(false)
  // 	document.body.style.overflow = 'auto'
  // }
  // const giftAlertMarkup = (
  // 	<>
  // 		<div
  // 			className={css`
  // 				position: fixed;
  // 				inset: 0;
  // 				background-color: rgba(0, 0, 0, 0.85);
  // 				width: 100%;
  // 				height: 100vh;
  // 				z-index: 11;
  // 			`}></div>
  // 		<div
  // 			className={css`
  // 				display: grid;
  // 				place-items: center;
  // 				position: fixed;
  // 				inset: 0;
  // 				width: 100%;
  // 				padding: 0.5rem 1rem;
  // 				color: #fff;
  // 				font-weight: 600;
  // 				font-size: 18px;
  // 				z-index: 11;
  // 				text-align: center;
  // 				pointer-events: none;
  // 			`}>
  // 			Bu tuşa basarak hediye kampanya ürününüzü değiştirebilirsiniz.
  // 		</div>
  // 	</>
  // )

  const tooltipState: TooltipState = {
    show: false,
    coords: {
      top: 0,
      left: 0,
    },
    content: {},
  }

  const [addressTooltip, setAddressTooltip] = useState(tooltipState)

  const handleAddressTooltip = (e: any, address: any) => {
    setAddressTooltip({
      show: true,
      coords: getPosition(e),
      content: address,
    })
  }

  const setOrderNotes = () => {
    dispatchState({
      orderNotes: state.orderNotes,
    })
  }

  const setAddressModal = async (ProductId: number, Company: number) => {
    let data: AddAddressRequest = {
      Id: 0,
      NameAndSurname: '',
      Country: CountryOptions[0].label,
      City: '',
      District: '',
      PostCode: '',
      SevkCode: '',
      Phone: '',
      Email: '',
      AddressDetail: '',
      AddressDetail2: '',
      AddressTitle: '',
      Unvan: '',
      Neighbourhood: '',
      isLicense: 1,
    }
    if (state.softwareProducts.length > 0) {
      const dt = state.softwareProducts
        .filter(so => {
          return so.Company === Company && so.ProductId === ProductId
        })
        .pop()
      if (dt) {
        data = {
          Id: 0,
          NameAndSurname: dt.NameAndSurname,
          Country: dt.Country,
          City: dt.City,
          District: dt.District,
          PostCode: dt.PostCode,
          SevkCode: dt.SevkCode,
          Phone: dt.Phone,
          Email: dt.Email,
          AddressDetail: dt.AddressDetail,
          AddressDetail2: dt.AddressDetail2,
          AddressTitle: dt.AddressTitle,
          Unvan: dt.Unvan,
          Neighbourhood: dt.Neighbourhood,
          isLicense: dt.isLicense,
        }
        const city = state.cities && state.cities.find(ct => ct.label === dt.City)
        const getTownApi: GetTownApi = new GetTownApi({})

        const res = await getTownApi.getTown(Number(city?.value))
        const dat: ISelectOption[] = []
        if (res) {
          res.map(town => {
            dat.push({ value: town.IlceKodu, label: town.IlceAdi })
          })
          const town: any = dat && dat.find(ct => ct.label === dt.District)
          const getDistrictApi: GetDistrictApi = new GetDistrictApi({})

          const result = await getDistrictApi.getDistrict(Number(town.value))

          if (result) {
            const dn: ISelectOption[] = []
            result.map(district => {
              dn.push({ value: district.Id, label: district.MahalleAdi })
            })
            const district: any = dn && dn.find(ct => ct.label === dt.Neighbourhood)
            dispatchState({
              postCode: dt.PostCode,
              SevkCode: dt.SevkCode,
              country: { value: CountryOptions[0].value, label: CountryOptions[0].label },
              city,
              towns: dat,
              town,
              districtsData: result,
              districts: dn,
              district,
            })
          }
        }
      }
    }

    dispatchState({
      showAddressModal: true,
      adresModalName: 'Lisans',
      lisans: { ProductId, Company, Preferred: '0' },
      addAddressParams: data,
    })
  }

  function setGiftProducts() {
    const notSelected = state.singleCampaigns.find(x => x.isSelected === false)
    dispatchState({
      selectedSingleCampaignId: notSelected ? notSelected.CampaignInfoModel.CampaignBundleID : 0,
      SingleCampaignProductCheckbox: notSelected.ProductID ? notSelected.ProductID : 0,
      SingleCampaignProductPrice: notSelected.CampaignBasketDetailPrice.Normal,
    })
    SingleCampaignProduct(notSelected, true)
  }

  async function IsNot3D() {
    try {
      const res = await new IsNot3DPayment({}).post({ Companies: state.threedPaymentCompanyCode })
      dispatchState({ threedPaymentDisabled: !res, securePayment: !res })
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  useEffect(() => {
    IsNot3D()
  }, [state.threedPaymentCompanyCode])

  const deleteSelecteds = () => {
    Swal.fire({
      icon: 'question',
      title: 'Seçtiğiniz ürünler silinecektir. Devam etmek istediğinize emin misiniz?',
      showConfirmButton: true,
      allowOutsideClick: false,
      showDenyButton: true,
      confirmButtonText: 'Evet',
      denyButtonText: 'Hayır',
    }).then(async result => {
      if (result.isConfirmed) {
        props.showLoading()
        const request: ISetBasketManagementByIdsRequest = Object.assign({})
        request.BeasketDetailIDList = state.selectedProductIds
        request.CurrentBasketID = Number(state.activeBasket[0].value)
        request.OperationType = 3
        props.copyMoveOrDeleteFromBasket &&
          props
            .copyMoveOrDeleteFromBasket(request)
            .then(res => {
              props.hideLoading()
            })
            .catch((err: IException) => {
              Swal.fire({
                icon: 'error',
                title: err.description,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
            })
      }
    })
  }

  const getSelectedTown = async (id: number, district?: string, neighbourhood?: string) => {
    const getTownApi: GetTownApi = new GetTownApi({})
    const res = await getTownApi.getTown(id)

    if (res) {
      const data: ISelectOption[] = []
      let dis: number = 0
      res.map(town => {
        data.push({ value: town.IlceKodu, label: town.IlceAdi })
        if (town.IlceAdi === district) {
          dis = Number(town.IlceKodu)
        }
      })
      dispatchState({ towns: data })
      if (dis > 0) {
        getSelectedDistrict(dis, neighbourhood)
      }
    }
  }
  const getSelectedDistrict = async (id: number, neighbourhood?: string) => {
    const getDistrictApi: GetDistrictApi = new GetDistrictApi({})
    const res = await getDistrictApi.getDistrict(id)
    if (res) {
      const data: ISelectOption[] = []
      let neighbourhoodData: ISelectOption | null = null
      res.map(district => {
        data.push({ value: district.Id, label: district.MahalleAdi })

        if (district.MahalleAdi === neighbourhood) {
          neighbourhoodData = { value: district.Id, label: district.MahalleAdi }
        }
      })
      dispatchState({ districts: data, districtsData: res, district: neighbourhoodData })
    }
  }

  const handleAddressSelection = async (selectedAddress: ISelectOption) => {
    // Extract the address data from the selected option
    const selectedAddressData = state.addresses?.LicenseAddressList.find(
      address => `${address.Id}` === selectedAddress.value
    )

    if (selectedAddressData) {
      const selectedCountryOption = CountryOptions.find(option => option.value === selectedAddressData.Country)

      const selectedCityOption = state.cities?.find(option => option.label === selectedAddressData.City)
      await getSelectedTown(
        Number(selectedCityOption?.value),
        selectedAddressData.District,
        selectedAddressData.Neighbourhood
      )

      if (selectedCountryOption) {
        dispatchState({
          country: selectedCountryOption,
        })
      }

      if (selectedCityOption) {
        dispatchState({
          city: selectedCityOption,
        })
      }

      // const selectedIlceOption = state.towns?.find(
      //   (option) => option.label === selectedAddressData.District
      // )

      const selectedIlceOption: ISelectOption = {
        value: '',
        label: selectedAddressData.District, // Etiketi (label) `selectedAddressData.District` değeri olarak ayarlayın
      }

      const selectedNeighbourhoodOption = state.districts?.find(
        option => option.label === selectedAddressData.Neighbourhood
      )

      if (selectedIlceOption) {
        dispatchState({
          town: selectedIlceOption,
        })
      }

      if (selectedNeighbourhoodOption) {
        dispatchState({
          district: selectedNeighbourhoodOption,
        })
      }

      getNeighborhood(Number(selectedNeighbourhoodOption?.value))

      const selectedSevkCode = selectedAddressData.SevkCode
      if (selectedSevkCode) {
        dispatchState({
          SevkCode: selectedSevkCode,
        })
      }

      const selectedPostCode = selectedAddressData.PostCode
      if (selectedPostCode) {
        dispatchState({
          postCode: selectedPostCode,
        })
      }

      dispatchState({
        selectedLicenseAddress: selectedAddress,
        addAddressParams: {
          ...state.addAddressParams,
          NameAndSurname: selectedAddressData.NameAndSurname || '',
          Unvan: selectedAddressData.Unvan || '',
          Email: selectedAddressData.Email || '',
          Phone: selectedAddressData.Phone || '',
          Country: selectedAddressData.Country || '',
          City: selectedAddressData.City || '',
          District: selectedAddressData.District || '',
          Neighbourhood: selectedAddressData.Neighbourhood || '',
          AddressTitle: selectedAddressData.AddressTitle || '',
          AddressDetail: selectedAddressData.AddressDetail || '',
          SevkCode: selectedAddressData.SevkCode,
          PostCode: selectedAddressData.PostCode,
        },
        // Set other form fields accordingly
      })
    }
  }

  const saveAddress = async () => {
    const savedAddress = state.addAddressParams
    savedAddress.isLicense = 1
    props.showLoading()
    const addAddressApi: AddAddressApi = new AddAddressApi({})
    await addAddressApi
      .addAddress(savedAddress)
      .then(async res => {
        props.hideLoading()
        state.selectedLicenseAddress = undefined
        if (res) {
          getAddresses().then(() => {
            props.hideLoading()
            Swal.fire({
              // position: 'top-end',
              icon: 'success',
              title: 'Yeni adres eklendi',
              showConfirmButton: true,
              allowOutsideClick: false,
              // timer: 1500
            })
          })
        }
        dispatchState({ showAddressModal: false })
      })
      .catch((err: any) => {
        props.hideLoading()
      })
  }

  const [activeTab, setActiveTab] = useState('products')

  const handleTabClick = tab => {
    setActiveTab(tab)
  }

  const handlePaymentMethodClick = () => {
    if (state.paymentMethod) {
      document.getElementById('order-note-tab')?.click()
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Lütfen bir ödeme yöntemi seçin.',
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  return (
    <React.Fragment>
      <section style={{ overflow: 'unset' }}>
        <div className='container container-fluid'>
          <div className='breadcrumb  px-0'></div>
          {/* Sepet seçim row'u */}
          <div className='row align-items-center d-flex justify-content-center'>
            <div className='col-md-2'>
              <div className='d-flex flex-wrap align-items-center'>
                <h3
                  style={{
                    color: '#616161',
                    fontSize: '1.9rem',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Sepetim{' '}
                  {basket.ActiveBasket?.BasketName === 'Default'
                    ? `(${props.user?.Name})`
                    : `(${basket.ActiveBasket?.BasketName})`}
                </h3>
              </div>
            </div>
            <div className='col-md-6'>
              <a
                className='btn btn-light'
                style={{
                  // border: '1px solid #EC4C4C',
                  // color: '#EC4C4C',
                  // borderRadius: '0',
                  // padding: '15px 20px',

                  color: '#fe0205',
                  padding: '15px 20px',
                  fontWeight: '600',
                  fontSize: '16px',
                  background: 'none',
                  border: 'none',
                  textTransform: 'capitalize',
                }}
                onClick={() => {
                  if (!state.approval) {
                    deleteSelecteds()
                  } else {
                    Swal.fire({
                      icon: 'question',
                      title: 'Adresi onayladığınız için ürünleri silemezsiniz. Adres onayını kaldırmak ister misiniz?',
                      showConfirmButton: true,
                      allowOutsideClick: false,
                      showDenyButton: true,
                      confirmButtonText: 'Evet',
                      denyButtonText: 'Hayır',
                    }).then(async result => {
                      result.isConfirmed && window.location.reload()
                    })
                  }
                }}
              >
                {' '}
                Seçtiklerimi Sil
                <i className='ms-1 icon-trash-2' />
              </a>
            </div>
            <div className='col-md-4'>
              <div className='d-flex justify-content-end align-items-center gap-3'>
                <IPSelectBox
                  className='col-6'
                  name='currencyType'
                  isDisabled={state.approval ? true : false}
                  value={state.selectedBasketToReadyAction}
                  options={state.baskets}
                  onChangeSingle={(newVal: ISelectOption) => {
                    dispatchState({ selectedBasketToReadyAction: [newVal], changeBasket: true })
                  }}
                />
                <a
                  className='btn btn-light'
                  style={{
                    // border: '1px solid #EC4C4C',
                    // color: '#EC4C4C',
                    // borderRadius: '0',
                    // padding: '15px 20px',
                    fontWeight: '600',
                    fontSize: '16px',
                    color: 'white',
                    borderRadius: '0px',
                    padding: '15px 20px',
                    background: '#868686',
                    textTransform: 'capitalize',
                  }}
                  onClick={() => {
                    if (!state.approval) {
                      navigate(`/mycard/management`)
                    } else {
                      Swal.fire({
                        icon: 'question',
                        title:
                          'Adresi onayladığınız için sepet yönetimine gidemezsiniz. Adres onayını kaldırmak ister misiniz?',
                        showConfirmButton: true,
                        allowOutsideClick: false,
                        showDenyButton: true,
                        confirmButtonText: 'Evet',
                        denyButtonText: 'Hayır',
                      }).then(async result => {
                        result.isConfirmed && window.location.reload()
                      })
                    }
                  }}
                >
                  {' '}
                  Sepet Yönetimi
                </a>
              </div>
            </div>
          </div>
          {/*Note: Sepet seçim row'u End */}
          <div id='page-content' className='sidebar-right p-t-5'>
            <div className='row'>
              <div className='tabs'>
                <ul className='nav nav-tabs' id='myTab' role='tablist'>
                  <li className='nav-item'>
                    <a
                      className='nav-link active'
                      aria-selected='true'
                      id='products-tab'
                      data-toggle='tab'
                      href='#products'
                      role='tab'
                      aria-controls='products'
                      onClick={() => handleTabClick('products')}
                    >
                      Ürün
                      <span
                        className='badge badge-pill badge-danger m-l-5'
                        style={{
                          padding: '0.3em 0.6em',
                          lineHeight: 'normal',
                        }}
                      >
                        {basketCount}
                      </span>
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id={'address-tab'}
                      data-toggle='tab'
                      href='#address'
                      role='tab'
                      aria-controls={'address'}
                      aria-selected='false'
                      //onClick={addressMethod}
                      // aria-selected={activeTab === 'address' ? 'true' : 'false'}
                      onClick={e => {
                        addressMethod(e)
                        handleTabClick('address')
                      }}
                    >
                      Adres
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id={'payment-method-tab'}
                      data-toggle='tab'
                      href='#payment-method'
                      role='tab'
                      aria-controls={'payment-method'}
                      aria-selected='false'
                      //onClick={paymentMethod}
                      onClick={e => {
                        paymentMethod(e)
                        handleTabClick('payment-method')
                      }}
                    >
                      Ödeme Şekli
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link'
                      id='order-note-tab'
                      data-toggle='tab'
                      href='#order-note'
                      role='tab'
                      aria-controls='order-note'
                      aria-selected='false'
                      onClick={() => {
                        handleTabClick('order-note')
                      }}
                    >
                      Sipariş Notunuz
                    </a>
                  </li>
                </ul>
              </div>
              <div className='col-lg-9'>
                <div
                  className='tab-content px-3'
                  id='myTabContent'
                  style={{
                    overflowX: 'clip',
                    overflowY: 'scroll',
                    maxHeight: '602px',
                    height: '602px',
                    backgroundColor: '#fff',
                    // maxHeight: giftAlert ? 'auto' : '602px',
                    // height: giftAlert ? 'auto' : '602px',
                  }}
                >
                  <div
                    className='tab-pane fade active show'
                    id='products'
                    role='tabpanel'
                    aria-labelledby='products-tab'
                  >
                    <div>
                      {basket &&
                        basket.ActiveBasket &&
                        basket.ActiveBasket.Companies?.map((company: CompaniesGroup) => {
                          let companyColor = '#222'
                          company.CompanyID === 1 && (companyColor = '#DA291C')
                          company.CompanyID === 2 && (companyColor = '#0055C9')
                          company.CompanyID === 3 && (companyColor = '#298FC2')
                          company.CompanyID === 4 && (companyColor = '#00AFD7')

                          return (
                            <>
                              {/* <div className='row justify-content-start'>
                                <div className='col-auto '>
                                  <div
                                    className='sub-grey d-flex align-items-center justify-content-between'
                                    style={{
                                      background: '#DFDFDF',
                                      height: '40px',
                                      padding: '5px 30px',
                                      borderTopRightRadius: '30px',
                                    }}
                                  >
                                    <img
                                      src={company.CompanyID === 2 ? '/images/netex_logo.png' : company.CompanyLogo}
                                      alt=''
                                    />
                                  </div>
                                </div>
                              </div> */}
                              <React.Fragment key={company.CompanyID}>
                                {company.Warehouses &&
                                  company.Warehouses.map((warehouse: WarehousesGroup) => {
                                    let warehouseText = state.warehouses.find(ware => {
                                      return ware.WarehouseId === warehouse.WarehouseID
                                    })?.Name
                                    return (
                                      <>
                                        {warehouse.BasketDetail.map((bItem: IBasketDetail) => {
                                          return (
                                            <BasketItem
                                              key={bItem.Id}
                                              bItem={bItem}
                                              addItemToBasket={props.addItemToBasket}
                                              increaseProductQuantity={props.increaseProductQuantity}
                                              reduceProductQuantity={props.reduceProductQuantity}
                                              onProductSelectionChange={onProductSelectionChange}
                                              selectedProductIds={state.selectedProductIds}
                                              SingleCampaignProduct={SingleCampaignProduct}
                                              approval={state.approval}
                                              onRemoveProductFromBasket={onRemoveProductFromBasket}
                                              onQuantityChange={onQuantityChange}
                                              company={company}
                                              warehouse={warehouse}
                                              orderNotes={state.orderNotes}
                                              setOrderNotes={setOrderNotes}
                                              setAddressModal={setAddressModal}
                                              singleCampaigns={state.singleCampaigns}
                                              clearSingleCampaigns={clearSingleCampaigns}
                                              isSoftwareAdresAdd={
                                                state.softwareProducts.length > 0
                                                  ? state.softwareProducts.findIndex(soft => {
                                                      return (
                                                        soft.ProductId === bItem.ProductID &&
                                                        soft.Company ===
                                                          (bItem.CompanyID === 1
                                                            ? 1000
                                                            : bItem.CompanyID === 2
                                                            ? 2300
                                                            : bItem.CompanyID === 3
                                                            ? 1200
                                                            : 1100)
                                                      )
                                                    }) > -1
                                                    ? true
                                                    : false
                                                  : false
                                              }
                                            />
                                          )
                                        })}
                                        <div
                                          className={`d-flex gap-2 align-items-center flex-wrap`}
                                          style={{
                                            width: '100%',
                                            padding: '1rem',
                                            color: '#616161',
                                            fontSize: '1.3rem',
                                            fontWeight: '500',
                                          }}
                                        >
                                          <div className={`d-flex align-items-center gap-3 w-100`}>
                                            <img
                                              src={
                                                company.CompanyID === 2 ? '/images/netex_logo.png' : company.CompanyLogo
                                              }
                                              className='d-block'
                                              style={{ width: '50px' }}
                                              alt=''
                                            />{' '}
                                            {warehouseText}
                                            <WarehouseNote
                                              companyColor={companyColor}
                                              value={
                                                state.orderNotes.find((noteElem: IOrderNote) => {
                                                  return (
                                                    noteElem.CompanyId === company.CompanyID &&
                                                    noteElem.WarehouseId === warehouse.WarehouseID
                                                  )
                                                })?.Note
                                              }
                                              orderNotes={state.orderNotes}
                                              setOrderNotes={setOrderNotes}
                                              companyId={company.CompanyID}
                                              warehouseId={warehouse.WarehouseID}
                                            />
                                          </div>
                                        </div>
                                      </>
                                    )
                                  })}
                              </React.Fragment>
                            </>
                          )
                        })}
                      <div className='d-flex flex-column align-items-end'>
                        {basket &&
                          basket.ActiveBasket &&
                          basket.ActiveBasket.Companies?.map((company: CompaniesGroup) => (
                            <div
                              className={`${styles.companyRow} d-flex align-items-center w-100`}
                              style={{
                                color: '#252525',
                                fontSize: '1.3rem',
                                fontWeight: '500',
                                maxWidth: '400px',
                                background: '#f0f0f0', // #ED222B
                                padding: '1rem',
                              }}
                            >
                              <img
                                src={company.CompanyID === 2 ? '/images/netex_logo.png' : company.CompanyLogo}
                                className='d-block mr-2'
                                style={{ width: '50px' }}
                                alt=''
                              />
                              <span style={{ marginRight: '4px' }}>Şirket Genel Toplamı:</span>
                              <IPPriceDisplay
                                price={company.Price.Display ?? '0,00 $'}
                                integerFontSize='16px'
                                fractionalFontSize='12px'
                                useDiv={false}
                              />
                            </div>
                          ))}
                        <div
                          className='undefined d-flex align-items-center w-100'
                          style={{
                            maxWidth: '400px',
                            padding: '1rem 0',
                          }}
                        >
                          <span
                            style={{
                              color: '#787878',
                              fontSize: '1.5rem',
                              fontWeight: '500',
                            }}
                          >
                            Ara Toplam :{' '}
                            {/* {basket &&
                            basket.ActiveBasket &&
                            basket.ActiveBasket.BasketPrice &&
                            basket.ActiveBasket.BasketPrice.TL
                              ? basket.ActiveBasket.BasketPrice.TL.ValueDisplay
                              : 0} */}
                            <IPPriceDisplay
                              useDiv={false}
                              price={
                                basket &&
                                basket.ActiveBasket &&
                                basket.ActiveBasket.BasketPrice &&
                                basket.ActiveBasket.BasketPrice.TL
                                  ? basket.ActiveBasket.BasketPrice.TL.ValueDisplay
                                  : 0
                              }
                              integerFontSize='16px'
                              fractionalFontSize='12px'
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className='seperator mb-2 mt-0'></div>
                    <div className='row'>
                      {state.bannerProduct.length > 0 ? (
                        state.bannerProduct.length > 1 ? (
                          <>
                            <div className='col-lg-6'>
                              {state.bannerProduct[state.bannerProduct.length - 2].BannerType !==
                              IBannerTypeEnum.LinkYok ? (
                                state.bannerProduct[state.bannerProduct.length - 2].ProductAddress ? (
                                  <a
                                    href={state.bannerProduct[state.bannerProduct.length - 2].ProductAddress}
                                    target={
                                      state.bannerProduct[state.bannerProduct.length - 2].BannerType ===
                                      IBannerTypeEnum.YeniPencereLink
                                        ? '_blank'
                                        : ''
                                    }
                                    rel='noreferrer'
                                  >
                                    <img
                                      src={state.bannerProduct[state.bannerProduct.length - 2].ImageUrl}
                                      alt={state.bannerProduct[state.bannerProduct.length - 2].Title}
                                      className=''
                                    />
                                  </a>
                                ) : (
                                  <Link
                                    to={`/products?bannerId=${state.bannerProduct[state.bannerProduct.length - 2].Id}`}
                                    target={
                                      state.bannerProduct[state.bannerProduct.length - 2].BannerType ===
                                      IBannerTypeEnum.YeniPencereLink
                                        ? '_blank'
                                        : ''
                                    }
                                  >
                                    <img
                                      src={state.bannerProduct[state.bannerProduct.length - 2].ImageUrl}
                                      alt={state.bannerProduct[state.bannerProduct.length - 2].Title}
                                      className=''
                                    />
                                  </Link>
                                )
                              ) : (
                                <img
                                  src={state.bannerProduct[state.bannerProduct.length - 2].ImageUrl}
                                  alt={state.bannerProduct[state.bannerProduct.length - 2].Title}
                                  className=''
                                />
                              )}
                            </div>
                            <div className='col-lg-6'>
                              {state.bannerProduct[state.bannerProduct.length - 1].BannerType !==
                              IBannerTypeEnum.LinkYok ? (
                                state.bannerProduct[state.bannerProduct.length - 1].ProductAddress ? (
                                  <a
                                    href={state.bannerProduct[state.bannerProduct.length - 1].ProductAddress}
                                    target={
                                      state.bannerProduct[state.bannerProduct.length - 1].BannerType ===
                                      IBannerTypeEnum.YeniPencereLink
                                        ? '_blank'
                                        : ''
                                    }
                                    rel='noreferrer'
                                  >
                                    <img
                                      src={state.bannerProduct[state.bannerProduct.length - 1].ImageUrl}
                                      alt={state.bannerProduct[state.bannerProduct.length - 1].Title}
                                      className=''
                                    />
                                  </a>
                                ) : (
                                  <Link
                                    to={`/products?bannerId=${state.bannerProduct[state.bannerProduct.length - 1].Id}`}
                                    target={
                                      state.bannerProduct[state.bannerProduct.length - 1].BannerType ===
                                      IBannerTypeEnum.YeniPencereLink
                                        ? '_blank'
                                        : ''
                                    }
                                  >
                                    <img
                                      src={state.bannerProduct[state.bannerProduct.length - 1].ImageUrl}
                                      alt={state.bannerProduct[state.bannerProduct.length - 1].Title}
                                      className=''
                                    />
                                  </Link>
                                )
                              ) : state.bannerProduct[state.bannerProduct.length - 2].BannerType !==
                                IBannerTypeEnum.LinkYok ? (
                                state.bannerProduct[state.bannerProduct.length - 2].ProductAddress ? (
                                  <a
                                    href={state.bannerProduct[state.bannerProduct.length - 2].ProductAddress}
                                    target={
                                      state.bannerProduct[state.bannerProduct.length - 2].BannerType ===
                                      IBannerTypeEnum.YeniPencereLink
                                        ? '_blank'
                                        : ''
                                    }
                                    rel='noreferrer'
                                  >
                                    <img
                                      src={state.bannerProduct[state.bannerProduct.length - 2].ImageUrl}
                                      alt={state.bannerProduct[state.bannerProduct.length - 2].Title}
                                      className=''
                                    />
                                  </a>
                                ) : (
                                  <Link
                                    to={`/products?bannerId=${state.bannerProduct[state.bannerProduct.length - 2].Id}`}
                                    target={
                                      state.bannerProduct[state.bannerProduct.length - 2].BannerType ===
                                      IBannerTypeEnum.YeniPencereLink
                                        ? '_blank'
                                        : ''
                                    }
                                  >
                                    <img
                                      src={state.bannerProduct[state.bannerProduct.length - 2].ImageUrl}
                                      alt={state.bannerProduct[state.bannerProduct.length - 2].Title}
                                      className=''
                                    />
                                  </Link>
                                )
                              ) : (
                                <img
                                  src={state.bannerProduct[state.bannerProduct.length - 2].ImageUrl}
                                  alt={state.bannerProduct[state.bannerProduct.length - 2].Title}
                                  className=''
                                />
                              )}
                            </div>
                          </>
                        ) : (
                          <div className='col-lg-6'>
                            {state.bannerProduct[0].BannerType !== IBannerTypeEnum.LinkYok ? (
                              state.bannerProduct[0].ProductAddress ? (
                                <a
                                  href={state.bannerProduct[0].ProductAddress}
                                  target={
                                    state.bannerProduct[0].BannerType === IBannerTypeEnum.YeniPencereLink
                                      ? '_blank'
                                      : ''
                                  }
                                  rel='noreferrer'
                                >
                                  <img
                                    src={state.bannerProduct[0].ImageUrl}
                                    alt={state.bannerProduct[0].Title}
                                    className=''
                                  />
                                </a>
                              ) : (
                                <Link
                                  to={`/products?bannerId=${state.bannerProduct[0].Id}`}
                                  target={
                                    state.bannerProduct[0].BannerType === IBannerTypeEnum.YeniPencereLink
                                      ? '_blank'
                                      : ''
                                  }
                                >
                                  <img
                                    src={state.bannerProduct[0].ImageUrl}
                                    alt={state.bannerProduct[0].Title}
                                    className=''
                                  />
                                </Link>
                              )
                            ) : (
                              <img
                                src={state.bannerProduct[0].ImageUrl}
                                alt={state.bannerProduct[0].Title}
                                className=''
                              />
                            )}
                          </div>
                        )
                      ) : (
                        ''
                      )}
                    </div>

                    <div className='row'>
                      <div className='col-md-12' style={{ fontSize: '13px', fontWeight: '500' }}>
                        <span className='text-black'>
                          *BDDK tarafından, finansal istikrarın güçlendirilmesi ve makroekonomik dengelenme sürecinin
                          desteklenmesi kapsamında kredi kartı taksit uygulamaları aşağıda belirtilen ürün grupları
                          hariç 3 taksit sınırlaması getirilmiştir.
                        </span>
                        <br />
                        <span style={{ color: '#EC4C4C', margin: '3px 0', display: 'block' }}>
                          *Server, Tablet ve Beyaz eşya grubunda 6 aya kadar taksit uygulanacaktır. &nbsp;&nbsp;
                          *Notebook, Desktop grubunda 12 aya kadar taksit uygulanacaktır.
                          <br />
                          *Sepetinizdeki diğer ürünleri en fazla 3 taksitle alabilirsiniz.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade p-t-40'
                    id='payment-method'
                    role='tabpanel'
                    aria-labelledby='payment-method-tab'
                  >
                    <div className={`${styles.payment_method}`}>
                      <form action='' className=' p-10'>
                        <div className='row'>
                          {state.dealerSavedCondition.length > 0 && (
                            <div className='form-check white-circle pl-0'>
                              <input
                                className='white-circle-input form-check-input flex-shrink-0'
                                name='paymentRadio'
                                id='exampleRadios1'
                                value='option1'
                                checked={state.paymentMethod === 'savedcondition'}
                                type='radio'
                                onChange={() =>
                                  dispatchState({
                                    paymentMethod: 'savedcondition',
                                  })
                                }
                              />
                              <label className='form-check-label m-0 ' htmlFor='exampleRadios1'>
                                <span style={{ fontSize: '15px' }}>
                                  Kayıtlı çalışma koşullarım ile satın almak istiyorum.
                                </span>
                              </label>
                            </div>
                          )}
                        </div>
                        {state.paymentMethod === 'savedcondition' && state.dealerSavedCondition.length > 0 && (
                          <div className='row'>
                            {state.dealerSavedCondition.map((dealer: any) => (
                              <div className='col-lg-6 mt-sm-4 mt-lg-10 card-mobile-resize ' key={dealer.Company}>
                                <div
                                  className='your-recent-visits text-center'
                                  style={{
                                    minHeight: '50px',
                                    height: '50px',
                                  }}
                                >
                                  <div>
                                    <Image
                                      src={dealer.Company === '2300' ? '/images/netex_logo.png' : dealer.Logo}
                                      alt=''
                                    />
                                  </div>
                                </div>
                                <div
                                  className='your-recent-visits text-center'
                                  style={{
                                    minHeight: '200px',
                                    height: '200px',
                                  }}
                                >
                                  <div style={{ marginTop: '10px' }}>
                                    <IPSelectBox
                                      options={
                                        dealer.ConditionList &&
                                        dealer.ConditionList.map((item: IConditionList) => {
                                          return {
                                            label: item.Value,
                                            value: `${item.Condition}`,
                                          }
                                        })
                                      }
                                      placeholder='Ödeme Şekli'
                                      onChangeSingle={(value: ISelectOption) => {
                                        savedList(
                                          dealer.Company,
                                          value,
                                          'condition',
                                          dealer.ConditionList.find(
                                            x => x.Condition === value.value
                                          ).AvailableCurrency.map(ava => {
                                            return { value: ava, label: ava }
                                          })
                                        )
                                      }}
                                    />
                                  </div>
                                  <div style={{ marginTop: '10px' }}>
                                    <IPSelectBox
                                      options={
                                        savedConditionList.find(save => save.Company === dealer.Company)
                                          ?.AvaibleCurrency
                                      }
                                      value={
                                        savedConditionList.find(save => save.Company === dealer.Company)?.CurrencySelect
                                      }
                                      placeholder='Para Birimi'
                                      onChangeSingle={(value: ISelectOption) => {
                                        savedList(dealer.Company, value, 'currency')
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                        {(new Date('2023-05-12 17:30:00') > new Date() ||
                          new Date('2023-05-15 07:59:59') < new Date()) && (
                          <div className='row mt-3'>
                            <div className='form-check white-circle pl-0'>
                              <input
                                className='white-circle-input form-check-input flex-shrink-0'
                                type='radio'
                                checked={state.paymentMethod === 'iyzico'}
                                name='paymentRadio'
                                onChange={() => {
                                  let companies: any[] = []
                                  new GetCompaniesApi({}).getCompanyApi().then(res => {
                                    companies = res
                                    const threedPaymentCompanyCode = basket.ActiveBasket.Companies.map(x => {
                                      const item = companies.find(a => a.Id === x.CompanyID)?.Code
                                      return item.toString()
                                    })
                                    dispatchState({
                                      paymentMethod: 'iyzico',
                                      threedPaymentCompanyCode: threedPaymentCompanyCode
                                        ? threedPaymentCompanyCode
                                        : [],
                                    })
                                  })
                                }}
                                id='exampleRadios5'
                                value='option1'
                              />
                              <label className='form-check-label  m-0 ' htmlFor='exampleRadios5'>
                                <span style={{ fontSize: '15px' }}>İyzico aracılığı ile satın almak istiyorum.</span>
                              </label>
                            </div>
                          </div>
                        )}

                        {state.paymentMethod === 'iyzico' && (
                          <div id='PaymentForm' style={{ marginTop: '25px' }}>
                            <div className='row mt-3'>
                              <div className='col-md-6 p-0'>
                                <div className='row mt-3'>
                                  <div className='col-md-12'>
                                    <InputMask
                                      placeholder='Kart Numarası'
                                      mask='9999 9999 9999 9999'
                                      className='form-control'
                                      value={state.ccNumber}
                                      //NOTE: Eski
                                      // onChange={e => {
                                      //   const ccNumber = e.target.value.replaceAll(' ', '')

                                      //   if (!ccNumber.includes('_')) {
                                      //     const BinNumber = ccNumber.slice(0, 6)
                                      //     const req: binNumberRequest = {
                                      //       CargoPayment: state.cargoList?.CargoItem,
                                      //       BinNumber,
                                      //       //amount: 550,
                                      //       basket:
                                      //         basket &&
                                      //         basket.ActiveBasket &&
                                      //         basket.ActiveBasket.BasketPrice &&
                                      //         basket.ActiveBasket.BasketPrice.TL
                                      //           ? basket.ActiveBasket
                                      //           : //.BasketPrice.TL.Total
                                      //             //0,
                                      //             ({} as ISimpleBasket),
                                      //     }
                                      //     sendBinNumber(req)
                                      //   }

                                      //   dispatchState({
                                      //     ccNumber,
                                      //   })
                                      // }}
                                      onPaste={e => {
                                        const ccNumber = e.clipboardData.getData('text').replaceAll(' ', '')
                                        dispatchState({ ccNumber })
                                        dispatchState({ sentBinNumber: false })
                                        dispatchState({ showInstallments: false })
                                        if (ccNumber.length >= 6) {
                                          // Only proceed if sendBinNumber hasn't been called yet
                                          const BinNumber = ccNumber.slice(0, 6)
                                          const req = { BinNumber }
                                          findBankInfos(req)
                                          dispatchState({ sentBinNumber: true })
                                        }
                                        if (ccNumber.length === 16) {
                                          const BinNumber = ccNumber.slice(0, 6)
                                          const req: binNumberRequest = {
                                            CargoPayment: state.cargoList?.CargoItem,
                                            BinNumber,
                                            //amount: 550,
                                            basket:
                                              basket &&
                                              basket.ActiveBasket &&
                                              basket.ActiveBasket.BasketPrice &&
                                              basket.ActiveBasket.BasketPrice.TL
                                                ? basket.ActiveBasket
                                                : //.BasketPrice.TL.Total
                                                  //0,
                                                  ({} as ISimpleBasket),
                                          }
                                          sendBinNumber(req)
                                        }
                                      }}
                                      onChange={e => {
                                        const ccNumber = e.target.value.replaceAll(' ', '').replaceAll('_', '')
                                        if (ccNumber.length < 6) {
                                          dispatchState({ showInstallments: false })
                                          dispatchState({ sentBinNumber: false })
                                        } else if (ccNumber.length >= 6 && !state.sentBinNumber) {
                                          // Check if sendBinNumber hasn't been called yet
                                          const BinNumber = ccNumber.slice(0, 6)
                                          const req: binNumberRequest = {
                                            CargoPayment: state.cargoList?.CargoItem,
                                            BinNumber,
                                            basket:
                                              basket &&
                                              basket.ActiveBasket &&
                                              basket.ActiveBasket.BasketPrice &&
                                              basket.ActiveBasket.BasketPrice.TL
                                                ? basket.ActiveBasket
                                                : ({} as ISimpleBasket),
                                          }
                                          const reqBin = { BinNumber }
                                          findBankInfos(reqBin)
                                          sendBinNumber(req)
                                          dispatchState({ sentBinNumber: true }) // Set flag to true indicating sendBinNumber has been called
                                        }
                                        dispatchState({
                                          ccNumber,
                                        })
                                      }}
                                      onFocus={() => {
                                        dispatchState({ ccFocus: 'number' })
                                      }}
                                    />
                                    {/* <IPInput
                                      type='tel'
                                      name='text'
                                      className='form-control'
                                      placeholder='Kart Numarası'
                                      //pattern="[\d| ]{16,22}"
                                      maxLength={16}
                                      dataPattern={IPInputDataPattern.Numeric}
                                      required
                                      value={state.ccNumber}
                                      onPaste={(e: any) => {
                                        const ccNumber = e.clipboardData.getData('text').replaceAll(' ', '')
                                        dispatchState({ ccNumber })
                                        if (ccNumber.length === 16) {
                                          const BinNumber = ccNumber.slice(0, 6)
                                          const req: binNumberRequest = {
                                            CargoPayment: state.cargoList?.CargoItem,
                                            BinNumber,
                                            //amount: 550,
                                            basket:
                                              basket &&
                                              basket.ActiveBasket &&
                                              basket.ActiveBasket.BasketPrice &&
                                              basket.ActiveBasket.BasketPrice.TL
                                                ? basket.ActiveBasket
                                                : //.BasketPrice.TL.Total
                                                  //0,
                                                  ({} as ISimpleBasket),
                                          }
                                          sendBinNumber(req)
                                        }
                                      }}
                                      onChange={e => {
                                        const { target } = e

                                        target.value = target.value.replaceAll(' ', '')

                                        if (target.value.length === 16) {
                                          const BinNumber = target.value.slice(0, 6)
                                          const req: binNumberRequest = {
                                            CargoPayment: state.cargoList?.CargoItem,
                                            BinNumber,
                                            //amount: 550,
                                            basket:
                                              basket &&
                                              basket.ActiveBasket &&
                                              basket.ActiveBasket.BasketPrice &&
                                              basket.ActiveBasket.BasketPrice.TL
                                                ? basket.ActiveBasket
                                                : //.BasketPrice.TL.Total
                                                  //0,
                                                  ({} as ISimpleBasket),
                                          }
                                          sendBinNumber(req)
                                        }

                                        dispatchState({
                                          ccNumber: target.value,
                                        })
                                      }}
                                      onFocus={() => {
                                        dispatchState({ ccFocus: 'number' })
                                      }}
                                    /> */}
                                  </div>
                                </div>
                                <div className='row mt-3'>
                                  <div className='col-md-12'>
                                    <IPInput
                                      type='text'
                                      name='name'
                                      className='form-control'
                                      placeholder='Ad Soyad'
                                      dataPattern={IPInputDataPattern.AlphabeticWithSpaces}
                                      required
                                      onChange={e => {
                                        const { target } = e

                                        dispatchState({
                                          ccName: target.value,
                                        })
                                      }}
                                      onFocus={() => dispatchState({ ccFocus: 'name' })}
                                    />
                                  </div>
                                </div>
                                <div className='row mt-3'>
                                  <div className='col-md-6 mb-3'>
                                    <InputMask
                                      mask={'99/99'}
                                      type='tel'
                                      name='expiry'
                                      placeholder='Geçerlilik Tarihi'
                                      required
                                      onChange={e => {
                                        const { target } = e

                                        target.value = formatExpirationDate(target.value)

                                        dispatchState({
                                          ccExpiry: target.value,
                                        })
                                      }}
                                      onFocus={() => dispatchState({ ccFocus: 'expiry' })}
                                    />
                                  </div>
                                  <div className='col-md-6 mb-3'>
                                    <IPInput
                                      type='tel'
                                      name='cvc'
                                      className='form-control'
                                      placeholder='CVC'
                                      //pattern="\d{3,4}"
                                      maxLength={3}
                                      dataPattern={IPInputDataPattern.Numeric}
                                      required
                                      onChange={e => {
                                        const { target } = e

                                        target.value = formatCVC(target.value)

                                        dispatchState({
                                          ccCVC: target.value,
                                        })
                                      }}
                                      onFocus={() => dispatchState({ ccFocus: 'cvc' })}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='col-md-6' style={{ position: 'relative' }}>
                                {state.ccNumber.length > 5 && (
                                  //state.bankCode > 0 &&
                                  <Image
                                    alt=''
                                    src={state.bankLogo}
                                    className={styles.banksImage}
                                    style={{
                                      position: 'absolute',
                                      top: '15%',
                                      left: '45%',
                                      transform: 'translate(-50%, -50%)',
                                      zIndex: '999',
                                      maxWidth: '90px',
                                    }}
                                  />
                                )}

                                <Cards
                                  cvc={state.ccCVC}
                                  expiry={state.ccExpiry}
                                  focused={state.ccFocus}
                                  name={state.ccName}
                                  number={state.ccNumber}
                                  placeholders={{ name: 'AD SOYAD' }}
                                />
                              </div>
                              <div className='col-12 p-0 mt-3'>
                                <IPCheckbox
                                  disabled={state.threedPaymentDisabled}
                                  checked={state.securePayment}
                                  label='3D Secure ile ödeme yapmak istiyorum.'
                                  onCheckedChange={checked => dispatchState({ securePayment: checked })}
                                />
                              </div>
                            </div>
                            {state.binResponse && state.showInstallments ? (
                              <div className='table-responsive'>
                                <div className={`${styles.bankLogoRow} row`}>
                                  <div className={`${styles.bankLogoCol} col-md-12`}>
                                    <Image alt={''} src={state.bankLogo} className={styles.banksImage} />
                                    <div className={styles.subtitleInstallment}></div>
                                    <div className={styles.descriptionInstallment}>
                                      <div>Taksit Sayısı</div>
                                      <div className=''>Taksit Tutarı</div>
                                      <div className=''>Komisyon Tutarı</div>
                                      <div className=''>Toplam Tutar</div>
                                    </div>
                                    <div className={styles.subtitleInstallment}></div>

                                    {state.installmentInfo &&
                                      state.installmentInfo.map((item, index) => (
                                        <div
                                          key={index}
                                          className={styles.installmentContainer}
                                          style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            width: 'none',
                                          }}
                                        >
                                          <div
                                            style={{
                                              width: '100%',
                                              display: 'flex',
                                              justifyContent: 'space-evenly',
                                            }}
                                          >
                                            <p style={{ width: '1%' }}>
                                              <input
                                                type='radio'
                                                name='InstallmentRadio'
                                                className='mr-5'
                                                value={item.InstallmentNumber}
                                                onChange={e => {
                                                  const { target } = e

                                                  dispatchState({
                                                    ccInstallmentNumber: Number(target.value),
                                                  })
                                                }}
                                              />
                                            </p>
                                            <p
                                              style={{ whiteSpace: 'nowrap', justifyContent: 'center', width: '5%' }}
                                              className={styles.ComissionValue}
                                            >
                                              {item.InstallmentNumber}
                                            </p>
                                            <p
                                              style={{ whiteSpace: 'nowrap', justifyContent: 'end', width: '22%' }}
                                              className={styles.ComissionValue}
                                            >
                                              {/* {item.PriceValue} */}
                                              <IPPriceDisplay useDiv={false} price={item.PriceValue} />
                                            </p>
                                            <p
                                              style={{ whiteSpace: 'nowrap', justifyContent: 'end', width: '22%' }}
                                              className={styles.ComissionValue}
                                            >
                                              {/* {item.ComissionValue} */}
                                              <IPPriceDisplay useDiv={false} price={item.ComissionValue} />
                                            </p>
                                            <p
                                              style={{
                                                whiteSpace: 'nowrap',
                                                justifyContent: 'end',
                                                paddingRight: '3%',
                                              }}
                                              className={styles.totalPrice}
                                            >
                                              {/* {item.TotalPriceValue} */}
                                              <IPPriceDisplay useDiv={false} price={item.TotalPriceValue} />
                                            </p>
                                            {state.expandedIntallment !== item.InstallmentNumber && (
                                              <i
                                                className='icon-chevron-down'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                  dispatchState({
                                                    expandedIntallment: item.InstallmentNumber,
                                                  })
                                                }
                                              ></i>
                                            )}
                                            {state.expandedIntallment === item.InstallmentNumber && (
                                              <i
                                                className='icon-chevron-up'
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                  dispatchState({
                                                    expandedIntallment: 0,
                                                  })
                                                }
                                              ></i>
                                            )}
                                          </div>
                                          {state.expandedIntallment === item.InstallmentNumber && (
                                            <div
                                              style={{
                                                minWidth: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                border: '1px solid gray',
                                                borderRadius: '10px',
                                                backgroundColor: '#FFF8EA',
                                                padding: '8px',
                                                marginLeft: '0px',
                                              }}
                                            >
                                              {/*<div className="container-fluid">
                                                <div className="column d-flex justify-content-center">
                                                  <div className="col-2">
                                                    #
                                                  </div>
                                                  <div className="col-3">
                                                    Ürün Kodu
                                                  </div>
                                                  <div className="col-5">
                                                    Toplam Tutar(KDV Dahil)
                                                  </div>
                                                  <div className="col-3">
                                                    Komisyon(%)
                                                  </div>
                                                </div>
                                            </div>*/}
                                              <table className='table table-borderless'>
                                                <thead>
                                                  <tr>
                                                    <th scope='col'>#</th>
                                                    <th scope='col'>Ürün Kodu</th>
                                                    <th scope='col'>Komisyon(%)</th>
                                                    <th scope='col'>Toplam Tutar (KDV Dahil)</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {item.InstallmentDetail?.map((detail: KampanyaVade, idx: number) => {
                                                    return (
                                                      <tr>
                                                        <th scope='row'>{idx + 1}-</th>
                                                        {detail.IyzicoCampaignId !== 0 ? (
                                                          <th>
                                                            {' '}
                                                            {
                                                              state.productsOnBasket.find(
                                                                (item: IBasicProductModel) => item.Id === detail.ProdId
                                                              )?.Code
                                                            }
                                                            <br />
                                                            <strong style={{ color: 'red' }}>
                                                              {detail.IyzicoCampaignName}{' '}
                                                            </strong>
                                                          </th>
                                                        ) : (
                                                          <th>
                                                            {
                                                              state.productsOnBasket.find(
                                                                (item: IBasicProductModel) => item.Id === detail.ProdId
                                                              )?.Code
                                                            }
                                                          </th>
                                                        )}
                                                        <th>{detail.RateUI + ' %'}</th>
                                                        <th>{detail.Price.toFixed(2) + ' ₺'}</th>
                                                      </tr>
                                                    )
                                                  })}
                                                  {item.InstallmentDetail?.map((detail: KampanyaVade, idx: number) => {
                                                    return (
                                                      item.InstallmentDetail.length === idx + 1 && (
                                                        <tr>
                                                          <th scope='row'>{idx + 2}-</th>
                                                          <th>Kargo Ücreti</th>
                                                          <th></th>
                                                          <th>
                                                            {/* {item.CargoPrice.toFixed(2) + ' ₺'} */}
                                                            <IPPriceDisplay
                                                              useDiv={false}
                                                              color='#A2A4A9'
                                                              price={item.CargoPrice.toFixed(2) + ' ₺'}
                                                              integerFontSize='17px'
                                                              fractionalFontSize='12px'
                                                            />
                                                          </th>
                                                        </tr>
                                                      )
                                                    )
                                                  })}
                                                </tbody>
                                              </table>
                                              {/* <table className="table table-borderless" style={{ marginRight: "250px" }}>
                                                <thead className="text-center">
                                                  <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Ürün Kodu</th>
                                                    <th scope="col">Toplam Tutar(KDV Dahil)</th>
                                                    <th scope="col">Komisyon(%)</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  <tr>
                                                    {item.InstallmentDetail?.map(
                                                      (
                                                        detail: KampanyaVade,
                                                        idx: number
                                                      ) => {
                                                        return (
                                                          <div>
                                                            <div
                                                              style={{
                                                                width: "90%",
                                                                display: "flex",
                                                                justifyContent:
                                                                  "flex-start",
                                                                gap: "10px",
                                                              }}
                                                            >
                                                              <div
                                                                style={{
                                                                  justifyContent:
                                                                    "flex-end",
                                                                  width: "40px",
                                                                }}
                                                              >
                                                                <div>
                                                                  <th scope="row">{idx + 1}-</th>
                                                                </div>
                                                              </div>
                                                              {detail.IyzicoCampaignId !==
                                                                0 && (
                                                                  <div>
                                                                    <strong>
                                                                      Kampanya:{" "}
                                                                    </strong>
                                                                    {
                                                                      detail.IyzicoCampaignName
                                                                    }
                                                                  </div>
                                                                )}
                                                              <div
                                                                style={{ width: "100%", marginLeft: "130px" }}
                                                              >
                                                                <th>
                                                                  {
                                                                    state.productsOnBasket.find(
                                                                      (
                                                                        item: IBasicProductModel
                                                                      ) =>
                                                                        item.Id ===
                                                                        detail.ProdId
                                                                    )?.Code
                                                                  }
                                                                </th>
                                                              </div>
                                                              <div>
                                                              </div>
                                                              <th> {detail.Price.toString().slice(0, 7)}</th>
                                                              <div style={{ marginLeft: "200px" }}>
                                                                <th>{detail.RateUI}</th>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        );
                                                      }
                                                    )}
                                                  </tr>
                                                </tbody>
                                                
                                                {item.InstallmentDetail?.map(
                                                  (
                                                    detail: KampanyaVade,
                                                    idx: number
                                                  ) => {
                                                    return (
                                                      <div
                                                        style={{
                                                          width: "100%",
                                                          display: "flex",
                                                          justifyContent:
                                                            "flex-start",
                                                          gap: "10px",
                                                        }}
                                                      >
                                                        <div
                                                          style={{
                                                            justifyContent:
                                                              "flex-end",
                                                            width: "40px",
                                                          }}
                                                        >
                                                          {idx + 1}-
                                                        </div>
                                                        {detail.IyzicoCampaignId !==
                                                          0 && (
                                                            <div>
                                                              <strong>
                                                                Kampanya:{" "}
                                                              </strong>
                                                              {
                                                                detail.IyzicoCampaignName
                                                              }
                                                            </div>
                                                          )}
                                                        <div
                                                          style={{ width: "100%" }}
                                                        >
                                                          <strong>Ürün: </strong>
                                                          {
                                                            state.productsOnBasket.find(
                                                              (
                                                                item: IBasicProductModel
                                                              ) =>
                                                                item.Id ===
                                                                detail.ProdId
                                                            )?.Code
                                                          }
                                                        </div>
                                                        <div>
                                                        </div>
                                                        {detail.IyzicoCampaignId}
                                                        <div>
                                                          Oran: {detail.RateUI}
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                               
                                            </table>
                                             */}
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        )}
                      </form>
                      {/* <div className='row'>
                        <div className='col'>
                          <IPButton
                            text='İlerle'
                            className='ml-auto w-25'
                            onClick={() => document.getElementById('order-note-tab')?.click()}
                          />
                        </div>
                      </div> */}
                    </div>
                    <div className='row m-t-50'>
                      <div className='col-lg-12'>
                        {state.bannerPayment &&
                          (state.bannerPayment.BannerType !== IBannerTypeEnum.LinkYok ? (
                            state.bannerPayment.ProductAddress ? (
                              <a
                                href={state.bannerPayment.ProductAddress}
                                target={
                                  state.bannerPayment.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''
                                }
                                rel='noreferrer'
                              >
                                <Image src={state.bannerPayment.ImageUrl} alt={state.bannerPayment.Title} />
                              </a>
                            ) : (
                              <Link
                                to={`/products?bannerId=${state.bannerPayment.Id}`}
                                target={
                                  state.bannerPayment.BannerType === IBannerTypeEnum.YeniPencereLink ? '_blank' : ''
                                }
                              >
                                <Image src={state.bannerPayment.ImageUrl} alt={state.bannerPayment.Title} />
                              </Link>
                            )
                          ) : (
                            <Image src={state.bannerPayment.ImageUrl} alt={state.bannerPayment.Title} />
                          ))}
                      </div>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade p-t-40'
                    id='address'
                    role='tabpanel'
                    aria-labelledby='address-tab'
                    onClick={() => {
                      if (addressTooltip.show) {
                        setAddressTooltip({
                          show: false,
                          coords: {
                            top: 0,
                            left: 0,
                          },
                          content: null,
                        })
                      }
                    }}
                  >
                    <div className={`${styles.address}`}>
                      <form action='' className='p-10'>
                        {/* <div className="row p-l-15 p-r-15">
                          <div className="form-check pl-0">
                            <input
                              className="form-check-input"
                              name="paymentRadio"
                              id="exampleRadios7"
                              value="option1"
                              type="radio"
                            />
                            <label
                              className="form-check-label m-0 p-t-10"
                              htmlFor="exampleRadios7"
                            >
                              <span
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "500",
                                  color: "#616161",
                                }}
                              >
                                Lütfen Sevkiyat Adresi, Tarih ve Formatı Seçiniz
                              </span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group row align-items-center m-auto m-b-20">
                          <div
                            className="col-auto d-flex align-items-center"
                            style={{
                              height: 40,
                              backgroundColor: "rgba(15, 189, 224,0.1)",
                              borderRadius: "20px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: "500",
                                color: "#616161",
                              }}
                            >
                              Ev / Mohammad Hosen
                            </span>
                          </div>
                        </div> */}
                        <div className='form-group row align-items-center'>
                          <div className='col-sm-5 text-sm-left'>
                            <label
                              style={{
                                color: '#616161',
                                fontWeight: '500',
                                fontSize: '1.5rem',
                              }}
                            >
                              Fatura{' '}
                              {state.addresses &&
                                state.addresses.ShippAddresList &&
                                state.addresses.ShippAddresList.length === 0 &&
                                've Sevk'}{' '}
                              Adresi
                            </label>
                          </div>
                          <div className='col-sm-7'>
                            <IPSelectBox
                              isDisabled={state.approval}
                              options={
                                state.addresses
                                  ? state.addresses?.InvoiceAddressList?.map((address: IAddressModel) => {
                                      return {
                                        value: `${address.Id}`,
                                        label: (
                                          <div
                                            onMouseEnter={e => handleAddressTooltip(e, address)}
                                            onMouseLeave={_ =>
                                              setAddressTooltip(prev => {
                                                return {
                                                  ...prev,
                                                  show: false,
                                                }
                                              })
                                            }
                                            onClick={_ =>
                                              setAddressTooltip(prev => {
                                                return {
                                                  ...prev,
                                                  show: false,
                                                }
                                              })
                                            }
                                          >
                                            {address.AddressTitle ? address.AddressTitle : address.AddressDetail}
                                          </div>
                                        ),
                                      }
                                    })
                                  : []
                              }
                              onChangeSingle={(value: ISelectOption) => {
                                if (
                                  state.addresses &&
                                  state.addresses.ShippAddresList &&
                                  state.addresses.ShippAddresList.length === 0
                                ) {
                                  shipmentAdres(value, 'Fatura ve Sevk')
                                  dispatchState({ ShippingTypes: [] })
                                }
                                dispatchState({ selectedBillAddress: value })
                              }}
                              value={state.selectedBillAddress}
                            />
                          </div>
                        </div>
                        {state.addresses &&
                          state.addresses.ShippAddresList &&
                          state.addresses.ShippAddresList.length > 0 && (
                            <>
                              <div className='form-group row align-items-center'>
                                <div className='col-sm-5 text-sm-left'>
                                  <label
                                    style={{
                                      color: '#616161',
                                      fontWeight: '500',
                                      fontSize: '1.5rem',
                                    }}
                                  >
                                    Sevk Adresi
                                  </label>
                                </div>
                                <div className='col-sm-7'>
                                  <IPSelectBox
                                    isDisabled={state.approval}
                                    styles={customStyles}
                                    options={state.addresses.ShippAddresList.map((address: IAddressModel) => {
                                      return {
                                        value: `${address.Id}`,
                                        label: (
                                          <div
                                            onMouseEnter={e => handleAddressTooltip(e, address)}
                                            onMouseLeave={_ =>
                                              setAddressTooltip(prev => {
                                                return {
                                                  ...prev,
                                                  show: false,
                                                }
                                              })
                                            }
                                            onClick={_ =>
                                              setAddressTooltip(prev => {
                                                return {
                                                  ...prev,
                                                  show: false,
                                                }
                                              })
                                            }
                                          >
                                            {address.AddressTitle ? address.AddressTitle : address.AddressDetail}
                                          </div>
                                        ),
                                      }
                                    })}
                                    // value={state.addresses
                                    //   ?.filter((addr: IAddress) => {
                                    //     return (
                                    //       state.selectedAddress &&
                                    //       Number(addr.Id) ===
                                    //       Number(state.selectedAddress.value)
                                    //     );
                                    //   })
                                    //   .map((address: IAddress) => {
                                    //     return {
                                    //       value: `${address.Id}`,
                                    //       label: address.AddressTitle
                                    //         ? address.AddressTitle
                                    //         : address.AddressDetail,
                                    //     };
                                    //   })}
                                    onChangeSingle={(value: ISelectOption) => {
                                      shipmentAdres(value, 'Sevk')
                                      dispatchState({ ShippingTypes: [], ShippingName: '' })
                                    }}
                                  />
                                </div>
                              </div>
                              <div className='form-group row align-items-center'>
                                <div className='col-sm-5 text-sm-left'>
                                  <label
                                    style={{
                                      color: '#616161',
                                      fontWeight: '500',
                                      fontSize: '1.5rem',
                                    }}
                                  >
                                    Müşteri adresi
                                  </label>
                                </div>
                                <div className='col-sm-7'>
                                  <IPButton
                                    disabled={state.approval}
                                    className='btn btn border-0 p-0'
                                    style={{
                                      backgroundColor: '#EE333E',
                                      width: '100%',
                                      borderRadius: '0',
                                    }}
                                    text={'Yeni Adres'}
                                    onClick={() => dispatchState({ showAddressModal: true, adresModalName: 'Adres' })}
                                  />
                                </div>
                              </div>
                            </>
                          )}

                        <div className='form-group row align-items-center'>
                          <div className='col-sm-5 text-sm-left'>
                            <label
                              style={{
                                color: '#616161',
                                fontWeight: '500',
                                fontSize: '1.5rem',
                              }}
                            >
                              Sevkiyat Tarihi
                            </label>
                          </div>
                          <div className='col-md-7'>
                            <IPDatePicker
                              disabled={state.approval}
                              selected={shipmentDate}
                              onChange={(date: Date) => {
                                shipmentData(date)
                                dispatchState({ ShippingTypes: [], ShippingName: '' })
                              }}
                              dateFormat={'dd-MM-yyyy'}
                              minDate={new Date()}
                            />
                          </div>
                        </div>
                        <div className='form-group row align-items-center'>
                          <div className='col-sm-5 text-sm-left'>
                            <label
                              style={{
                                color: '#616161',
                                fontWeight: '500',
                                fontSize: '1.5rem',
                              }}
                            >
                              Gönderim Formatı
                            </label>
                          </div>
                          <div className='col-sm-7'>
                            <IPSelectBox
                              isDisabled={state.approval}
                              options={state.ShippingTypes}
                              value={{
                                value:
                                  state.ShippingType ||
                                  (state.ShippingTypes && state.ShippingTypes[0] && state.ShippingTypes[0].value),
                                label:
                                  state.ShippingName ||
                                  (state.ShippingTypes && state.ShippingTypes[0] && state.ShippingTypes[0].label),
                              }}
                              onChangeSingle={(option: ISelectOption) => {
                                dispatchState({
                                  ShippingType: String(option.value),
                                  ShippingName: option.label,
                                  type: true,
                                })
                              }}
                            />
                          </div>
                          {state.ShippingType === '04' ? (
                            <div>
                              <br></br>
                              <p style={{ color: 'red' }}>
                                *TEKLOS Depo Adresi: Cumhuriyet Mahallesi, Yahyakaptan Caddesi No:10A D:2 41420
                                Çayırova/Kocoeli
                              </p>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                        {/* NOTE: Adres Sekmesi Eski İlerle Butonu
                        <div className='etkin d-flex justify-content-end'>
                          <label className='p-switch switch-color-success d-flex m-10'>
                            <label className='floagit t-right mr-4'>Adres Bilgilerimi Onaylıyorum</label>
                            <IPCheckbox name='Active' onChange={() => approval()} checked={state.approval} />
                          </label>
                        </div>
                        <div className='row mt-4'>
                          <div className='col'>
                            <IPButton text='İlerle' className='ml-auto w-25' onClick={paymentMethod} />
                          </div>
                        </div> */}
                      </form>
                    </div>
                  </div>
                  <div
                    className='tab-pane fade p-t-40'
                    id='order-note'
                    role='tabpanel'
                    aria-labelledby='order-note-tab'
                  >
                    <div
                      className='order-note background-white p-t-20 p-b-110 p-l-30 p-r-30'
                      style={{
                        border: ' 1px solid #CBCBCB',
                        fontSize: '1.5rem',
                      }}
                    >
                      <h6 style={{ color: '#616161' }}>Siparişlerinizle İlgili Notlarınız</h6>
                      <div className='form-group'>
                        {state.orderNotes.map((orderNote: IOrderNote) => {
                          return (
                            <>
                              <label>{`Şirket Adı: ${
                                orderNote.CompanyId === 1
                                  ? 'Index'
                                  : orderNote.CompanyId === 2
                                  ? 'Netex'
                                  : orderNote.CompanyId === 3
                                  ? 'Despec'
                                  : orderNote.CompanyId === 4
                                  ? 'Datagate'
                                  : ''
                              } Depo Adı: ${
                                state.warehouses.find(ware => {
                                  return ware.WarehouseId === orderNote.WarehouseId
                                })?.Name
                              }`}</label>
                              <textarea
                                className='form-control rounded-0'
                                id='exampleFormControlTextarea1'
                                rows={3}
                                value={orderNote.Note}
                                onKeyDown={e => {
                                  if (
                                    e.key === '#' ||
                                    e.key === '&' ||
                                    e.key === '<' ||
                                    e.key === '>' ||
                                    e.key === '\\' ||
                                    e.key === "'" ||
                                    e.keyCode === 51 ||
                                    e.key === 'Enter'
                                  ) {
                                    e.preventDefault()
                                  }
                                }}
                                onPaste={e => {
                                  e.preventDefault()
                                  const pastedText = e.clipboardData.getData('text/plain')
                                  // Belirtilen özel karakterlerin yanı sıra yeni satırları da kaldırmak için regex ifadesini güncelliyoruz
                                  const cleanText = pastedText.replace(/(\r\n|\n|\r|#|[&#<>\\'])/gm, '')
                                  document.execCommand('insertText', false, cleanText)
                                }}
                                onChange={e => {
                                  const toBeUpdatedNoteIdx = state.orderNotes.findIndex((noteElem: IOrderNote) => {
                                    return (
                                      noteElem.CompanyId === orderNote.CompanyId &&
                                      noteElem.WarehouseId === orderNote.WarehouseId
                                    )
                                  })
                                  const data: IOrderNote[] = []
                                  if (toBeUpdatedNoteIdx > -1) {
                                    state.orderNotes.map((elem: IOrderNote, a) => {
                                      if (a === toBeUpdatedNoteIdx) {
                                        return data.push({
                                          CompanyId: elem.CompanyId,
                                          WarehouseId: elem.WarehouseId,
                                          Note: e.target.value,
                                        })
                                      } else return data.push(elem)
                                    })
                                    // state.orderNotes[toBeUpdatedNoteIdx].Note = e.target.value
                                    dispatchState({
                                      orderNotes: data,
                                    })
                                  } else {
                                    dispatchState({
                                      orderNotes: state.orderNotes,
                                    })
                                  }
                                }}
                                style={{ border: '1px solid #C4C4C4' }}
                              ></textarea>
                              <br />
                            </>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-3 pl-lg-0'>
                <div className={`${styles.order_details}`}>
                  <div
                    style={{
                      fontSize: '20px',
                      fontWeight: '500',
                    }}
                  >
                    Sipariş Özeti
                  </div>
                  <div className='seperator mb-2 mt-0'></div>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <span className={`${styles.productCardName}`}>
                        <strong>Ara Toplam</strong>
                      </span>
                    </div>
                    <div>
                      {/* <span style={{ color: '#A2A4A9' }}>
                        {basket &&
                        basket.ActiveBasket &&
                        basket.ActiveBasket.BasketPrice &&
                        basket.ActiveBasket.BasketPrice.TL
                          ? basket.ActiveBasket.BasketPrice.TL.ValueDisplay
                          : '0,00 ₺'}
                      </span> */}
                      <IPPriceDisplay
                        useDiv={false}
                        color='#A2A4A9'
                        price={
                          basket &&
                          basket.ActiveBasket &&
                          basket.ActiveBasket.BasketPrice &&
                          basket.ActiveBasket.BasketPrice.TL
                            ? basket.ActiveBasket.BasketPrice.TL.ValueDisplay
                            : 0
                        }
                        integerFontSize='17px'
                        fractionalFontSize='12px'
                      />
                    </div>
                  </div>
                  {/* {
                    basket && basket.ActiveBasket && basket.ActiveBasket.CampaignBasketInfo && basket.ActiveBasket.CampaignBasketInfo.IsCampaign &&
                    <div className="d-flex justify-content-between mt-2">
                      <div>
                        <span className={`${styles.productCardName}`}>
                          <strong>Sepette İndirim</strong>
                        </span>
                      </div>
                      <div>
                        <span className={`${styles.productCardText}`}>
                        {basket.Price?.TotalDisplay}
                      </span>
                        <span style={{ color: "#A2A4A9" }}>0,00 ₺</span>
                      </div>
                    </div>
                  } */}

                  <div className='d-flex justify-content-between mt-2'>
                    {state.cargoItem.length === 0 && (
                      <>
                        <div>
                          <span className={`${styles.productCardName}`}>
                            <strong>Kargo Fiyat</strong>
                          </span>
                        </div>

                        <div>
                          {/* <span style={{ color: '#A2A4A9' }}>0,00 ₺</span> */}
                          <IPPriceDisplay
                            useDiv={false}
                            color='#A2A4A9'
                            price='0,00 ₺'
                            integerFontSize='17px'
                            fractionalFontSize='12px'
                          />
                        </div>
                      </>
                    )}
                  </div>
                  {state.cargoItem &&
                    state.cargoItem.length > 0 &&
                    state.cargoItem.map((cargo, i) => (
                      <div className='d-flex justify-content-between mt-2' key={i}>
                        <div>
                          <span className={`${styles.productCardName}`}>
                            <strong>
                              {state.companies
                                .filter(com => Number(com.Id) === Number(cargo.CompanyId))
                                .map(() => {
                                  return `Kargo (${cargo.Company})`
                                })}
                            </strong>
                          </span>
                        </div>
                        <div>
                          <span style={{ color: '#A2A4A9' }}>{cargo.DisplayValue}</span>
                        </div>
                      </div>
                    ))}

                  <div className='d-flex justify-content-between mt-2'>
                    <div>
                      <span className={`${styles.productCardName}`}>
                        <strong>Toplam KDV</strong>
                      </span>
                    </div>
                    <div>
                      {/* <span className={`${styles.productCardText}`}>
                        {basket.Price?.TotalDisplay}
                      </span> */}
                      {/* <span style={{ color: '#A2A4A9' }}>
                        {basket &&
                        basket.ActiveBasket &&
                        basket.ActiveBasket.BasketPrice &&
                        basket.ActiveBasket.BasketPrice.TL
                          ? basket.ActiveBasket.BasketPrice.TL.KdvDisplay
                          : '0,00 ₺'}
                      </span> */}

                      <IPPriceDisplay
                        useDiv={false}
                        color='#A2A4A9'
                        price={
                          basket &&
                          basket.ActiveBasket &&
                          basket.ActiveBasket.BasketPrice &&
                          basket.ActiveBasket.BasketPrice.TL
                            ? basket.ActiveBasket.BasketPrice.TL.KdvDisplay
                            : '0,00 ₺'
                        }
                        integerFontSize='17px'
                        fractionalFontSize='12px'
                      />
                    </div>
                  </div>
                  <div className='seperator mb-2 mt-0'></div>
                  <div className='d-flex justify-content-between mt-2'>
                    <strong className={`${styles.productCardName}`}>Genel Toplam</strong>
                    <div className='text-right' style={{ lineHeight: '1.7' }}>
                      {/* <p style={{ color: '#EE333E', fontSize: '1.5rem' }}>
                        {state.cargoList
                          ? state.cargoList.Basket &&
                            state.cargoList.Basket.BasketPrice &&
                            state.cargoList.Basket.BasketPrice.TL
                            ? state.cargoList.Basket.BasketPrice.TL.Display
                            : '0,00 ₺'
                          : basket &&
                            basket.ActiveBasket &&
                            basket.ActiveBasket.BasketPrice &&
                            basket.ActiveBasket.BasketPrice.TL
                          ? basket.ActiveBasket.BasketPrice.TL.Display
                          : '0,00 ₺'}
                      </p> */}

                      <IPPriceDisplay
                        useDiv={true}
                        color='#EE333E'
                        price={
                          state.cargoList
                            ? state.cargoList.Basket &&
                              state.cargoList.Basket.BasketPrice &&
                              state.cargoList.Basket.BasketPrice.TL
                              ? state.cargoList.Basket.BasketPrice.TL.Display
                              : '0,00 ₺'
                            : basket &&
                              basket.ActiveBasket &&
                              basket.ActiveBasket.BasketPrice &&
                              basket.ActiveBasket.BasketPrice.TL
                            ? basket.ActiveBasket.BasketPrice.TL.Display
                            : '0,00 ₺'
                        }
                        integerFontSize='1.5rem'
                        fractionalFontSize='1rem'
                      />

                      {/* <p style={{ color: '#EE333E', fontSize: '1.5rem' }}>
                        {state.cargoList
                          ? state.cargoList.Basket &&
                            state.cargoList.Basket.BasketPrice &&
                            state.cargoList.Basket.BasketPrice.USD
                            ? state.cargoList.Basket.BasketPrice.USD.Display
                            : '0,00 ₺'
                          : basket &&
                            basket.ActiveBasket &&
                            basket.ActiveBasket.BasketPrice &&
                            basket.ActiveBasket.BasketPrice.USD
                          ? basket.ActiveBasket.BasketPrice.USD.Display
                          : '0,00 ₺'}
                      </p> */}

                      <IPPriceDisplay
                        useDiv={true}
                        color='#EE333E'
                        price={
                          state.cargoList
                            ? state.cargoList.Basket &&
                              state.cargoList.Basket.BasketPrice &&
                              state.cargoList.Basket.BasketPrice.USD
                              ? state.cargoList.Basket.BasketPrice.USD.Display
                              : '0,00 ₺'
                            : basket &&
                              basket.ActiveBasket &&
                              basket.ActiveBasket.BasketPrice &&
                              basket.ActiveBasket.BasketPrice.USD
                            ? basket.ActiveBasket.BasketPrice.USD.Display
                            : '0,00 ₺'
                        }
                        integerFontSize='1.5rem'
                        fractionalFontSize='1rem'
                      />
                      {/* <p style={{ color: '#EE333E', fontSize: '1.5rem' }}>
                         {state.cargoList
                          ? state.cargoList.Basket &&
                            state.cargoList.Basket.BasketPrice &&
                            state.cargoList.Basket.BasketPrice.EUR
                            ? state.cargoList.Basket.BasketPrice.EUR.Display
                            : '0,00 ₺'
                          : basket &&
                            basket.ActiveBasket &&
                            basket.ActiveBasket.BasketPrice &&
                            basket.ActiveBasket.BasketPrice.EUR
                          ? basket.ActiveBasket.BasketPrice.EUR.Display
                          : '0,00 ₺'} 
                      </p>*/}

                      <IPPriceDisplay
                        useDiv={true}
                        color='#EE333E'
                        price={
                          state.cargoList
                            ? state.cargoList.Basket &&
                              state.cargoList.Basket.BasketPrice &&
                              state.cargoList.Basket.BasketPrice.EUR
                              ? state.cargoList.Basket.BasketPrice.EUR.Display
                              : '0,00 ₺'
                            : basket &&
                              basket.ActiveBasket &&
                              basket.ActiveBasket.BasketPrice &&
                              basket.ActiveBasket.BasketPrice.EUR
                            ? basket.ActiveBasket.BasketPrice.EUR.Display
                            : '0,00 ₺'
                        }
                        integerFontSize='1.5rem'
                        fractionalFontSize='1rem'
                      />
                    </div>
                  </div>
                  <div
                    className={`${css`
                      display: grid;
                      grid-template-columns: repeat(auto-fit, minmax(130px, 1fr));
                      gap: 0.5rem;
                      font-size: 1.3rem;
                      font-weight: 400;
                    `}`}
                  >
                    {/* <IPButton
                        text="Teklif İste"
                        isRounded={false}
                        onClick={requestOffer}
                        style={{ fontSize: "1.3rem", fontWeight: "400" }}
                      /> */}
                    {/*NOTE: YENİ SİPARİŞ VER BUTONU */}
                    {/* {!showAdressGoButton && !state.approval && !state.paymentMethod ? (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='İlerle'
                        className='ml-auto'
                        onClick={handleIlerleClick}
                      />
                    ) : showAdressGoButton && !state.approval && !state.paymentMethod ? (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='Adresi Onayla'
                        className='ml-auto'
                        onClick={approval}
                      />
                    ) : !showPaymentGoButton && state.approval && !state.paymentMethod ? (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='İlerle'
                        className='ml-auto'
                        onClick={paymentMethod}
                      />
                    ) : showPaymentGoButton && state.approval && state.paymentMethod && !showOrderButton ? (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='İlerle'
                        className='ml-auto'
                        onClick={() => {
                          const orderNoteTab = document.getElementById('order-note-tab')
                          if (orderNoteTab) {
                            orderNoteTab.click()
                            setShowOrderButton(true)
                          }
                        }}
                      />
                    ) : state.approval && state.paymentMethod && showOrderButton ? (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='Sipariş Ver'
                        disabled={!basket.ActiveBasket || basket.ActiveBasket.BasketCount === 0}
                        isRounded={false}
                        onClick={orderNow}
                      />
                    ) : (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='Sipariş Ver'
                        disabled={!basket.ActiveBasket || basket.ActiveBasket.BasketCount === 0}
                        isRounded={false}
                        onClick={orderNow}
                      />
                    )}
                    <IPButton
                      style={{ backgroundColor: '#0327ec' }}
                      text='Teklif Oluştur'
                      isRounded={false}
                      onClick={() => dispatchState({ showCreateOfferModal: true })}
                    /> */}
                    {/* NOTE: SİPARİŞ VER BUTONU END */}
                    {/* 
                      NOTE: SEPET DÜZENLEMESİ ÖNCESİ SİPARİŞ VER BUTONU
                    <IPButton
                      style={{ backgroundColor: '#fe0000' }}
                      text='Sipariş Ver'
                      disabled={!basket.ActiveBasket || basket.ActiveBasket.BasketCount === 0}
                      isRounded={false}
                      onClick={orderNow}
                    />
                    <IPButton
                      style={{ backgroundColor: '#0327ec' }}
                      text='Teklif Oluştur'
                      isRounded={false}
                      onClick={() => dispatchState({ showCreateOfferModal: true })}
                    /> */}
                    {/* NOTE: SİPARİŞ VER ve İLERLE BUTONU */}
                    {activeTab === 'products' && (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='İlerle'
                        className='ml-auto'
                        onClick={handleIlerleClick}
                      />
                    )}
                    {activeTab === 'address' && !state.approval && (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='Adresi Onayla'
                        className='ml-auto'
                        onClick={approval}
                      />
                    )}
                    {activeTab === 'address' && state.approval && (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='İlerle'
                        className='ml-auto'
                        onClick={paymentMethod}
                      />
                    )}
                    {activeTab === 'address' && state.approval && (
                      <IPButton
                        style={{ backgroundColor: '#6c757d' }}
                        text='Adresi Değiştir'
                        className='ml-auto'
                        onClick={approval}
                      />
                    )}
                    {activeTab === 'payment-method' && state.approval && (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='İlerle'
                        className='ml-auto'
                        onClick={handlePaymentMethodClick}
                      />
                    )}
                    {activeTab === 'order-note' && (
                      <IPButton
                        style={{ backgroundColor: '#fe0000' }}
                        text='Sipariş Ver'
                        disabled={!basket.ActiveBasket || basket.ActiveBasket.BasketCount === 0}
                        isRounded={false}
                        onClick={orderNow}
                      />
                    )}
                    {!(activeTab === 'address' && state.approval) && (
                      <IPButton
                        style={{ backgroundColor: '#0327ec' }}
                        text='Teklif Oluştur'
                        isRounded={false}
                        onClick={() => dispatchState({ showCreateOfferModal: true })}
                      />
                    )}
                  </div>
                  {/* {basket &&
                    basket.ActiveBasket &&
                    basket.ActiveBasket.AvailableCampModels &&
                    basket.ActiveBasket.AvailableCampModels.length > 0 && (
                      <div className='mt-3'>
                        <div
                          style={{
                            fontSize: '20px',
                            fontWeight: '500',
                          }}
                        >
                          Kampanya Seç
                        </div>
                        <div className='seperator mb-2 mt-0'></div>
                        {basket.ActiveBasket.AvailableCampModels.map((campaign: IAvailableCampModels) => (
                          <div className='row'>
                            <div className='d-flex align-items-center gap-2 pl-4'>
                              <input
                                className='white-circle-input form-check-input flex-shrink-0'
                                name='campaing'
                                id='campaing2'
                                value={campaign.Id}
                                checked={campaign.IsActive}
                                type='radio'
                                onChange={() => {
                                  if (!state.approval) {
                                    dispatchRedux(getApplyCampaignOnBasketRedux(campaign.Id))
                                  } else {
                                    Swal.fire({
                                      icon: 'question',
                                      title:
                                        'Adresi onayladığınız için kampanya seçemezsiniz. Adres onayını kaldırmak ister misiniz?',
                                      showConfirmButton: true,
                                      allowOutsideClick: false,
                                      showDenyButton: true,
                                      confirmButtonText: 'Evet',
                                      denyButtonText: 'Hayır',
                                    }).then(async result => {
                                      result.isConfirmed && window.location.reload()
                                    })
                                  }
                                }}
                              />
                              <label className='m-0 py-0 px-2' htmlFor='campaing2'>
                                {campaign.CampaignDescription}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    )} */}
                  <div className='seperator mb-2 mt-2'></div>
                  <div>
                    <form className='form-inline'>
                      <div className='input-group'>
                        <input
                          style={{
                            backgroundColor: '#fff',
                            borderRadius: '0',
                          }}
                          type='text'
                          id='CouponCode'
                          className='form-control'
                          placeholder='Kupon Kodu'
                          onChange={e => {
                            const code = e.target.value
                            dispatchState({ code: code })
                          }}
                        />
                        <div className='input-group-append'>
                          <IPButton
                            className={`${styles.discountCode}`}
                            style={{ borderColor: '#E0E0E2' }}
                            text='Ekle'
                            isRounded={false}
                            onClick={giftCodeInput}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 text-center'>
                      <div id='iyzipay-checkout-form' className='popup'></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 mt-3'>
                <div className='row'>
                  {suggestProducts && !state.approval && suggestProducts.length > 0 && (
                    <p
                      style={{
                        fontSize: '1.5rem',
                        fontWeight: '600',
                        color: '#484848',
                        marginBottom: '10px',
                        marginLeft: '5px',
                      }}
                    >
                      SEPETİNİZE ÖZEL, ÖNERİLEN ÜRÜNLER
                    </p>
                  )}
                  {suggestProducts && !state.approval && suggestProducts.length > 4 ? (
                    <Swiper
                      navigation={true}
                      keyboard={true}
                      autoplay={{
                        delay: 5000,
                        disableOnInteraction: false,
                      }}
                      pagination={{ clickable: true }}
                      observer={true}
                      observeParents={true}
                      modules={[Navigation, Mousewheel, Keyboard, Pagination, Autoplay]}
                      className='mySwiper'
                    >
                      {[...Array(Math.ceil(suggestProducts.length / 4))].map((_, slideIndex) => (
                        <SwiperSlide key={slideIndex} style={{ backgroundColor: 'transparent' }}>
                          <div className='row'>
                            {suggestProducts.slice(slideIndex * 4, (slideIndex + 1) * 4).map((item: any) => (
                              <div
                                className='col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize mb-5'
                                key={item.Id}
                                style={{ backgroundColor: '#f5f5f5' }}
                              >
                                <div
                                  className={`d-flex flex-column justify-content-start most-popular-products ${css`
                                    padding: 20px;
                                  `}`}
                                >
                                  <div className='room'>
                                    <div className='room-image text-center'>
                                      <Link to={`/product/detail/${item.Id}?from=promotional`}>
                                        {item.Image ? (
                                          <Image
                                            src={item.Image}
                                            alt=''
                                            className='popular_product'
                                            style={{ objectFit: 'contain' }}
                                          />
                                        ) : (
                                          <Image
                                            src='./images/products/Resim_yok-01.jpg'
                                            alt=''
                                            style={{ objectFit: 'contain' }}
                                          />
                                        )}
                                      </Link>
                                    </div>
                                  </div>
                                  <div className='product-description'>
                                    <Link to={`/product/detail/${item.Id}?from=promotional`}>
                                      <div className='product-title  text-left'>{item.Name}</div>
                                    </Link>
                                    <div className='product-title text-left'>Ürün Kodu: {item.ProductCode}</div>
                                  </div>
                                  <hr />
                                  <div className='row align-items-center product-bottom-fixed justify-content-between '>
                                    <div className='col-5 p-0 '>
                                      {item.BrandDetail?.ImageUrl && (
                                        <Link to={`/products?brandId=${item.BrandId}`}>
                                          <Image
                                            src={item.BrandDetail?.ImageUrl}
                                            alt=''
                                            className='popular_product'
                                            style={{ width: '50px' }}
                                          />
                                        </Link>
                                      )}
                                    </div>
                                    <div className='col-7'>
                                      <div className='d-flex flex-column align-items-end'>
                                        {item.CampaignPrice?.CampaignPriceDisplay ? (
                                          <>
                                            <del
                                              className={css`
                                                color: #b3b3b3;
                                                font-size: 19px;
                                              `}
                                            >
                                              {item.Prices?.Special.Price ?? ''}
                                            </del>

                                            <IPPriceDisplay
                                              useDiv={true}
                                              price={item.CampaignPrice?.CampaignPriceDisplay}
                                              integerFontSize='19px'
                                              fractionalFontSize='12px'
                                            />
                                          </>
                                        ) : (
                                          // <div
                                          //   className={css`
                                          //     font-size: 19px;
                                          //   `}
                                          // >
                                          //   {item.Prices?.Special.Price ?? ''}
                                          // </div>
                                          <IPPriceDisplay
                                            useDiv={true}
                                            price={item.Prices?.Special.Price ?? ''}
                                            integerFontSize='19px'
                                            fractionalFontSize='12px'
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className='d-flex align-items-end justify-content-between'>
                                    <div>
                                      {/* Adet:{' '} */}
                                      <IPInput
                                        type='number'
                                        className={`stockNumber myCardStockNumber ${css`
                                          height: 44px;
                                        `}`}
                                        min={1}
                                        controlledField
                                        value={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                        onChange={e => handleQuantityChange(item.Id, parseInt(e.target.value))} // Miktar değiştiğinde handleQuantityChange fonksiyonunu çağırın
                                        onBlur={e => handleQuantityOnBlur(e)}
                                      />
                                    </div>
                                    <div className='productButton'>
                                      <AddToBasket
                                        product={item}
                                        quantity={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                        className={css`
                                          font-size: 14px;
                                          height: 44px;
                                        `}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  ) : (
                    suggestProducts &&
                    !state.approval &&
                    suggestProducts.map(
                      (item: any, index) =>
                        index < 4 && (
                          <div className={`col-12 col-sm-6 col-md-4 col-lg-3 card-mobile-resize`} key={item.Id}>
                            <div
                              className={`d-flex flex-column justify-content-start most-popular-products ${css`
                                padding: 20px;
                              `}`}
                            >
                              <div className='room'>
                                <div className='room-image text-center'>
                                  <Link to={`/product/detail/${item.Id}?from=promotional`}>
                                    {item.Image ? (
                                      <Image
                                        src={item.Image}
                                        alt=''
                                        className='popular_product'
                                        style={{ objectFit: 'contain' }}
                                      />
                                    ) : (
                                      <Image
                                        src='./images/products/Resim_yok-01.jpg'
                                        alt=''
                                        style={{ objectFit: 'contain' }}
                                      />
                                    )}
                                  </Link>
                                </div>
                              </div>
                              <div className='product-description'>
                                <Link to={`/product/detail/${item.Id}?from=promotional`}>
                                  <div className='product-title  text-left'>{item.Name}</div>
                                </Link>
                                <div className='product-title text-left'>Ürün Kodu: {item.ProductCode}</div>
                              </div>
                              <hr />
                              <div className='row align-items-center product-bottom-fixed justify-content-between '>
                                <div className='col-5 p-0 '>
                                  {item.BrandDetail?.ImageUrl && (
                                    <Link to={`/products?brandId=${item.BrandId}`}>
                                      <Image
                                        src={item.BrandDetail?.ImageUrl}
                                        alt=''
                                        className='popular_product'
                                        style={{ width: '50px' }}
                                      />
                                    </Link>
                                  )}
                                </div>
                                <div className='col-7 text-right mt-2 mb-1'>
                                  {item.CampaignPrice?.CampaignPriceDisplay
                                    ? item.CampaignPrice?.CampaignPriceDisplay
                                    : item.Prices?.Special.Price ?? ''}
                                </div>
                              </div>

                              <div className='d-flex align-items-end justify-content-between'>
                                <div>
                                  {/* Adet:{' '} */}
                                  <IPInput
                                    type='number'
                                    className={`stockNumber myCardStockNumber ${css`
                                      height: 44px;
                                    `}`}
                                    min={1}
                                    controlledField
                                    value={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                    onChange={e => handleQuantityChange(item.Id, parseInt(e.target.value))} // Miktar değiştiğinde handleQuantityChange fonksiyonunu çağırın
                                    onBlur={e => handleQuantityOnBlur(e)}
                                  />
                                </div>
                                <div className='productButton'>
                                  <AddToBasket
                                    product={item}
                                    quantity={suggestQuantities[item.Id] || 1} // Ürün ID'sine göre miktarı alın
                                    className={css`
                                      font-size: 14px;
                                      height: 44px;
                                    `}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* <div className='breadcrumb m-b-40 px-0'>
            <h3 style={{ color: '#00A843', fontSize: '1.9rem' }}>Sepetinize özel fırsat!</h3>
          </div>
          <div className='homePage-header m-b-40'>
            <Swiper
              spaceBetween={15}
              loop={true}
              navigation={{
                prevEl: '.customLeftArrow',
                nextEl: '.customRightArrow',
              }}
              modules={[Pagination, Navigation]}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                640: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 5,
                },
              }}
              className='mySwiper my-3'
            >
              {data.company.map(img => (
                <SwiperSlide key={img.id}>
                  <div
                    className='first-products-banner text-center'
                    style={{ boxShadow: 'none', border: '1px solid #C4C4C4' }}
                  >
                    <div className='room'>
                      <div className='row align-items-center justify-content-between'>
                        <div className='col-auto'>
                          <span style={{ fontSize: '1.5rem', color: '#0FBDE0' }}>Aksesuar</span>
                        </div>
                        <div className='col-auto float-right'>
                          <img src='/images/mycardLogo.png' alt='' />
                        </div>
                      </div>
                      <div className='room-image text-center'>
                        <Link to='/product/detail/3'>
                          <img src='/images/products/product3.png' alt='#' className='' />
                        </Link>
                      </div>
                      <div className='product-description m-t-10 text-left'>
                        <Link to='/product/detail/3'>
                          <div className='product-title text-black' style={{ WebkitLineClamp: '2' }}>
                            Anker PowerLine Select + Apple Lightning 0.9m Naylon...
                          </div>
                          <div className='product-title m-t-10 m-b-10'>
                            <span style={{ color: '#616161', fontSize: '1rem' }}>Ürün kodu: 11234-6880</span>
                            <span style={{ color: '#616161', fontSize: '1rem' }} className='float-right'>
                              50 +
                            </span>
                          </div>
                          <ins>
                            <span style={{ color: '#EE333E', fontWeight: '500' }}>FY</span>
                          </ins>
                        </Link>
                        <div className='productButton m-t-20'>
                          <button
                            type='button'
                            className='IPButton_Button__GWRch background-white px-2'
                            style={{
                              border: '1px solid #C5C5C5',
                              color: '#C6C6C6',
                            }}
                          >
                            Sepete Ekle
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div className='customLeftArrow' style={{ top: 'auto', bottom: '18%', left: '7%' }}></div>
            <div className='customRightArrow' style={{ top: 'auto', bottom: '18%', right: '7%' }}></div>
          </div> */}
        </div>
      </section>
      {basket.ActiveBasket && basket.ActiveBasket.SuggestProductList && (
        <section
          className='section-back-grey background-white py-5 p-l-40 p-r-40 text-left '
          style={{ marginBottom: '10px' }}
        >
          {' '}
          <div className='container'>
            <h4 className='section-back-grey-title'>Benzer Ürünler </h4>
            <div className='seperator m-0'></div>
            <div className='homePage-header m-b-40'>
              <Swiper
                spaceBetween={30}
                loop={false}
                navigation={{
                  prevEl: '.customLeftArrow',
                  nextEl: '.customRightArrow',
                }}
                modules={[Pagination, Navigation]}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                  },
                  640: {
                    slidesPerView: 2,
                  },
                  768: {
                    slidesPerView: 3,
                  },
                  1200: {
                    slidesPerView: 4,
                  },
                }}
                className='mySwiper my-3 morePadProduct'
              >
                {basket.ActiveBasket.SuggestProductList.map((basketDetail: IBasketDetail) => (
                  <SwiperSlide key={basketDetail.Product.Id}>
                    <ProductCard4
                      key={basketDetail.Product.Id}
                      product={basketDetail.Product}
                      campaignPrice={basketDetail.CampaignBasketDetailPrice?.Normal}
                      // onFavoriteAdd={onFavoriteAdd}
                      // onFavoriteRemove={onFavoriteRemove}
                      // isFavoriteProduct={
                      //   state.favoriteProductIds.indexOf(product.Id) > -1
                      // }
                      // onFavoriteAdd={onFavoriteAdd}
                      // onFavoriteRemove={onFavoriteRemove}
                      // isFavoriteProduct={
                      //   state.favoriteProductIds.indexOf(product.Id) > -1
                      // }
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='customLeftArrow' style={{ top: '50%', left: '9rem' }}></div>
              <div className='customRightArrow' style={{ top: '50%', right: '9rem' }}></div>
            </div>
          </div>
        </section>
      )}

      <ModalAddress
        title={state.adresModalName}
        width={700}
        show={state.showAddressModal}
        onClose={() => {
          const reqAdres: AddAddressRequest = {
            Id: 0,
            NameAndSurname: '',
            Country: '',
            City: '',
            District: '',
            PostCode: '',
            SevkCode: '',
            Phone: '',
            Email: '',
            AddressDetail: '',
            AddressDetail2: '',
            AddressTitle: '',
            Unvan: '',
            Neighbourhood: '',
            isLicense: 0,
          }
          return dispatchState({
            selectedLicenseAddress: undefined,
            showAddressModal: false,
            addAddressParams: reqAdres,
            towns: [],
            town: null,
            district: null,
            city: null,
            districts: [],
            postCode: '',
            SevkCode: '',
            country: CountryOptions[0],
          })
        }}
        onConfirm={addAddress}
      >
        <form className='p-3'>
          <div className='form-row'>
            {state.adresModalName === 'Lisans' ? (
              <div className='form-group col-md-6 align-items-center '>
                <label className='text-left'>Kayıtlı Adreslerim</label>
                <IPSelectBox
                  options={
                    state.addresses
                      ? state.addresses?.LicenseAddressList?.map((address: IAddressModel) => {
                          return {
                            value: `${address.Id}`,
                            label: (
                              <div
                                onMouseEnter={e => handleAddressTooltip(e, address)}
                                onMouseLeave={() =>
                                  setAddressTooltip(prev => {
                                    return {
                                      ...prev,
                                      show: false,
                                    }
                                  })
                                }
                                onClick={() =>
                                  setAddressTooltip(prev => {
                                    return {
                                      ...prev,
                                      show: false,
                                    }
                                  })
                                }
                              >
                                {address.AddressTitle ? address.AddressTitle : address.AddressDetail}
                              </div>
                            ),
                          }
                        })
                      : []
                  }
                  onChangeSingle={async (value: ISelectOption) => {
                    // Call the handleAddressSelection function when an address is selected
                    await handleAddressSelection(value)
                  }}
                  value={state.selectedLicenseAddress}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6  align-items-center'>
              <label className=' text-sm-left'>* Adı Soyadı / Ünvan</label>
              <IPInput
                type='text'
                className='form-control'
                name='NameAndSurname'
                required
                value={state.addAddressParams.NameAndSurname}
                onChange={e => {
                  const newParams = state.addAddressParams
                  newParams.NameAndSurname = e.target.value

                  dispatchState({ addAddressParams: newParams })
                }}
              />
            </div>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>Yetkili İsmi</label>
              <IPInput
                type='text'
                className='form-control'
                name='Unvan'
                value={state.addAddressParams.Unvan}
                required
                onChange={e => {
                  const newParams = state.addAddressParams
                  newParams.Unvan = e.target.value
                  dispatchState({ addAddressParams: newParams })
                }}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>* E-Mail</label>
              <IPInput
                type='text'
                className='form-control'
                name='Email'
                id='Email'
                value={state.addAddressParams.Email}
                required
                onChange={e => {
                  const newParams = state.addAddressParams
                  newParams.Email = e.target.value
                  dispatchState({ addAddressParams: newParams })
                }}
              />
            </div>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>* Telefon</label>
              <InputMask
                mask='(999) 999 99 99'
                name='Phone'
                type='text'
                required
                value={state.addAddressParams.Phone}
                className='form-control'
                onChange={e => {
                  const newParams = state.addAddressParams
                  newParams.Phone = e.target.value
                  dispatchState({ addAddressParams: newParams })
                }}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>* Ülke</label>
              <IPSelectBox
                options={CountryOptions}
                value={CountryOptions[0]}
                onChangeSingle={option => {
                  getTown(Number(option.value))
                  const newParams = state.addAddressParams
                  newParams.Country = `${option.label}`
                  dispatchState({
                    addAddressParams: newParams,
                    country: option,
                  })
                }}
              />
            </div>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>* İl</label>
              <IPSelectBox
                options={state.cities}
                value={state.city}
                onChangeSingle={option => {
                  getTown(Number(option.value))
                  const newParams = state.addAddressParams
                  newParams.City = `${option.label}`
                  newParams.District = ''
                  dispatchState({
                    addAddressParams: newParams,
                    city: option,
                    town: null,
                    district: null,
                    districts: [],
                    postCode: '',
                    SevkCode: '',
                  })
                }}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>* İlçe</label>
              <IPSelectBox
                options={state.towns}
                value={state.town}
                onChangeSingle={option => {
                  getDistrict(Number(option.value))
                  const newParams = state.addAddressParams
                  newParams.District = `${option.label}`
                  dispatchState({
                    addAddressParams: newParams,
                    town: option,
                    district: null,
                    postCode: '',
                    SevkCode: '',
                  })
                }}
              />
            </div>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>* Mahalle</label>
              <IPSelectBox
                options={state.districts}
                value={state.district}
                onChangeSingle={option => {
                  getNeighborhood(Number(option.value))

                  const newParams = state.addAddressParams
                  newParams.Neighbourhood = `${option.label}`
                  dispatchState({
                    addAddressParams: newParams,
                    district: option,
                  })
                }}
              />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-group col-md-3 align-items-center '>
              <label className='text-sm-left'>* Posta Kodu</label>
              <IPInput type='text' className='form-control' name='PostCode' disabled required value={state.postCode} />
            </div>
            <div className='form-group col-md-3 align-items-center '>
              <label className='text-sm-left'>* Sevk Kodu</label>
              <IPInput type='text' className='form-control' name='SevkCode' disabled required value={state.SevkCode} />
            </div>
            <div className='form-group col-md-6 align-items-center '>
              <label className='text-sm-left'>* Adres Başlığı</label>
              <IPInput
                type='text'
                className='form-control'
                name='AddressTitle'
                value={state.addAddressParams.AddressTitle}
                required
                onChange={e => {
                  const newParams = state.addAddressParams
                  newParams.AddressTitle = e.target.value
                  dispatchState({ addAddressParams: newParams })
                }}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-12 align-items-center '>
              <label className='text-sm-left'>* Adres</label>
              <IPTextarea
                className='form-control'
                name='AddressDetail'
                required
                maxLength={40}
                value={state.addAddressParams.AddressDetail}
                onChange={e => {
                  const newParams = state.addAddressParams
                  newParams.AddressDetail = e.target.value
                  dispatchState({ addAddressParams: newParams })
                }}
              />
            </div>
          </div>
          <div className='form-row'>
            <div className='form-group col-md-12 align-items-center '>
              <label className='text-sm-left'>Adres 2</label>
              <IPTextarea
                className='form-control'
                name='AddressDetail2'
                required
                maxLength={40}
                value={state.addAddressParams.AddressDetail2}
                onChange={e => {
                  const newParams = state.addAddressParams
                  newParams.AddressDetail2 = e.target.value
                  dispatchState({ addAddressParams: newParams })
                }}
              />
            </div>
          </div>
        </form>
      </ModalAddress>

      <Modal
        title={'Teklif Oluşturma'}
        width={1200}
        show={state.showCreateOfferModal}
        onClose={() =>
          dispatchState({
            showCreateOfferModal: false,
            createOfferCurrencyType: CurrencyType.TL,
            createOfferPriceType: PriceType.Normal,
            createOfferPercentage: 0,
            calculatedOffer: undefined,
          })
        }
        onConfirm={saveOffer}
        confirmText={'Teklifi Kaydet'}
      >
        <div className='p-3' style={{ padding: '0 0 8rem' }}>
          <div className='form-row'>
            <h5>Sepetimdeki seçili ürünler için;</h5>
          </div>

          <div className='form-row mt-2'>
            <div className='form-group col-md-2 align-items-center'>
              <IPRadio
                name={'percentage'}
                checked={state.createOfferPriceType === PriceType.Special}
                text={'Özel Fiyatıma'}
                onChange={() => dispatchState({ createOfferPriceType: PriceType.Special })}
              />
            </div>
            <div className='form-group col-md-2 align-items-center'>
              <IPRadio
                name={'percentage'}
                checked={state.createOfferPriceType === PriceType.Normal}
                text={'Bayi Fiyatına'}
                onChange={() => dispatchState({ createOfferPriceType: PriceType.Normal })}
              />
            </div>
            <div className='form-group col-md-1 align-items-center'>
              <InputMask
                mask='% 999'
                type='text'
                placeholder='%'
                className='form-control'
                name='percentageNumber'
                required
                value={state.createOfferPercentage}
                onChange={e => {
                  const val = e.target.value.replaceAll('%', '').replaceAll('_', '').trim()

                  dispatchState({
                    createOfferPercentage:
                      !val || isNaN(Number(val)) ? '' : Number(val) > 100 ? 100 : Number(val) < 0 ? 0 : Number(val),
                  })
                }}
              />
            </div>
            <div className='form-group col-md-1 mt-3 align-items-center'>
              <label className='text-sm-left'>Ekle</label>
            </div>
          </div>

          <div className='form-row'>
            <div className='form-group col-md-6 align-items-center'>
              <IPRadio
                name={'percentage'}
                checked={state.createOfferPriceType === PriceType.EndUser}
                text={'Tavsiye Edilen Son Kullanıcı (TESK) Kullan'}
                onChange={() => dispatchState({ createOfferPriceType: PriceType.EndUser })}
              />
            </div>
          </div>

          <div className='form-row'>
            <div className='form-group col-md-3 align-items-center'>
              <label className='sr-only-size'>Fiyatların gösterileceği döviz tipi</label>
              <IPSelectBox
                name='currencyType'
                value={currencyTypes.filter(
                  (cType: any) => Number(cType.value) === Number(state.createOfferCurrencyType)
                )}
                options={currencyTypes}
                onChangeSingle={(newVal: ISelectOption) => {
                  dispatchState({
                    createOfferCurrencyType: Number(newVal.value),
                  })
                }}
              />
            </div>
          </div>

          <div className='row align-items-center' style={{ marginBottom: '70px' }}>
            <div className='form-group col-md-2 align-items-center'>
              <IPButton name='createOfferInModal' onClick={createOffer} text='Teklif Oluştur' />
            </div>
            <div className='form-group col-md-2 align-items-center '>
              <IPButton
                name='offerSendMailModal'
                onClick={() => {
                  if (state.createOfferBool) return dispatchState({ showSendMailModal: true })
                  Swal.fire('Hata', 'Lütfen önce teklif oluşturunuz', 'error')
                }}
                text='Teklif Gönder'
              />
            </div>
            <div className='form-group col-md-2 align-items-center '>
              <IPButton
                name='printInModal'
                onClick={() => {
                  if (!state.createOfferBool) return Swal.fire('Hata', 'Lütfen önce teklif oluşturunuz', 'error')
                  printElement(document.getElementById('offer-toPrint'))
                }}
                text='Yazdır'
              />
            </div>
            <div className='form-group col-md-2 align-items-center '>
              <IPButton
                name='printInModal'
                onClick={() => {
                  if (!state.createOfferBool) return Swal.fire('Hata', 'Lütfen önce teklif oluşturunuz', 'error')
                  excelToOffer()
                }}
                text='Excele Aktar'
              />
            </div>
          </div>

          {state.calculatedOffer && state.calculatedOffer.Products && state.calculatedOffer.Products.length > 0 && (
            <div className='row align-items-center mt-3' id='offer-toPrint'>
              <table className='table table-borderless table-hover'>
                <thead>
                  <tr className='EInvoicetableDescription'>
                    <td scope='col' style={{ padding: '10px' }}>
                      Ürün kodu
                    </td>
                    <td scope='col' style={{ padding: '10px' }}>
                      Ürün Adı
                    </td>
                    <td scope='col' style={{ padding: '10px' }}>
                      Adet
                    </td>
                    <td scope='col' style={{ padding: '10px' }}>
                      Birim Fiyat
                    </td>
                    <td scope='col' style={{ padding: '10px' }}>
                      Toplam Fiyat
                    </td>
                  </tr>
                </thead>
                <tbody>
                  {state.calculatedOffer &&
                    state.calculatedOffer.Products &&
                    state.calculatedOffer.Products.map((prod: ICreateOfferProduct) => {
                      return (
                        <tr key={prod.ProductId}>
                          <td scope='col' style={{ padding: '10px' }}>
                            {prod.Product.ProductCode}
                          </td>
                          <td scope='col' style={{ padding: '10px' }}>
                            {prod.Product.Name}
                          </td>
                          <td scope='col' style={{ padding: '10px' }}>
                            {prod.Quantity}
                          </td>
                          <td scope='col' style={{ padding: '10px' }}>
                            {prod.UnitPrice && prod.UnitPrice}
                          </td>
                          <td scope='col' style={{ padding: '10px' }}>
                            {prod.TotalPrice && prod.TotalPrice}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </Modal>
      <Modal
        title={'Hediye Ürün Seçimi'}
        width={1200}
        show={state.showChangeProduct}
        onClose={() =>
          dispatchState({
            showChangeProduct: false,
          })
        }
        onConfirm={SingleCampaignProductReplace}
        confirmText={'Onayla'}
      >
        <div>
          {basket &&
            basket.ActiveBasket &&
            basket.ActiveBasket.SingleCampaignItems &&
            basket.ActiveBasket.SingleCampaignItems.map((bItem: IBasketDetail) => {
              if (bItem.CampaignInfoModel.CampaignBundleID === state.selectedSingleCampaignId) {
                return (
                  <div
                    className='productCard productCard2'
                    style={{
                      minHeight: '150px',
                    }}
                  >
                    <div className='indicator topLeft'>
                      {' '}
                      <img src='/images/products/discount.svg' alt='' />
                    </div>
                    {/* <div className="indicator topRight">-10%</div> */}

                    <IPRadio
                      text=''
                      name=''
                      checked={
                        state.SingleCampaignProductCheckbox === bItem.ProductID &&
                        state.SingleCampaignProductPrice === bItem.CampaignBasketDetailPrice?.Normal &&
                        true
                      }
                      onChange={() => SingleCampaignProduct(bItem, false)}
                    />

                    <div className='productImage' style={{ margin: 'inherit', overflow: 'inherit' }}>
                      <img src={bItem.Product.Image} alt='' />
                    </div>
                    {bItem.Product.BrandLogo && (
                      <img
                        src={createImageUrl(bItem.Product.BrandLogo)}
                        alt=''
                        style={{
                          width: '30px',
                          border: '1px solid #C4C4C4',
                          borderRadius: '50%',
                        }}
                      />
                    )}
                    <div
                      style={{
                        maxWidth: '500px',
                        width: '100%',
                      }}
                    >
                      <div className='productName'>{bItem.Product.Name}</div>
                      <div className='productCode d-flex justify-content-between'>
                        <div>
                          <span>
                            Ürün kodu: &nbsp;
                            {bItem.Product.ProductCode}
                          </span>
                        </div>
                        <div className='d-flex gap-2 pr-2'>
                          <span>
                            <img src='/images/products/truck.svg' alt='' width={25} />
                          </span>
                          <span>
                            <img src='/images/products/quality2.png' width={16} height={18} alt='' />
                          </span>
                          <div
                            style={{
                              background: 'rgb(196, 196, 196)',
                              border: '1px solid #0FBDE0',
                              transform: 'rotate(-90deg)',
                              borderRadius: '50%',
                              width: '1.6rem',
                              height: '1.6rem',
                            }}
                          ></div>
                        </div>
                      </div>
                      {bItem.CampaignInfoModel &&
                        bItem.CampaignInfoModel.IsCampaign &&
                        bItem.CampaignInfoModel.CampaignDescription && (
                          <div
                            className='productCode'
                            style={{
                              color: 'red',
                              marginTop: '5px',
                              height: '40px',
                              width: '380px',
                            }}
                          >
                            <span
                              style={{
                                whiteSpace: 'nowrap',
                                lineHeight: '2rem',
                              }}
                            >
                              {bItem.CampaignInfoModel?.CampaignDescription}
                            </span>
                          </div>
                        )}
                    </div>

                    {bItem.Product?.Stock?.Stock && (
                      <div className='stock m-t-5'>
                        <span>{stockIndicator(bItem.Product?.Stock?.Stock)}</span>
                      </div>
                    )}

                    <div className='productPrices flex-row' style={{ width: '120px' }}>
                      <div className='d-flex flex-column align-items-center'>
                        {/* <span className="price2">
                                            10,000,00 TL
                                          </span> */}
                        <del className='price2' style={{ color: '#ABABAF', fontSize: '13px' }}>
                          {bItem.BasketDetailPrice
                            ? bItem.BasketDetailPrice?.SpecialDisplay
                            : bItem.Product?.Prices?.Normal?.Price}
                        </del>
                        {/* <span
                          className='price2'
                          style={{
                            color: '#ABABAF',
                            fontSize: '13px',
                          }}
                        >
                          {bItem.BasketDetailPrice
                            ? bItem.BasketDetailPrice?.SpecialDisplay
                            : bItem.Product?.Prices?.Normal?.Price}
                        </span> */}
                        <span
                          style={{
                            color: '#EE333E',
                            fontSize: '1.4rem',
                          }}
                        >
                          {bItem.CampaignInfoModel &&
                          bItem.CampaignInfoModel.IsCampaign &&
                          bItem.CampaignInfoModel.CampaignDescription
                            ? bItem.CampaignBasketDetailPrice?.SpecialDisplay
                            : bItem.BasketDetailPrice
                            ? bItem.BasketDetailPrice?.SpecialDisplay
                            : bItem.Product?.Prices.Normal?.Price}
                        </span>
                      </div>
                    </div>
                  </div>
                )
              }
              return null
            })}
        </div>
      </Modal>

      {/* Yazdırılacak tablo */}
      <div className='d-none' id='orderForPrint'>
        <div className='row m-5 p-5' style={{ textAlign: 'center' }}>
          <h4>Siparişiniz Başarıyla Kaydolmuştur!</h4>
          <br />
          <h4>SİPARİŞ SEVK ADRES BİLGİLERİ</h4>
          <div className='table-responsive'>
            <table
              style={{
                borderCollapse: 'collapse',
                width: '100%',
                border: '1px solid #000000',
              }}
            >
              {state.orderDetails.IyzicoOrderNumber && (
                <tr style={{ border: '1px solid #000000' }}>
                  <td
                    style={{
                      border: '1px solid #000000',
                      backgroundColor: '#f0f0f0',
                      padding: '10px',
                      width: '100px',
                    }}
                  >
                    <b style={{ fontWeight: '600' }}>Iyzico Sipariş No</b>
                  </td>
                  <td style={{ border: '1px solid #000000', padding: '10px' }}>
                    <span className='mr-2'></span>
                    {state.orderDetails.IyzicoOrderNumber}
                  </td>
                </tr>
              )}
              <tr style={{ border: '1px solid #000000' }}>
                <td
                  className='headerEven'
                  style={{
                    border: '1px solid #000000',
                    backgroundColor: '#ccc',
                    padding: '10px',
                    width: '100px',
                  }}
                >
                  <b style={{ fontWeight: '600' }}>Sipariş No</b>
                </td>
                <td style={{ border: '1px solid #000000', padding: '10px' }}>
                  <span className='mr-2'></span>
                  {state.orderDetails.OrderNumber}
                </td>
              </tr>
              <tr style={{ border: '1px solid #000000' }}>
                <td
                  className='headerOdd'
                  style={{
                    border: '1px solid #000000',
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    width: '100px',
                  }}
                >
                  <b style={{ fontWeight: '600' }}>Firma Adı</b>
                </td>
                <td style={{ border: '1px solid #000000', padding: '10px' }}>
                  <span className='mr-2'></span>
                  {state.orderDetails.DealerName}
                </td>
              </tr>
              <tr style={{ border: '1px solid #000000' }}>
                <td
                  className='headerOdd'
                  style={{
                    border: '1px solid #000000',
                    backgroundColor: '#ccc',
                    padding: '10px',
                    width: '100px',
                  }}
                >
                  <b style={{ fontWeight: '600' }}>Adres</b>
                </td>
                <td style={{ border: '1px solid #000000', padding: '10px' }}>
                  <span className='mr-2'></span>
                  {state.orderDetails.Address}
                </td>
              </tr>
              <tr style={{ border: '1px solid #000000' }}>
                <td
                  className='headerOdd'
                  style={{
                    border: '1px solid #000000',
                    backgroundColor: '#f0f0f0',
                    padding: '10px',
                    width: '100px',
                  }}
                >
                  <b style={{ fontWeight: '600' }}>İl/İlçe</b>
                </td>
                <td style={{ border: '1px solid #000000', padding: '10px' }}>
                  <span className='mr-2'></span>
                  {state.orderDetails.City + '/' + state.orderDetails.District}
                </td>
              </tr>
            </table>
          </div>

          <h4 style={{ marginTop: '15px' }}>SİPARİŞ DETAYI</h4>
          {state.orderDetails.OrderReturnCompanyList.map((order, i) => (
            <div className='table-responsive'>
              <div className='row order-detail' key={i}>
                <div className='table-responsive col-12'>
                  <table
                    className='table table-borderless table-hover m-t-5'
                    style={{ borderCollapse: 'collapse', width: '100%' }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Şirket</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          {(() => {
                            const companyId = order.Company
                            let companyName = ''

                            switch (companyId) {
                              case '1000':
                                companyName = 'INDEX'
                                break
                              case '1100':
                                companyName = 'DATAGATE'
                                break
                              case '1200':
                                companyName = 'DESPEC'
                                break
                              case '2300':
                                companyName = 'NETEX'
                                break
                              default:
                                break
                            }
                            return companyName
                          })()}
                        </td>

                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Kur Tipi</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{order.CurrencyType}</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Kur</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{order.CurrencyValue}</td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Sipariş No</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{order.OrderNumber}</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Tarih</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          {state.orderDetails.CreatedDate
                            ? new Date(state.orderDetails.CreatedDate).toLocaleDateString('tr-TR')
                            : '-'}
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>MT</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{order.MT}</td>
                      </tr>
                      <tr>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Ödeme Şekli</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{order.PaymentMethod}</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Sevk Tarihi</b>
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          {state.orderDetails.ShipmentDate
                            ? new Date(state.orderDetails.ShipmentDate).toLocaleDateString('tr-TR')
                            : '-'}
                        </td>

                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Sevk Biçimi</b>
                        </td>

                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          {state.orderDetails.ShipmentType}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div className='row'>
                <div className='table-responsive'>
                  <table className='print-table table table-borderless table-hover m-t-5'>
                    <thead>
                      <tr className='EInvoicetableDescription' style={{ border: '1px solid #000000', padding: '8px' }}>
                        {order.Items[0].IyzicoNumber && <td>Iyzico No</td>}
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>Depo Kodu</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>Mal Kodu</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>Açıklama</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>Mik</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>Fiyat</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          Fiyat({order.Items[0].Currency === 0 ? '₺' : order.Items[0].Currency === 1 ? '$' : '€'})
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>Tutar</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          Tutar({order.Items[0].Currency === 0 ? '₺' : order.Items[0].Currency === 1 ? '$' : '€'})
                        </td>
                      </tr>
                    </thead>
                    {order.Items.map((item, index) => (
                      <tbody style={{ border: '1px solid #000000', padding: '8px' }} key={index}>
                        {order.Items[0].IyzicoNumber && (
                          <td style={{ border: '1px solid #000000', padding: '8px' }}>{item.IyzicoNumber}</td>
                        )}
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{item.Warehouse}</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{item.ProductCode}</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{item.ProductName}</td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>{item.Quantity}</td>
                        <td style={{ border: '1px solid #000000', padding: '8px', whiteSpace: 'nowrap' }}>
                          {item.Price1}
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px', whiteSpace: 'nowrap' }}>
                          {item.Price2}
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px', whiteSpace: 'nowrap' }}>
                          {item.TotalPrice1}
                        </td>
                        <td style={{ border: '1px solid #000000', padding: '8px', whiteSpace: 'nowrap' }}>
                          {item.TotalPrice2}
                        </td>
                      </tbody>
                    ))}
                    <tbody className='totalArea'>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td> </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tbody>

                    <div className='totalArea2'>
                      <tbody>
                        {order.Items[0].IyzicoNumber && <td></td>}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Toplam:</b>
                        </td>
                        <td
                          style={{
                            border: '1px solid #000000',
                            padding: '8px',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {order.TotalPrice}
                        </td>
                      </tbody>
                      <tbody>
                        {order.Items[0].IyzicoNumber && <td></td>}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Kargo:</b>
                        </td>
                        <td
                          style={{
                            border: '1px solid #000000',
                            padding: '8px',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {order.Cargo}
                        </td>
                      </tbody>
                      <tbody>
                        {order.Items[0].IyzicoNumber && <td></td>}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>KDV:</b>
                        </td>
                        <td
                          style={{
                            border: '1px solid #000000',
                            padding: '8px',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {order.TotalKdv}
                        </td>
                      </tbody>
                      <tbody>
                        {order.Items[0].IyzicoNumber && <td></td>}
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td style={{ border: '1px solid #000000', padding: '8px' }}>
                          <b>Genel Toplam:</b>
                        </td>
                        <td
                          style={{
                            border: '1px solid #000000',
                            padding: '8px',
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                          }}
                        >
                          {order.Total}
                        </td>
                      </tbody>
                    </div>
                  </table>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <Modal
        show={state.showOrderDetails}
        onClose={() => {
          dispatchState({ showOrderDetails: false })
          props.getFreshBasket()
          if (props.user.IsPermission || props.user.IsMonitorSalesAndOrder) return navigate(`/myspecial/myorders`)
          window.location.reload()
        }}
        hideConfirm={true}
        maxWidth={1200}
      >
        <div id='order' className='modal-content'>
          {/* <div className='form-row' style={{ justifyContent: 'center' }}>
            <div className='form-group'>
              <i
                className='icon-check'
                style={{
                  color: 'green',
                  fontSize: '50px',
                  marginLeft: '160px',
                }}
              ></i>
              <h4>Siparişiniz Başarıyla Kaydolmuştur!</h4>
            </div>
          </div> */}
          <div className='form-row' style={{ justifyContent: 'center', position: 'relative' }}>
            <div className='col-md-3 text-right' style={{ position: 'absolute', top: 5, right: 5 }}>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatchState({ showMail: true })
                }}
                className='p-6'
              >
                <img
                  style={{ marginTop: '-5px' }}
                  src='/images/icons/epostadetay.svg'
                  alt=''
                  width={40}
                  height={40}
                  title='Mail Gönder'
                />
              </a>
              <a
                onClick={() => {
                  printElementOrderRecord(document.getElementById('orderForPrint'))
                }}
                className='p-3'
              >
                <img
                  src='/images/icons/yazicidetay.svg'
                  alt=''
                  width={40}
                  height={40}
                  style={{ cursor: 'pointer' }}
                  title='Yazdır'
                />
              </a>

              <a onClick={postOrderExcel} className='p-3'>
                <img
                  src='/images/icons/excel.png'
                  alt=''
                  width={30}
                  height={30}
                  style={{ cursor: 'pointer' }}
                  title="Excel'e Aktar"
                />
              </a>
            </div>

            <div className='form-group'>
              <i
                className='icon-check'
                style={{
                  color: 'green',
                  fontSize: '50px',
                  marginLeft: '160px',
                }}
              ></i>
              <h4>Siparişiniz Başarıyla Kaydolmuştur!</h4>
            </div>
          </div>

          {state.errorMessage &&
            state.errorMessage.length > 0 &&
            state.errorMessage.map((error, index) => (
              <div key={index}>
                <h3 style={{ color: 'red' }}>{error}</h3> <br />{' '}
              </div>
            ))}

          <p
            style={{
              marginTop: '10px',
              fontSize: '17px',
              color: 'black',
              fontWeight: '600',
              marginLeft: '20px',
            }}
          >
            SİPARİŞ SEVK ADRES BİLGİLERİ
          </p>
          <div
            style={{
              //border: '1px solid #000000',
              backgroundColor: 'white',
              padding: '20px',
            }}
          >
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '100px' }}>Firma Adı</b>
              <span className='mr-2'>:</span>
              {state.orderDetails.DealerName}
            </div>
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '100px' }}>Sipariş No</b>
              <span className='mr-2'>:</span>
              {state.orderDetails.OrderNumber}
            </div>
            {state.orderDetails.IyzicoOrderNumber && (
              <div className='d-flex align-items-center justify-content-start'>
                <b style={{ width: '100px' }}>Iyzico Sipariş No</b>
                <span className='mr-2'>:</span>
                {state.orderDetails.IyzicoOrderNumber}
              </div>
            )}
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '100px' }}>İl/İlçe</b>
              <span className='mr-2'>:</span>
              {state.orderDetails.City + '/' + state.orderDetails.District}
            </div>
            <div className='d-flex align-items-center justify-content-start'>
              <b style={{ width: '100px' }}>Adres</b>
              <span className='mr-2'>:</span>
              {state.orderDetails.Address}
              {/* {state.orderDetails.IyzicoOrderNumber ? (
                <div className='col-12 col-sm-6'>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Sipariş No</b>
                    <span className='mr-2'>:</span>
                    {state.orderDetails.OrderNumber}
                  </div>
                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>Iyzico Sipariş No</b>
                    <span className='mr-2'>:</span>
                    {state.orderDetails.IyzicoOrderNumber}
                  </div>
                </div>
              ) : (
                <div className='col-12 col-sm-6'>

                  <div className='d-flex align-items-center justify-content-start'>
                    <b style={{ width: '100px' }}>İl/İlçe</b>
                    <span className='mr-2'>:</span>
                    {state.orderDetails.City + '/' + state.orderDetails.District}
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <p
            style={{
              marginTop: '10px',
              fontSize: '17px',
              color: 'black',
              fontWeight: '600',
              marginLeft: '20px',
            }}
          >
            SİPARİŞ DETAYI
          </p>
          {state.orderDetails.OrderReturnCompanyList.map((order, i) => (
            <div key={i}>
              <div
                style={{
                  //border: '1px solid #000000',
                  backgroundColor: 'white',
                  padding: '20px',
                }}
              >
                <div className='row'>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Şirket</b>
                      <span className='mr-2'>:</span>
                      {basket?.ActiveBasket?.Companies?.map((com: CompaniesGroup) => {
                        if (order.Company === '1000') {
                          if (com.CompanyID === 1) {
                            return <Image src={com.CompanyLogo} alt='' style={{ width: '50px' }} />
                          }
                        }
                        if (order.Company === '1100') {
                          if (com.CompanyID === 4) {
                            return <Image src={com.CompanyLogo} alt='' style={{ width: '50px' }} />
                          }
                        }
                        if (order.Company === '1200') {
                          if (com.CompanyID === 3) {
                            return <Image src={com.CompanyLogo} alt='' style={{ width: '50px' }} />
                          }
                        }
                        if (order.Company === '2300') {
                          if (com.CompanyID === 2) {
                            return <Image src='/images/netex_logo.png' alt='' style={{ width: '50px' }} />
                          }
                        }
                      })}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      {' '}
                      <b style={{ width: '100px' }}>Sipariş No</b>
                      <span className='mr-2'>:</span>
                      {order.OrderNumber}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Ödeme Şekli</b>
                      <span className='mr-2'>:</span>
                      {order.PaymentMethod}
                    </div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Kur Tipi</b> <span className='mr-2'>:</span> {order.CurrencyType}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Tarih</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.CreatedDate
                        ? new Date(state.orderDetails.CreatedDate).toLocaleDateString('tr-TR')
                        : '-'}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Sevk Tarihi</b>
                      <span className='mr-2'>:</span>
                      {state.orderDetails.ShipmentDate
                        ? new Date(state.orderDetails.ShipmentDate).toLocaleDateString('tr-TR')
                        : '-'}
                    </div>
                  </div>
                  <div className='col-12 col-sm-6 col-md-4'>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Kur</b> <span className='mr-2'>:</span>
                      {order.CurrencyValue}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>MT </b>
                      <span className='mr-2'>:</span>
                      {order.MT}
                    </div>
                    <div className='d-flex align-items-center justify-content-start'>
                      <b style={{ width: '100px' }}>Sevk Biçimi</b> <span className='mr-2'>:</span>{' '}
                      {state.orderDetails.ShipmentType}
                    </div>
                  </div>
                </div>
              </div>
              <div className='table-responsive'>
                <table className='table table-borderless table-hover m-t-5'>
                  <thead>
                    <tr className='EInvoicetableDescription'>
                      {order.Items[0].IyzicoNumber && <td scope='col'>Iyzico No</td>}
                      <td scope='col'>Depo Kodu</td>
                      <td scope='col' style={{ whiteSpace: 'nowrap' }} width={111}>
                        Ürün Kodu
                      </td>
                      <td
                        scope='col'
                        width={350}
                        style={{
                          textAlignLast: 'center',
                        }}
                      >
                        Açıklama
                      </td>
                      <td scope='col'>Adet</td>
                      <td scope='col'>Birim Tutar(₺)</td>
                      <td scope='col'>
                        Birim Tutar({order.Items[0].Currency === 0 ? '₺' : order.Items[0].Currency === 1 ? '$' : '€'})
                      </td>
                      <td scope='col'>Toplam Tutar (₺)</td>
                      <td scope='col'>
                        Toplam Tutar({order.Items[0].Currency === 0 ? '₺' : order.Items[0].Currency === 1 ? '$' : '€'})
                      </td>
                    </tr>
                  </thead>
                  {order.Items.map((item, index) => (
                    <tbody
                      key={index}
                      style={{
                        borderBottom: '1px solid lightgray',
                      }}
                    >
                      {order.Items[0].IyzicoNumber && <td>{item.IyzicoNumber}</td>}
                      <td>{item.Warehouse}</td>
                      <td>{item.ProductCode}</td>
                      <td>{item.ProductName}</td>
                      <td style={{ textAlign: 'center' }}>{item.Quantity}</td>
                      <td style={{ textAlign: 'right' }}>{item.Price1}</td>
                      <td style={{ textAlign: 'right' }}>{item.Price2}</td>
                      <td style={{ textAlign: 'right' }}>{item.TotalPrice1}</td>
                      <td style={{ textAlign: 'right' }}>{item.TotalPrice2}</td>
                    </tbody>
                  ))}
                  <tbody>
                    {order.Items[0].IyzicoNumber && <td></td>}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>Toplam:</b>
                    </td>
                    <td style={{ textAlign: 'left' }}>{order.TotalPrice}</td>
                  </tbody>
                  <tbody>
                    {order.Items[0].IyzicoNumber && <td></td>}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>Kargo:</b>
                    </td>
                    <td style={{ textAlign: 'left' }}>{order.Cargo}</td>
                  </tbody>
                  <tbody>
                    {order.Items[0].IyzicoNumber && <td></td>}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>KDV:</b>
                    </td>
                    <td style={{ textAlign: 'left' }}>{order.TotalKdv}</td>
                  </tbody>
                  <tbody>
                    {order.Items[0].IyzicoNumber && <td></td>}
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                      <b>Genel Toplam:</b>
                    </td>
                    <td style={{ textAlign: 'left' }}>{order.Total}</td>
                  </tbody>
                </table>
              </div>
            </div>
          ))}

          {/*NOTE:Eski Butonlar
           <div className='row align-items-center' style={{ justifyContent: 'center' }}>
            <div className='form-group col-md-3 align-items-center '>
              <IPButton
                name='createOfferInModal'
                onClick={() => {
                  printElementOrderRecord(document.getElementById('orderForPrint'))
                }}
                text='Yazdır'
                icon='print-solid.svg'
              />
            </div>
            <div className='form-group col-md-3 align-items-center '>
              <IPButton
                name='createOfferInModal'
                onClick={() => {
                  dispatchState({ showMail: true })
                }}
                text='E-Posta Gönder'
                icon='epostadetay.svg'
              />
            </div>
            <div className='form-group col-md-3 align-items-center '>
              <IPButton
                className='createOfferInModal'
                onClick={postOrderExcel}
                text='Excele Aktar'
                icon='file-excel-solid.svg'
              />
            </div>

            * <div className="form-group col-md-3 align-items-center ">
              <IPButton
                name="exportExcelInModal"
                onClick={(e) => { }}
                text="E-Posta Gönder"
                icon="envelope-regular.svg"
              />
            </div> *
          
          </div> */}
        </div>
      </Modal>
      <Modal
        show={state.showSendMailModal}
        onClose={() => {
          dispatchState({ showSendMailModal: false })
        }}
        width={400}
        onConfirm={offerSendMail}
        confirmText={'Mail Gönder'}
      >
        <div className='form-group col-md-12 align-items-center '>
          <label className='text-sm-left'>Mail Adresi</label>
          <IPInput
            type='text'
            className='form-control'
            name='sendMail'
            required
            value={state.sendMail}
            onChange={e => {
              dispatchState({ sendMail: e.target.value })
            }}
          />
        </div>
      </Modal>
      <Modal
        show={state.showMail}
        onClose={() => {
          dispatchState({ showMail: false })
        }}
        width={500}
        onConfirm={orderSendMail}
        confirmText={'Mail Gönder'}
      >
        <div className='form-group col-md-12 align-items-center '>
          <label className='text-sm-left'>Ad Soyad</label>
          <IPInput
            type='text'
            className='form-control'
            name='sendMail'
            required
            value={state.mailName}
            onChange={e => {
              dispatchState({ mailName: e.target.value })
            }}
          />
          <label className='text-sm-left'>Mail Adresi</label>
          <IPInput
            type='text'
            className='form-control'
            name='sendMail'
            required
            value={state.mailEmailText}
            onChange={e => {
              dispatchState({ mailEmailText: e.target.value })
            }}
          />
          <label className='text-sm-left'>Konu</label>
          <IPInput
            type='text'
            className='form-control'
            name='sendMail'
            required
            value={state.mailSubject}
            onChange={e => {
              dispatchState({ mailSubject: e.target.value })
            }}
          />
          <label className='text-sm-left'>Kişisel Notunuz</label>
          <IPTextarea
            className='form-control'
            name='sendMail'
            required
            value={state.mailNote}
            onChange={e => {
              dispatchState({ mailNote: e.target.value })
            }}
          />
          <label className='text-sm-left'>*Tüm alanların doldurulması zorunludur.</label>
          <label className='text-sm-left'>
            ** Birden fazla kişiye e-posta atmak için, adreslerin arasına virgül (",") koyunuz.
          </label>
        </div>
      </Modal>
      <Tooltip show={addressTooltip.show} coords={addressTooltip.coords}>
        {addressTooltip.content && (
          <div className='card'>
            <div className='card-header m-0 p-3'>
              {addressTooltip.content.AddressTitle
                ? addressTooltip.content.AddressTitle
                : addressTooltip.content.AddressDetail}
            </div>
            <div className='card-body pt-1 pb-0'>
              <h5>{addressTooltip.content.NameAndSurname}</h5>
              <p>{addressTooltip.content.District}</p>
              <p>{addressTooltip.content.AddressDetail}</p>
              <p>{addressTooltip.content.Phone}</p>
            </div>
          </div>
        )}
      </Tooltip>
      <div ref={paymentRef} style={{ display: 'none' }}></div>
      <Spinner loading={state.loading} />
    </React.Fragment>
  )
}

export default componentWithContext(MyCard)
