import { IRequesterInfo } from '../../../../../base/models'
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IProductGroupImagesModel } from '../models/requestModels'
import { IProductGroupImageList } from '../models/responseModel'

export class AddProductGroupImageApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/brand/AddProductGroupImage',
      requesterInfo,
    })
  }
  public async addImage(req: IProductGroupImagesModel): Promise<IProductGroupImageList[]> {
    return await this.postAsync<IProductGroupImageList[]>(req)
  }
}
