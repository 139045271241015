
import { ClientProxy } from '../../../../../base/proxy/clientProxy'
import { IRequesterInfo } from '../../../../../base/models'
import { CommonPostResponse } from '../../../components/models/models'
import { IBankAccountNumbers } from '../../../../FinancialInformation/models/models'

export class AddBankAccountApi extends ClientProxy {
  constructor(requesterInfo: IRequesterInfo) {
    super({
      url: 'admin/dealerPaymentOperation/addBankAccounts',
      requesterInfo,
    })
  }

  public async addBankAccount(request: IBankAccountNumbers): Promise<any> {
    return await this.postAsync<any>(request)
  }
}
