/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from '@emotion/css'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import { BaseComponentWithContextProps, componentWithContext } from '../../../../../base/customHooks/componentHOC'
import IPButton from '../../../../../components/IPButton'
import { IBankAccountNumbers } from '../../../../FinancialInformation/models/models'
import IPSelectBox from '../../../../../components/IPSelectBox'
import IPInput from '../../../../../components/IPInput'
import IPCheckbox from '../../../../../components/IPCheckBox'
import { useCustomReducer } from '../../../../../base/customHooks'
import { ISelectOption } from '../../../../../components/IPSelectBox/IPSelectBox'
import { AddBankAccountApi } from '../services/addBankAccountApi'
import { UpdateBankAccountApi } from '../services/updateBankAccountApi'

export interface BankAccountListState {
  BankName?: string
  Branch?: string
  BranchCode?: string
  BranchName?: string
  Type?: string
  AccountNumber?: string
  Iban?: string
  Company?: ISelectOption
  CompanyOption: ISelectOption[]
  Active?: boolean
  Id?: number
  PageType: string
}

function AddBankAccount(props: BaseComponentWithContextProps) {
  const location = useLocation()
  const bankAccountData = (location.state as { bankAccountData?: IBankAccountNumbers })?.bankAccountData
  const navigate: any = useNavigate()

  const getInitialState = (): BankAccountListState => {
    return {
      Active: true,
      PageType: "Add",
      CompanyOption: [
        { label: 'Index', value: 1000 },
        { label: 'Datagate', value: 1100 },
        { label: 'Despec', value: 1200 },
        { label: 'Netex', value: 2300 },
      ]
    }
  }

  const intialState = getInitialState()
  const [state, setState] = useCustomReducer<BankAccountListState>(intialState)

  useEffect(() => {
    if (bankAccountData && state.CompanyOption) {
      setState({
        BankName: bankAccountData.BankName,
        Branch: bankAccountData.Branch,
        BranchCode: bankAccountData.BranchCode,
        BranchName: bankAccountData.BranchName,
        Type: bankAccountData.Type,
        AccountNumber: bankAccountData.AccountNumber,
        Iban: bankAccountData.Iban,
        Company: state.CompanyOption.find(a => a.value === bankAccountData.Company),
        Active: bankAccountData.Active,
        Id: bankAccountData.Id,
        PageType: "Update"
      })
    }
  }, [bankAccountData, state.CompanyOption])

  const handleBankAccounts = async () => {
    try {
      if (state.BankName && state.Branch && state.BranchCode && state.BranchName && state.Type && state.AccountNumber && state.Iban && state.Active && state.Company) {
        const req: IBankAccountNumbers = {
          Id: bankAccountData && bankAccountData.Id ? bankAccountData.Id : 0,
          BankName: state.BankName,
          Branch: state.Branch,
          BranchCode: state.BranchCode,
          BranchName: state.BranchName,
          Type: state.Type,
          AccountNumber: state.AccountNumber,
          Iban: state.Iban,
          Company: Number(state.Company?.value),
          Active: state.Active
        }
        if (state.PageType === "Add") {
          const addApi: AddBankAccountApi = new AddBankAccountApi({})
          addApi.addBankAccount(req).then((res: any) => {
            if (res) {
              Swal.fire({
                icon: 'success',
                title: res,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              navigate('/admin/BankAndInstallementOperation/BankAccounts/BankAccountList')
            }
          }).catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
        }
        else {
          const updateApi: UpdateBankAccountApi = new UpdateBankAccountApi({})
          updateApi.updateBankAccount(req).then((res: any) => {
            if (res) {
              Swal.fire({
                icon: 'success',
                title: res,
                showConfirmButton: true,
                allowOutsideClick: false,
              })
              navigate('/admin/BankAndInstallementOperation/BankAccounts/BankAccountList')
            }
          }).catch((err: any) => {
            Swal.fire({
              icon: 'error',
              title: err.description,
              showConfirmButton: true,
              allowOutsideClick: false,
            })
          })
        }
      }
      else {
        Swal.fire({
          icon: 'error',
          title: "Bütün alanları doldurmak zorunludur!",
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      }
    } catch (err: any) {
      Swal.fire({
        icon: 'error',
        title: err.description,
        showConfirmButton: true,
        allowOutsideClick: false,
      })
    }
  }

  return (
    <div className='container'>
      <div className='content col-lg-12 m-auto'>
        <div className='card'>
          <div className='card-header d-flex justify-content-between'>
            <span className='h4'>Banka Hesap Numaraları</span>
            <div className='col-12 col-md-2 mb-2'>
              <div className='buttonsuz'>
                <Link to='/admin/BankAndInstallementOperation/BankAccounts/BankAccountList'>
                  <IPButton type='button' className='btn btn-primary' text='Listeye Dön' />
                </Link>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <form className='formbanner'>
              <div className='row'>
                <div className='col-lg-3'>
                  <label htmlFor='bank' className='form-label'>
                    Banka
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    id='bank'
                    value={state.BankName}
                    onChange={e => {
                      setState({ BankName: e.target.value?.toUpperCase() })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='branch' className='form-label'>
                    Şube
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    id='branch'
                    value={state.Branch}
                    onChange={e => {
                      setState({ Branch: e.target.value?.toUpperCase() })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='branchCode' className='form-label'>
                    Şube Kodu
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    id='branchCode'
                    value={state.BranchCode}
                    onChange={e => {
                      setState({ BranchCode: e.target.value?.toUpperCase() })
                    }}
                  />
                </div>
                <div className='col-lg-3'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Şube Adı
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    id='branchName'
                    value={state.BranchName}
                    onChange={e => {
                      setState({ BranchName: e.target.value?.toUpperCase() })
                    }}
                  />
                </div>
                <div className='col-lg-1'>
                  <label htmlFor='bayikodu' className='form-label'>
                    Aktiflik
                  </label>
                  <div className='active'>
                    <IPCheckbox
                      type='text'
                      name='Active'
                      onClick={() => setState({ Active: !state.Active })}
                      checked={state.Active}
                      label={'Aktif'}
                    />
                  </div>
                </div>
              </div>
              <br></br>
              <div className='row'>
                <div className='col-lg-4'>
                  <label htmlFor='iban' className='form-label'>
                    IBAN
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    id='iban'
                    value={state.Iban}
                    onChange={e => {
                      setState({ Iban: e.target.value?.toUpperCase() })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='type' className='form-label'>
                    Hesap Türü
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    id='type'
                    value={state.Type}
                    onChange={e => {
                      setState({ Type: e.target.value?.toUpperCase() })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='accountNumber' className='form-label'>
                    Hesap Numarası
                  </label>
                  <IPInput type='text'
                    className='form-control'
                    id='accountNumber'
                    value={state.AccountNumber}
                    onChange={e => {
                      setState({ AccountNumber: e.target.value?.toUpperCase() })
                    }}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='company' className='form-label'>
                    Şirket
                  </label>
                  <IPSelectBox
                    isClearable
                    id='company'
                    name='company'
                    options={state.CompanyOption}
                    value={state.Company}
                    onChangeSingle={(option) => setState({ Company: option })}
                  />
                </div>
                <div className='col-lg-2'>
                  <label htmlFor='location' className='form-label'>
                    <br />
                    <div className='buttonsuz'>
                      <IPButton
                        type='button'
                        className='btn btn-success'
                        text={state.PageType === "Update" ? 'Güncelle' : 'Ekle'}
                        onClick={handleBankAccounts}
                      />
                    </div>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default componentWithContext(AddBankAccount)
